"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var user_service_1 = require("./user.service");
var utils_service_1 = require("../../common/utils.service");
var loading_service_1 = require("../../common/loading.service");
var dispose_bag_class_1 = require("../../classes/dispose-bag.class");
var UserComponent = /** @class */ (function () {
    function UserComponent(utils, loadingService) {
        this.utils = utils;
        this.loadingService = loadingService;
        //@ViewChild('lntable') lntable: DatatableComponent;
        this.loading = false;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
        this.temp = [];
        this.rows = [];
    }
    UserComponent.prototype.ngOnInit = function () {
        this.utils.setPageTitleByTranslateKey('USERS');
    };
    return UserComponent;
}());
exports.UserComponent = UserComponent;
