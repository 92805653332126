"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var authentication_service_1 = require("app/authentication/authentication.service");
exports.InterceptorSkipHeader = 'X-Skip-Interceptor';
var BaseInterceptor = /** @class */ (function () {
    function BaseInterceptor(auth) {
        this.auth = auth;
    }
    BaseInterceptor.prototype.addAuthenticationToken = function (request) {
        if (request.headers.has(exports.InterceptorSkipHeader)) {
            return this.skipIntercept(request);
        }
        var headers = {};
        if (request.method !== 'GET' && !request.headers.has('Content-Type')) {
            headers['Content-Type'] = 'application/json; charset=utf-8';
        }
        var user = this.auth.getUser();
        if (!user) {
            return this.requestWithHeader(request, headers);
        }
        headers = this.addTokensToHeaders(user, headers);
        return this.requestWithHeader(request, headers);
    };
    BaseInterceptor.prototype.skipIntercept = function (request) {
        request.headers.delete(exports.InterceptorSkipHeader);
        var user = this.auth.getUser();
        if (!user) {
            return this.requestWithHeader(request, {});
        }
        var headers = this.addTokensToHeaders(user, {});
        return this.requestWithHeader(request, headers);
    };
    BaseInterceptor.prototype.addTokensToHeaders = function (user, headers) {
        if (user.token) {
            headers['Authorization'] = "Bearer " + user.token;
        }
        if (user.accessToken) {
            headers['X-Google-AccessToken'] = user.accessToken;
        }
        return headers;
    };
    BaseInterceptor.prototype.requestWithHeader = function (request, headers) {
        return request.clone({
            setHeaders: headers
        });
    };
    return BaseInterceptor;
}());
exports.BaseInterceptor = BaseInterceptor;
