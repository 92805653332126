"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".gene-forget-pass .login-logo img {\n  margin-bottom: 1.5rem;\n}\n.gene-forget-pass button {\n  margin-bottom: 1rem;\n}\n.gene-forget-pass .mat-card {\n  padding-bottom: 2rem;\n  padding-top: 2rem;\n}\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbGliL2plbmtpbnMvd29ya3NwYWNlL2Zhc3NpLWNtcy1kZXYvc3JjL2FwcC9zZXNzaW9uL2ZvcmdvdC1wYXNzd29yZFYyL2ZvcmdvdC1wYXNzd29yZFYyLWNvbXBvbmVudC5zY3NzIiwic3JjL2FwcC9zZXNzaW9uL2ZvcmdvdC1wYXNzd29yZFYyL2ZvcmdvdC1wYXNzd29yZFYyLWNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVRO0VBQ0kscUJBQUE7QUNEWjtBRElJO0VBQ0ksbUJBQUE7QUNGUjtBRElJO0VBQ0ksb0JBQUE7RUFDQSxpQkFBQTtBQ0ZSIiwiZmlsZSI6InNyYy9hcHAvc2Vzc2lvbi9mb3Jnb3QtcGFzc3dvcmRWMi9mb3Jnb3QtcGFzc3dvcmRWMi1jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5nZW5lLWZvcmdldC1wYXNze1xuICAgIC5sb2dpbi1sb2dve1xuICAgICAgICBpbWd7XG4gICAgICAgICAgICBtYXJnaW4tYm90dG9tOiAxLjVyZW07XG4gICAgICAgIH1cbiAgICB9XG4gICAgYnV0dG9ue1xuICAgICAgICBtYXJnaW4tYm90dG9tOiAxcmVtO1xuICAgIH1cbiAgICAubWF0LWNhcmR7XG4gICAgICAgIHBhZGRpbmctYm90dG9tOiAycmVtO1xuICAgICAgICBwYWRkaW5nLXRvcDogMnJlbTtcbiAgICB9XG59IiwiLmdlbmUtZm9yZ2V0LXBhc3MgLmxvZ2luLWxvZ28gaW1nIHtcbiAgbWFyZ2luLWJvdHRvbTogMS41cmVtO1xufVxuLmdlbmUtZm9yZ2V0LXBhc3MgYnV0dG9uIHtcbiAgbWFyZ2luLWJvdHRvbTogMXJlbTtcbn1cbi5nZW5lLWZvcmdldC1wYXNzIC5tYXQtY2FyZCB7XG4gIHBhZGRpbmctYm90dG9tOiAycmVtO1xuICBwYWRkaW5nLXRvcDogMnJlbTtcbn0iXX0= */"];
exports.styles = styles;
