import { Injectable } from '@angular/core';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { ApiService } from 'app/common/api.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UserService extends CrudApiService {
    constructor(protected requestService: RequestService, protected apiService: ApiService) {
      super(requestService, apiService)
      this.target = 'user'
    }

    getList(): Observable<any[]> {
        return super.getList()
            .map(array =>
                array
                    .map(i => new User(i))
                    .sort((a, b) => a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : 1)
                    .map(user => user.toObject())
            );
    }

    insertUser(user: any) {
        return this.requestService.post(this.apiService.createUrlFromBaseUrl(this.target), JSON.stringify(user))
    }

    updateUser(user: any) {
        return this.requestService.put(this.apiService.createUrlFromBaseUrl(this.target, String(user.id)), JSON.stringify(user))
    }

    updatePassword(password: any, id: string) {
        return this.requestService.put(this.apiService.createUrlFromBaseUrl(this.target, id, 'password'), JSON.stringify(password))
    }
}

export class User {
   id: number;
   name: string;
   surname: string;
   email: string;
   department: string;
   phone: string;
   mobile: string;
   enabled: boolean;

   role: string;
   company_id: number;
   location_id: number;
   
   fullName: string;

   company: any;

    constructor(userObj: any) {
        Object.assign(this, userObj);
        this.fullName = [this.name, this.surname].filter(i => !!i && i !== '').join(' ');
    }

    toObject(): any {
        return JSON.parse(JSON.stringify(this))
    }
}

export enum RolesTypes {
    ADMIN = 'admin',
    USER = 'user'
  }