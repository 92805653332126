"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var router_1 = require("@angular/router");
var core_service_1 = require("../../service/core/core.service");
var menu_items_1 = require("../../core/menu/menu-items/menu-items");
var SideBarComponent = /** @class */ (function () {
    function SideBarComponent(translate, router, coreService, menuItems) {
        this.translate = translate;
        this.router = router;
        this.coreService = coreService;
        this.menuItems = menuItems;
    }
    SideBarComponent.prototype.ngOnInit = function () {
    };
    //render to the crm page
    SideBarComponent.prototype.onClick = function () {
        var first = location.pathname.split('/')[1];
        if (first == 'horizontal') {
            this.router.navigate(['/horizontal/dashboard/crm']);
        }
        else {
            this.router.navigate(['/dashboard/crm']);
        }
    };
    /**
     * addMenuItem is used to add a new menu into menu list.
     */
    SideBarComponent.prototype.addMenuItem = function () {
        this.menuItems.add({
            state: 'pages',
            name: 'GENE MENU',
            type: 'sub',
            icon: 'trending_flat',
            children: [
                { state: 'blank', name: 'SUB MENU1' },
                { state: 'blank', name: 'SUB MENU2' }
            ]
        });
    };
    return SideBarComponent;
}());
exports.SideBarComponent = SideBarComponent;
