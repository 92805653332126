import {Component, OnInit, ViewChild} from '@angular/core';
import {PageTitleService} from '../../core/page-title/page-title.service';
import {User, UserService} from './user.service';
import {UtilsService} from '../../common/utils.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {ActivatedRoute} from '@angular/router';
import {LoadingService} from '../../common/loading.service';
import {DisposeBag} from '../../classes/dispose-bag.class';

@Component({
  selector: 'ms-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  //@ViewChild('lntable') lntable: DatatableComponent;
  loading = false;
  disposeBag = new DisposeBag();

  temp: User[] = [];
  rows: User[] = [];
  page: number
  public options: any[]

  public filterString: string
  public enabledString: string

  constructor(private utils: UtilsService, public loadingService: LoadingService) { }

  ngOnInit() {
    this.utils.setPageTitleByTranslateKey('USERS');
  }
}
