import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { Observable, zip } from 'rxjs';
import { FormPasswordComponent } from 'app/components/user/form-password/form-password.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  private profileDialogRef: MatDialogRef<FormPasswordComponent>;

  constructor(private translateService: TranslateService, private pageTitleService: PageTitleService, private dialog: MatDialog) { }

  translate(key: string): Observable<string>  {
    return this.translateService.get(key)
  }

  translateArray(array: string[]): Observable<string[]>  {
    return zip(...array.map(type => this.translate(type)))
  }

  translateArrayDict(keys: string[]): Observable<any[]> {
    return this.translateArray(keys).map(array => {
      return array.map((item, i) => {
        return {id: keys[i], text: item};
      })
    })
  }

  translateDict(keys: string[]): Observable<any> {
    return this.translateArrayDict(keys).map(res => {
        const dict = {};
        for (const el of res) {
          dict[el.id] = el.text;
        }
        return dict;
      })  
  }

  arrayToDict(array: any[], primaryKey: any, valueKey: string = null): any {
    const dict = {};
    for (const el of array) {
      dict[el[primaryKey]] = !!valueKey && valueKey !== '' ? el[valueKey] : el;
    }
    return dict
  }

  objectArrayToDict(array: any[], primaryKey: any): any {
    const dict = {};
    for (const el of array) { dict[el[primaryKey]] = el }
    return dict
  }

  setPageTitleByTranslateKey(key: string) {
    this.translateService.get(key).subscribe((translatedText: string) => {
      this.pageTitleService.setTitle(translatedText)
    })
  }

  private async dataURLToBlob(url): Promise<Blob> {
    return fetch(url).then(res => res.blob())
  }

  private blobToFile(theBlob: Blob, fileName: string): File {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return <File>theBlob;
  }

  public async dataURLToFile(url: string, fileName: string): Promise<File> {
    return this.dataURLToBlob(url).then(blob => this.blobToFile(blob, fileName))
  }

  public secondsToHours(seconds: number, decimal: number): number {
    const hours = seconds / 3600;
    return Math.ceil(hours * Math.pow(10, decimal)) / Math.pow(10, decimal);
  }

  public secondsToString(eta: number): string {
    const hours = eta / 3600;
    const hoursInt = Math.floor(hours);
    const minutes = (hours - hoursInt) * 60;
    const minutesInt = Math.floor(minutes);
    const seconds = (minutes - minutesInt) * 60;
    const secondsInt = Math.floor(seconds);
    return this.numberToString(hoursInt) + ':' + this.numberToString(minutesInt) + ':' + this.numberToString(secondsInt);
  }

  numberToString(int: number): any {
    if (int < 10) { return '0' + int; }
      return int;
  }

  openPasswordDialog(id: string): Observable<any> {
    this.profileDialogRef = this.dialog.open(FormPasswordComponent, {
      panelClass: 'form-popup',
      disableClose: false,
      data: id,
      width: '50%',
      height: '50%'
    });
    return this.profileDialogRef.afterClosed().map(result => {
      this.profileDialogRef = null;
      return result
    })
  }

  setViewOfMultiLanguageField(data: string): string {
    const obj = JSON.parse(data);
    const browserLang: string = this.translateService.getBrowserLang();
    return !!obj[browserLang] ? obj[browserLang] : obj[environment.defaultLanguage];
  }
}
