"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_service_1 = require("app/common/utils.service");
var info_service_1 = require("./../info.service");
var core_1 = require("@angular/core");
var loading_service_1 = require("app/common/loading.service");
var router_1 = require("@angular/router");
var ViewInfoComponent = /** @class */ (function () {
    function ViewInfoComponent(router, infoService, loadingService, utils) {
        this.router = router;
        this.infoService = infoService;
        this.loadingService = loadingService;
        this.utils = utils;
    }
    ViewInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.info$ = this.loadingService.apiRequest$WithLoading(this.infoService.get()).map(function (info) {
            info.name = _this.utils.setViewOfMultiLanguageField(info.name);
            if (!!info.description) {
                info.description = _this.utils.setViewOfMultiLanguageField(info.description);
            }
            return info;
        });
    };
    ViewInfoComponent.prototype.goToEdit = function () {
        this.router.navigate(['info', 'edit']);
    };
    return ViewInfoComponent;
}());
exports.ViewInfoComponent = ViewInfoComponent;
