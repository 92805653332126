import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudApiService } from '../../common/crud-api.service';
import { RequestService } from '../../common/request.service';
import { ApiService } from '../../common/api.service';

@Injectable({
  providedIn: 'root'
})
export class VersionService extends CrudApiService {

  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService);
    this.target = 'version';
  }

  save(version: Version, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(version));
    formData.append('files', file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  dowloadFile(url: string, name: string) {
    this.requestService.downloadFile(url, {}, '*/pdf', name);
  }

  setAsCurrent(id: number) {
    const url = this.apiService.createUrlFromBaseUrl(this.target, `${id}`, 'current');
    return this.requestService.put(url, {});
  }

  updateName(id: number, name: string) {
    const url = this.apiService.createUrlFromBaseUrl(this.target, `${id}`, 'name');
    return this.requestService.put(url, { name: name });
  }
}

export class Version {
  id: number;
  user_id: number;
  name: string;
  file_id: number;
  current: boolean;
  catalogue_id: number;
  url: string;
}
