"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var PageTitleService = /** @class */ (function () {
    function PageTitleService() {
        this.title = new rxjs_1.BehaviorSubject(null);
    }
    PageTitleService.prototype.setTitle = function (value) {
        this.title.next(value);
    };
    return PageTitleService;
}());
exports.PageTitleService = PageTitleService;
