"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var catalogue_service_1 = require("./../catalogue.service");
var utils_service_1 = require("./../../../common/utils.service");
var app_api_service_1 = require("./../../../common/app-api.service");
var grid_base_component_1 = require("./../../../classes/grid-base.component");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var catalogue_service_2 = require("../catalogue.service");
var dispose_bag_class_1 = require("../../../classes/dispose-bag.class");
var dx_service_1 = require("../../../common/dx.service");
var loading_service_1 = require("../../../common/loading.service");
var rxjs_1 = require("rxjs");
var log_service_1 = require("app/common/log.service");
var config_service_1 = require("app/common/config.service");
var version_service_1 = require("app/components/version/version.service");
var GridCatalogueComponent = /** @class */ (function (_super) {
    __extends(GridCatalogueComponent, _super);
    function GridCatalogueComponent(configService, router, catalogueService, dxService, loadingService, logger, versionService, appApiService, utilsService) {
        var _this = _super.call(this, configService) || this;
        _this.configService = configService;
        _this.router = router;
        _this.catalogueService = catalogueService;
        _this.dxService = dxService;
        _this.loadingService = loadingService;
        _this.logger = logger;
        _this.versionService = versionService;
        _this.appApiService = appApiService;
        _this.utilsService = utilsService;
        _this.disposeBag = new dispose_bag_class_1.DisposeBag();
        return _this;
    }
    GridCatalogueComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadData();
        this.editButtonClick = this.editButtonClick.bind(this);
        this.removeButtonClick = this.removeButtonClick.bind(this);
        this.versionButtonClick = this.versionButtonClick.bind(this);
    };
    GridCatalogueComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    GridCatalogueComponent.prototype.editButtonClick = function (e) {
        this.router.navigate(['catalogue', e.row.data.id]);
    };
    GridCatalogueComponent.prototype.versionButtonClick = function (e) {
        this.router.navigate(['catalogue', e.row.data.id, 'version']);
    };
    GridCatalogueComponent.prototype.removeButtonClick = function (e) {
        var _this = this;
        this.disposeBag.add(this.deleteRowWithConfirmation(e.row.data.id).subscribe(function () { return _this.loadData(); }, function () { return _this.logger.serverError(); }));
    };
    GridCatalogueComponent.prototype.downloadFile = function (url, name) {
        this.versionService.dowloadFile(url, name + ".pdf");
    };
    GridCatalogueComponent.prototype.deleteRowWithConfirmation = function (dataId) {
        return this.loadingService.apiRequest$WithLoading(this.dxService.dxConfirmWithSuccessfulObservable('WARNING', 'ARE YOU SURE', this.catalogueService.delete(dataId)));
    };
    GridCatalogueComponent.prototype.onToolbarPreparing = function (e) {
        var _this = this;
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: function () { return _this.loadData(); }
            }
        }, {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'add',
                onClick: function () { return _this.router.navigate(['catalogue/add']); }
            }
        });
    };
    GridCatalogueComponent.prototype.loadData = function () {
        var _this = this;
        this.catalogues$ = this.loadingService.apiRequest$WithLoading(rxjs_1.zip(this.catalogueService.getList(), this.appApiService.getCategories()))
            .map(function (_a) {
            var rows = _a[0], categoryArray = _a[1];
            categoryArray = categoryArray.map(function (category) {
                for (var _i = 0, _a = category.extended_name.split('/'); _i < _a.length; _i++) {
                    var name_1 = _a[_i];
                    category.extendedName = !category.extendedName ? _this.utilsService.setViewOfMultiLanguageField(name_1) :
                        category.extendedName + "/" + _this.utilsService.setViewOfMultiLanguageField(name_1);
                }
                return category;
            });
            var categories = _this.utilsService.arrayToDict(categoryArray, 'id', 'extendedName');
            return rows.map(function (row) {
                row.um_text = catalogue_service_1.TranslatedUm[row.um];
                if (!!row.category) {
                    row.category.extended_name = categories[row.category.id];
                }
                return row;
            });
        });
    };
    return GridCatalogueComponent;
}(grid_base_component_1.GridBaseComponent));
exports.GridCatalogueComponent = GridCatalogueComponent;
