"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var menu_toggle_directive_1 = require("./menu-toggle.directive");
var MenuToggleLinkDirective = /** @class */ (function () {
    function MenuToggleLinkDirective(nav) {
        this.nav = nav;
    }
    Object.defineProperty(MenuToggleLinkDirective.prototype, "open", {
        get: function () {
            return this._open;
        },
        set: function (value) {
            this._open = value;
            if (value) {
                this.nav.closeOtherLinks(this);
            }
        },
        enumerable: true,
        configurable: true
    });
    MenuToggleLinkDirective.prototype.ngOnInit = function () {
        this.nav.addLink(this);
        if (this.group) {
            var routeUrl = this.nav.getUrl();
            var currentUrl = routeUrl.split('/');
            if (currentUrl.indexOf(this.group) > 0) {
                this.toggle();
            }
        }
    };
    MenuToggleLinkDirective.prototype.ngOnDestroy = function () {
        this.nav.removeGroup(this);
    };
    MenuToggleLinkDirective.prototype.toggle = function () {
        this.open = !this.open;
    };
    return MenuToggleLinkDirective;
}());
exports.MenuToggleLinkDirective = MenuToggleLinkDirective;
