import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/do';  // debug
import 'rxjs/add/operator/catch';
import {LogService} from './log.service';
import {saveAs as importedSaveAs} from 'file-saver';
import {InterceptorSkipHeader} from '../authentication/token-interceptor';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private http: HttpClient, private logger: LogService) {}

  public get(url, headers = {}) {
    return this.request('GET', url, headers)
  }

  public put(url, data, headers = {}) {
    return this.request('PUT', url, data, headers)
  }

  public post(url, data, headers = {}) {
    return this.request('POST', url, data, headers)
  }

  public patch(url, data, headers = {}) {
    return this.request('PATCH', url, data, headers)
  }

  public delete(url, headers = {}) {
    return this.request('DELETE', url, headers)
  }

  private request(method, url, data, headers = {}) {
    const options = {headers: headers}
    let req = undefined
    switch (method) {
      case 'GET': {
        req = this.http.get(url, options)
        break;
      }
      case 'PUT': {
        req = this.http.put(url, data, options)
        break;
      }
      case 'POST': {
        req = this.http.post(url, data, options)
        break;
      }
      case 'PATCH': {
        req = this.http.patch(url, data, options)
        break;
      }
      case 'DELETE': {
        req = this.http.delete(url, options)
        break;
      }
      default: {
        break;
      }
    }
    return this.handleResponseError(req.pipe(take(1)));
  }

  protected handleErrorObservable(error: HttpErrorResponse | any) {
    console.error(error);
    if (error instanceof HttpErrorResponse) {
      const status = error.status
      if (status && status === 409) {
        this.logger.alreadyBookedError()
      } else if (status && status === 406) {
        this.logger.notAccetableError()
      } else if (status && status === 400) {
        this.logger.badRequestError()
      } else if (status && status === 452) {
        this.logger.pdfLinkIdDuplicateError()
      } else {
        this.logger.serverError()
      }
    } else {
      this.logger.serverError()
    }
    const message = error.error.message || error.statusText;
    return Observable.throw(message)
  }

  public downloadFile(url, headers, type, fileName = '') {
    this.http.get(url, { responseType: 'arraybuffer', headers: headers })
      .subscribe(response => this.downLoadFileFromResponse(response, type, fileName));
  }

  /**
   * Method is use to download file.
   * @param data - Array Buffer data
   * @param type - type of the document.
   */
  private downLoadFileFromResponse(data: any, type: string, fileName: string) {
    const blob = new Blob([data], { type: type});
    importedSaveAs(blob, fileName);
  }

  public multipartRequestWithFormData(method, url, formData, reportProgress = false, automaticErrorHandling = true) {
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    const options = {
      headers: headers,
      reportProgress: reportProgress,
    };

    const req = new HttpRequest(method, url, formData, options);
    if (automaticErrorHandling) {
      return this.handleResponseError(this.http.request(req));
    } else {
      return this.http.request(req);
    }
  }

  protected handleResponseError(req) {
    return req.catch((e: any) => Observable.throw(this.handleErrorObservable(e)))
  }
}
