import { Component, OnDestroy, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Version, VersionService } from '../version.service';
import { DisposeBag } from '../../../classes/dispose-bag.class';
import { Router } from '@angular/router';
import { DxService } from '../../../common/dx.service';
import { LoadingService } from '../../../common/loading.service';
import { Observable } from 'rxjs/Observable';
import { CatalogueService } from 'app/components/catalogue/catalogue.service';
import { LogService } from 'app/common/log.service';
import { ConfigService } from 'app/common/config.service';
import { GridBaseComponent } from 'app/classes/grid-base.component';

@Component({
  selector: 'ms-grid-version',
  templateUrl: './grid-version.component.html',
  styleUrls: ['./grid-version.component.scss']
})
export class GridVersionComponent extends GridBaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() catalogueId: number;
  _catalogueId: number;
  versions$: Observable<Version[]>;
  disposeBag = new DisposeBag();

  constructor(
    public configService: ConfigService,
    private router: Router,
    private versionService: VersionService,
    private catalogueService: CatalogueService,
    private dxService: DxService,
    private loadingService: LoadingService,
    private logger: LogService) {
    super(configService);
    this.editButtonClick = this.editButtonClick.bind(this);
    this.removeButtonClick = this.removeButtonClick.bind(this);
    this.setCurrentButtonClick = this.setCurrentButtonClick.bind(this);
    this._catalogueId = null;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes || !changes.catalogueId || !changes.catalogueId.currentValue) { return; }
    const catalogueId = changes.catalogueId.currentValue
    if (catalogueId === 0) { return; }
    this._catalogueId = catalogueId;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'back',
        onClick: () => this.router.navigate(['catalogue'])
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => this.loadData()
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => {
          if (!this._catalogueId) { return; }
          this.router.navigate(['catalogue', this._catalogueId, 'version', 'add']);
        }
      }
    }
    )
  }

  editButtonClick(e) {
    this.router.navigate(['catalogue', this._catalogueId, 'version', e.row.data.id]);
  }

  removeButtonClick(e) {
    if (!this.isNotCurrent(e)) { return; }
    this.disposeBag.add(this.askConfirmation(this.versionService.delete(e.row.data.id)).subscribe(
      () => this.loadData(),
      () => this.logger.serverError()
    ));
  }

  isNotCurrent(e) {
    return !e.row.data.current;
  }

  setCurrentButtonClick(e) {
    return this.disposeBag.add(this.askConfirmation(this.versionService.setAsCurrent(e.row.data.id)).subscribe(
        () => this.loadData(),
        () => this.logger.serverError()
      )
    );
  }

  downloadFile(url, name) {
    this.versionService.dowloadFile(url, `${name}.pdf`);
  }

  private askConfirmation(observable$: Observable<any>) {
    return this.loadingService.apiRequest$WithLoading(
      this.dxService.dxConfirmWithSuccessfulObservable('WARNING', 'ARE YOU SURE', observable$)
    );
  }

  private loadData() {
    if (!this._catalogueId) { return; }
    this.versions$ = this.loadingService.apiRequest$WithLoading(this.catalogueService.getVersionList(this._catalogueId));
  }
}
