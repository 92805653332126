<mat-form-field appearance="outline" class="nrt-search">
  <input matInput type="text" [formControl]="searchBox" [matAutocomplete]="auto"
    [placeholder]="'SEARCH ADDRESS' | translate" />
  <button *ngIf="!!searchBox.value" (click)="clearSearch()" mat-button>
    <mat-icon>close</mat-icon>
  </button>
  <mat-icon *ngIf="!searchBox.value" aria-hidden="true">search</mat-icon>
</mat-form-field>
<mat-autocomplete autoActiveFirstOption [displayWith]="onAddressSelected" #auto="matAutocomplete">
  <mat-option *ngFor="let option of options | async" [value]="option" (onSelectionChange)="updateSelection(option)">
    {{ option.formattedAddress }}</mat-option>
</mat-autocomplete>