"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var log_service_1 = require("./../../../common/log.service");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var dispose_bag_class_1 = require("../../../classes/dispose-bag.class");
var router_1 = require("@angular/router");
var utils_service_1 = require("../../../common/utils.service");
var version_service_1 = require("../version.service");
var loading_service_1 = require("../../../common/loading.service");
var devextreme_angular_1 = require("devextreme-angular");
var maxFileSize = 150000000; // 150 MB
var FormVersionComponent = /** @class */ (function () {
    function FormVersionComponent(loadingService, router, route, utilsService, versionService, logger) {
        this.loadingService = loadingService;
        this.router = router;
        this.route = route;
        this.utilsService = utilsService;
        this.versionService = versionService;
        this.logger = logger;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
        this.canUpdateFile = false;
        this.isFileUploaded = false;
        this.isFormInvalid = true;
        this.isMaxSizeExceeded = false;
    }
    FormVersionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.utilsService.setPageTitleByTranslateKey('CATALOGUE VERSIONS');
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.getVersion()).subscribe(function (version) {
            _this.version = version;
            _this.canUpdateFile = !version.url;
            if (!!version.id) {
                _this.dxForm.instance.updateData(version);
            }
        }));
        this.disposeBag.add(this.route.params.map(function (params) { return params['id']; }).subscribe(function (id) { return _this.catalogueId = id; }));
    };
    FormVersionComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    FormVersionComponent.prototype.getVersion = function () {
        var _this = this;
        return this.route.params.map(function (params) { return params['version_id']; }).switchMap(function (idFound, _) {
            if (idFound) {
                _this.cardTitle = 'MODIFY VERSION';
                return _this.versionService.getOne(idFound);
            }
            _this.cardTitle = 'ADD VERSION';
            return rxjs_1.of(new version_service_1.Version());
        });
    };
    FormVersionComponent.prototype.onFormSubmit = function () {
        var _this = this;
        if (this.version.id) {
            this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.updateVersion()).subscribe(function () { return _this.goToListPage(); }, function () { return _this.logger.serverError(); }));
        }
        else {
            if (!this.catalogueId) {
                return;
            }
            this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(this.saveVersion()).subscribe(function () { return _this.goToListPage(); }, function () { return _this.logger.serverError(); }));
        }
    };
    FormVersionComponent.prototype.onFieldDataChanged = function () {
        this.isFormInvalid = !this.isFormValid();
    };
    FormVersionComponent.prototype.fileChange = function (event) {
        this.versionFile = null;
        this.isMaxSizeExceeded = false;
        if (event.length > 0) {
            if (event[0].size > maxFileSize) {
                this.uploader.instance.reset();
                this.isMaxSizeExceeded = true;
            }
            else {
                this.versionFile = event[0];
            }
        }
        this.isFileUploaded = !!this.versionFile;
        this.isFormInvalid = !this.isFormValid();
    };
    FormVersionComponent.prototype.validate = function () {
        if (!this.dxForm || !this.dxForm.instance) {
            return { isValid: false };
        }
        return this.dxForm.instance.validate();
    };
    FormVersionComponent.prototype.isFormValid = function () {
        var result = this.validate();
        var isFormValid = result.isValid;
        return this.version.id ? isFormValid : isFormValid && this.isFileUploaded && !this.isMaxSizeExceeded;
    };
    FormVersionComponent.prototype.goToListPage = function () {
        if (!this.catalogueId) {
            return;
        }
        this.router.navigate(['catalogue', this.catalogueId, 'version']);
    };
    FormVersionComponent.prototype.saveVersion = function () {
        this.version.catalogue_id = this.catalogueId;
        return this.versionService.save(this.version, this.versionFile);
    };
    FormVersionComponent.prototype.updateVersion = function () {
        return this.versionService.updateName(this.version.id, this.version.name);
    };
    return FormVersionComponent;
}());
exports.FormVersionComponent = FormVersionComponent;
