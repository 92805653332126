"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TestMapComponent = /** @class */ (function () {
    function TestMapComponent() {
    }
    TestMapComponent.prototype.ngOnInit = function () {
        this.latitude = 5.9714;
        this.longitude = 116.0953;
    };
    return TestMapComponent;
}());
exports.TestMapComponent = TestMapComponent;
