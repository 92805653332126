"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var config_service_1 = require("../common/config.service");
var GridBaseComponent = /** @class */ (function () {
    function GridBaseComponent(configService) {
        this.configService = configService;
    }
    GridBaseComponent.prototype.ngOnInit = function () {
        this.buttonColumnNameWidth = +this.configService.getButtonColumnNameWidth();
        this.numericWidth = +this.configService.getNumericWidth();
        this.shortTextWidth = +this.configService.getShortTextWidth();
        this.timestampWidth = +this.configService.getTimestampWidth();
        this.elapsedTimeWidth = +this.configService.getElapsedTimeWidth();
        this.userFullNameWidth = +this.configService.getUserFullNameWidth();
        this.thumbnailCellWidth = +this.configService.getThumbnailCellWidth();
    };
    return GridBaseComponent;
}());
exports.GridBaseComponent = GridBaseComponent;
