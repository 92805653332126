<dx-data-grid
        id="gridContainer"
        [dataSource]="versions$ | async"
        [allowColumnReordering]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        [showBorders]="true"
        (onToolbarPreparing)="onToolbarPreparing($event)">
    <dxo-export [enabled]="true" fileName="Versions"></dxo-export>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]">
    </dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxi-column [width]="buttonColumnNameWidth" type="buttons" [allowFiltering]="false" [allowExporting]="false"
    [allowSorting]="false">
        <dxi-button icon="trash" [onClick]="removeButtonClick" [visible]="isNotCurrent" hint="{{'REMOVE VERSION' | translate}}"></dxi-button>
        <dxi-button icon="edit" [onClick]="editButtonClick"  hint="{{'MODIFY VERSION' | translate}}"></dxi-button>
        <dxi-button icon="check" [onClick]="setCurrentButtonClick"  hint="{{'SET CURRENT VERSION' | translate}}" [visible]="isNotCurrent"></dxi-button>
    </dxi-column>
    <dxi-column dataField="current" caption="{{'CURRENT' | translate}}" width="250"  dataType="boolean"></dxi-column>
    <dxi-column dataField="url_thumbnail" caption="{{ 'CATALOGUE'| translate }}" [allowExporting]="false" [width]="thumbnailCellWidth"
    [allowFiltering]="false" [allowSorting]="false" cellTemplate="thumbnailCellTemplate"></dxi-column>
    <div *dxTemplate="let cell of 'thumbnailCellTemplate'">
        <img [src]="cell.value" (click)="downloadFile(cell.data.url, cell.data.name)"/>
    </div>
    <dxi-column dataField="name" caption="{{'NAME' | translate}}" dataType="string"></dxi-column>
</dx-data-grid>
