<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div> <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="gene-card-content">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="mrgn-b-lg">
            <dx-form id="dxForm" [formData]="news">
              <dxi-item itemType="group">
                <ms-text-box-multilanguage labelText="{{ 'TITLE' | translate }}" [(model)]="news.title"
                  [isRequired]="true" [(isValid)]="isTitleFormValid" [languages]="languages">
                </ms-text-box-multilanguage>
              </dxi-item>
              <dxi-item [label]="{text: 'DESCRIPTION' | translate }">
                <div *dxTemplate>
                  <ms-html-editor-multilanguage [(model)]="news.description" [isRequired]="false"
                    [(isValid)]="isDescriptionFormValid" [languages]="languages"></ms-html-editor-multilanguage>
                </div>
              </dxi-item>
            </dx-form>
          </div>
          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field class="full-wid">
              <input matInput placeholder="{{ 'LINK' | translate }}" formControlName="link">
            </mat-form-field>
          </div>
          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <img *ngIf="!!news && !!news.photo && news.photo !== ''" class="previewImg"
              [attr.src]="news.photo | blobImageUrl | async" />
            <div class="fileuploader-container">
              <dx-file-uploader #uploader selectButtonText="{{'SELECT IMAGE' | translate}}" labelText=""
                accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
              </dx-file-uploader>
            </div>
          </div>
        </div>
        <div class="pad-wrap">
          <mat-card-actions>
            <button mat-raised-button color="primary" type="submit"
              [disabled]="!isFormValid()">{{ 'SUBMIT' | translate }}</button>
            <button mat-raised-button type="button" (click)="goToListPage()">{{ 'CANCEL' | translate }}</button>
          </mat-card-actions>
        </div>
      </form>
    </div>
  </mat-card>
</div>