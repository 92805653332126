"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var request_service_1 = require("./request.service");
var api_service_1 = require("./api.service");
var i0 = require("@angular/core");
var i1 = require("./request.service");
var i2 = require("./api.service");
var CrudApiService = /** @class */ (function () {
    function CrudApiService(requestService, apiService) {
        this.requestService = requestService;
        this.apiService = apiService;
    }
    CrudApiService.prototype.insert = function (obj) {
        return this.requestService.post(this.apiService.createUrlFromBaseUrl(this.target), JSON.stringify(obj));
    };
    CrudApiService.prototype.getList = function () {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target));
    };
    CrudApiService.prototype.getOne = function (id) {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, id));
    };
    CrudApiService.prototype.patch = function (obj, id) {
        return this.requestService.patch(this.apiService.createUrlFromBaseUrl(this.target, id), JSON.stringify(obj));
    };
    CrudApiService.prototype.put = function (obj, id) {
        return this.requestService.put(this.apiService.createUrlFromBaseUrl(this.target, id), JSON.stringify(obj));
    };
    CrudApiService.prototype.delete = function (id) {
        return this.requestService.delete(this.apiService.createUrlFromBaseUrl(this.target, id));
    };
    CrudApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CrudApiService_Factory() { return new CrudApiService(i0.ɵɵinject(i1.RequestService), i0.ɵɵinject(i2.ApiService)); }, token: CrudApiService, providedIn: "root" });
    return CrudApiService;
}());
exports.CrudApiService = CrudApiService;
