"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
require("hammerjs");
var common_1 = require("@angular/common");
var http_1 = require("@angular/common/http");
var ngx_perfect_scrollbar_1 = require("ngx-perfect-scrollbar");
var core_2 = require("@ngx-translate/core");
var http_loader_1 = require("@ngx-translate/http-loader");
require("here-js-api/scripts/mapsjs-core");
require("here-js-api/scripts/mapsjs-service");
exports.firebaseConfig = {
    apiKey: 'AIzaSyCE0po6Q8jGuBEds-A903KEU4U6Cerojzo',
    authDomain: 'gene-eaeef.firebaseapp.com',
    databaseURL: 'https://gene-eaeef.firebaseio.com',
    projectId: 'gene-eaeef',
    storageBucket: 'gene-eaeef.appspot.com',
    messagingSenderId: '81833823583',
    appId: '1:81833823583:web:581d7ddd8cfa93a4'
};
// AoT requires an exported function for factories
function HttpLoaderFactory(http) {
    return new http_loader_1.TranslateHttpLoader(http);
}
exports.HttpLoaderFactory = HttpLoaderFactory;
function appInitializerFactory(translate, injector) {
    return function () { return new Promise(function (resolve) {
        var locationInitialized = injector.get(common_1.LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(function () {
            translate.setDefaultLang('it');
            var browserLang = translate.getBrowserLang();
            var langToSet = browserLang.match(/en|it/) ? browserLang : 'it';
            translate.use(langToSet).subscribe(function () {
            }, function () {
                console.error("Problem with '" + langToSet + "' language initialization.'");
            }, function () {
                resolve(null);
            });
        });
    }); };
}
exports.appInitializerFactory = appInitializerFactory;
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
var ɵ0 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
exports.ɵ0 = ɵ0;
var GeneAppModule = /** @class */ (function () {
    function GeneAppModule() {
    }
    return GeneAppModule;
}());
exports.GeneAppModule = GeneAppModule;
