"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var MenuToggleDirective = /** @class */ (function () {
    function MenuToggleDirective(router) {
        this.router = router;
        this.navlinks = [];
    }
    MenuToggleDirective.prototype.closeOtherLinks = function (openLink) {
        this.navlinks.forEach(function (link) {
            if (link !== openLink) {
                link.open = false;
            }
        });
    };
    MenuToggleDirective.prototype.addLink = function (link) {
        this.navlinks.push(link);
    };
    MenuToggleDirective.prototype.removeGroup = function (link) {
        var index = this.navlinks.indexOf(link);
        if (index !== -1) {
            this.navlinks.splice(index, 1);
        }
    };
    MenuToggleDirective.prototype.getUrl = function () {
        return this.router.url;
    };
    return MenuToggleDirective;
}());
exports.MenuToggleDirective = MenuToggleDirective;
