import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../common/config.service';

@Component({
  selector: 'app-base',
  template: `NO UI TO BE FOUND HERE!`,
  styleUrls: ['./grid-base.component.css']
})
export class GridBaseComponent implements OnInit {
  buttonColumnNameWidth: number;
  numericWidth: number;
  shortTextWidth: number;
  timestampWidth: number;
  elapsedTimeWidth: number;
  userFullNameWidth: number;
  thumbnailCellWidth: number;

  constructor(public configService: ConfigService) {
  }

  ngOnInit() {
    this.buttonColumnNameWidth = +this.configService.getButtonColumnNameWidth();
    this.numericWidth = +this.configService.getNumericWidth();
    this.shortTextWidth = +this.configService.getShortTextWidth();
    this.timestampWidth = +this.configService.getTimestampWidth();
    this.elapsedTimeWidth = +this.configService.getElapsedTimeWidth();
    this.userFullNameWidth = +this.configService.getUserFullNameWidth();
    this.thumbnailCellWidth = +this.configService.getThumbnailCellWidth();
  }
}
