import { Injectable } from '@angular/core';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { ApiService } from 'app/common/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'news';
  }

  push(id: string) {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, id, 'push'));
  }

  create(news: any, file: any): Observable<any> {
    news = this.updateNewsForInsert(news);
    if (!file) { return this.insert(news); }

    const formData = this.setFormData(news, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target, 'photo'), formData, true);
  }

  update(id: string, news: any, file: any): Observable<any> {
    news = this.updateNewsForUpdate(news);
    if (!file) { return this.put(news, id); }

    const formData = this.setFormData(news, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id, 'photo'), formData, true);
}

  private updateNewsForInsert(news: any) {
    if (news.description == null) { news.description = ''; }
    if (news.link == null) { news.link = ''; }
    delete news['date'];
    return news
  }

  private updateNewsForUpdate(news: any) {
    delete news['date'];
    return news
  }

  private setFormData(news: any, file: any): FormData {
    const formData = new FormData();
    formData.append('data', JSON.stringify(news));
    formData.append('files', file);
    return formData;
  }
}
