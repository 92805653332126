"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("../../service/auth-service/auth.service");
var core_1 = require("@ngx-translate/core");
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(authService, translate) {
        this.authService = authService;
        this.translate = translate;
    }
    /**
      * send method is used to send a reset password link into your email.
      */
    ForgotPasswordComponent.prototype.send = function (value) {
        this.authService.resetPassword(value);
    };
    return ForgotPasswordComponent;
}());
exports.ForgotPasswordComponent = ForgotPasswordComponent;
