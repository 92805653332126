"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var utils_service_1 = require("app/common/utils.service");
var dx_service_1 = require("./../../../common/dx.service");
var news_service_1 = require("./../news.service");
var core_1 = require("@angular/core");
var devextreme_angular_1 = require("devextreme-angular");
var dispose_bag_class_1 = require("app/classes/dispose-bag.class");
var grid_base_component_1 = require("app/classes/grid-base.component");
var config_service_1 = require("app/common/config.service");
var router_1 = require("@angular/router");
var loading_service_1 = require("app/common/loading.service");
var log_service_1 = require("app/common/log.service");
var GridNewsComponent = /** @class */ (function (_super) {
    __extends(GridNewsComponent, _super);
    function GridNewsComponent(configService, router, loadingService, newsService, dxService, logger, utils) {
        var _this = _super.call(this, configService) || this;
        _this.configService = configService;
        _this.router = router;
        _this.loadingService = loadingService;
        _this.newsService = newsService;
        _this.dxService = dxService;
        _this.logger = logger;
        _this.utils = utils;
        _this.disposeBag = new dispose_bag_class_1.DisposeBag();
        return _this;
    }
    GridNewsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadData();
        this.linkButtonClick = this.linkButtonClick.bind(this);
        this.removeButtonClick = this.removeButtonClick.bind(this);
        this.cloneButtonClick = this.cloneButtonClick.bind(this);
    };
    GridNewsComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    GridNewsComponent.prototype.linkButtonClick = function (e) {
        this.router.navigate(['news', e.row.data.id]);
    };
    GridNewsComponent.prototype.isNotSent = function (e) {
        return !e.row.data.date;
    };
    GridNewsComponent.prototype.cloneButtonClick = function (e) {
        this.router.navigate(['news', e.row.data.id], { queryParams: { clone: true } });
    };
    GridNewsComponent.prototype.removeButtonClick = function (e) {
        var _this = this;
        this.disposeBag.add(this.askConfirmation(this.newsService.delete(e.row.data.id)).subscribe(function () { return _this.loadData(); }, function () { return _this.logger.serverError(); }));
    };
    GridNewsComponent.prototype.sendPush = function (e) {
        var _this = this;
        this.disposeBag.add(this.askConfirmation(this.newsService.push(e.row.data.id)).subscribe(function () { return _this.loadData(); }, function () { return _this.logger.serverError(); }));
    };
    GridNewsComponent.prototype.onToolbarPreparing = function (e) {
        var _this = this;
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: function () { return _this.loadData(); }
            }
        }, {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'add',
                onClick: function () { return _this.router.navigate(['news/add']); }
            }
        });
    };
    GridNewsComponent.prototype.loadData = function () {
        var _this = this;
        this.news$ = this.loadingService.apiRequest$WithLoading(this.newsService.getList()).map(function (newsList) {
            return newsList.map(function (news) {
                news.title = _this.utils.setViewOfMultiLanguageField(news.title);
                return news;
            });
        });
    };
    GridNewsComponent.prototype.askConfirmation = function (observable$) {
        return this.loadingService.apiRequest$WithLoading(this.dxService.dxConfirmWithSuccessfulObservable('WARNING', 'ARE YOU SURE', observable$));
    };
    return GridNewsComponent;
}(grid_base_component_1.GridBaseComponent));
exports.GridNewsComponent = GridNewsComponent;
