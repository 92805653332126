"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(router) {
        this.router = router;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            console.log(localStorage.getItem('currentUser'));
            return true;
        }
        // not logged in so redirect to login page
        this.router.navigate(['/session/login'], { queryParams: { returnUrl: state.url } });
        return false;
    };
    return AuthGuardService;
}());
exports.AuthGuardService = AuthGuardService;
