import { UtilsService } from 'app/common/utils.service';
import { DxService } from './../../../common/dx.service';
import { NewsService } from './../news.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { Observable } from 'rxjs';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { ConfigService } from 'app/common/config.service';
import { Router } from '@angular/router';
import { LoadingService } from 'app/common/loading.service';
import { LogService } from 'app/common/log.service';

@Component({
  selector: 'ms-grid-news',
  templateUrl: './grid-news.component.html',
  styleUrls: ['./grid-news.component.scss']
})
export class GridNewsComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  disposeBag = new DisposeBag();
  news$: Observable<any[]>;

  constructor(
    public configService: ConfigService,
    private router: Router,
    private loadingService: LoadingService,
    private newsService: NewsService,
    private dxService: DxService,
    private logger: LogService,
    private utils: UtilsService) {
    super(configService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.linkButtonClick = this.linkButtonClick.bind(this);
    this.removeButtonClick = this.removeButtonClick.bind(this);
    this.cloneButtonClick = this.cloneButtonClick.bind(this)
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  linkButtonClick(e) {
    this.router.navigate(['news', e.row.data.id]);
  }

  isNotSent(e) {
    return !e.row.data.date;
  }

  cloneButtonClick(e) {
    this.router.navigate(['news', e.row.data.id], { queryParams: { clone: true } });
  }

  removeButtonClick(e) {
    this.disposeBag.add(this.askConfirmation(this.newsService.delete(e.row.data.id)).subscribe(
      () => this.loadData(),
      () => this.logger.serverError()
    ));
  }

  sendPush(e) {
    this.disposeBag.add(this.askConfirmation(this.newsService.push(e.row.data.id)).subscribe(
      () => this.loadData(),
      () => this.logger.serverError()
    ));
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => this.loadData()
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => this.router.navigate(['news/add'])
        }
      }
    )
  }

  private loadData() {
    this.news$ = this.loadingService.apiRequest$WithLoading(this.newsService.getList()).map(newsList => {
      return newsList.map(news => {
        news.title = this.utils.setViewOfMultiLanguageField(news.title);
        return news;
      })
    });
  }

  private askConfirmation(observable$: Observable<any>) {
    return this.loadingService.apiRequest$WithLoading(
      this.dxService.dxConfirmWithSuccessfulObservable('WARNING', 'ARE YOU SURE', observable$)
    );
  }
}
