"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var crud_api_service_1 = require("app/common/crud-api.service");
var request_service_1 = require("app/common/request.service");
var api_service_1 = require("app/common/api.service");
var i0 = require("@angular/core");
var i1 = require("../../common/request.service");
var i2 = require("../../common/api.service");
var LocationService = /** @class */ (function (_super) {
    __extends(LocationService, _super);
    function LocationService(requestService, apiService) {
        var _this = _super.call(this, requestService, apiService) || this;
        _this.requestService = requestService;
        _this.apiService = apiService;
        _this.target = 'location';
        return _this;
    }
    LocationService.prototype.create = function (location, file) {
        if (!file) {
            return this.insert(location);
        }
        var formData = this.setFormData(location, file);
        return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target, 'photo'), formData, true);
    };
    LocationService.prototype.update = function (id, location, file) {
        if (!file) {
            return this.put(location, id);
        }
        var formData = this.setFormData(location, file);
        return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id, 'photo'), formData, true);
    };
    LocationService.prototype.setFormData = function (location, file) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(location));
        formData.append('files', file);
        return formData;
    };
    LocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocationService_Factory() { return new LocationService(i0.ɵɵinject(i1.RequestService), i0.ɵɵinject(i2.ApiService)); }, token: LocationService, providedIn: "root" });
    return LocationService;
}(crud_api_service_1.CrudApiService));
exports.LocationService = LocationService;
