"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./test-map.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../common/leaflet-map/leaflet-map.component.ngfactory");
var i3 = require("../../common/leaflet-map/leaflet-map.component");
var i4 = require("./test-map.component");
var styles_TestMapComponent = [i0.styles];
var RenderType_TestMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TestMapComponent, data: {} });
exports.RenderType_TestMapComponent = RenderType_TestMapComponent;
function View_TestMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "gene-card-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ms-leaflet-map", [], null, [[null, "latitudeChange"], [null, "longitudeChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("latitudeChange" === en)) {
        var pd_0 = ((_co.latitude = $event) !== false);
        ad = (pd_0 && ad);
    } if (("longitudeChange" === en)) {
        var pd_1 = ((_co.longitude = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_LeafletMapComponent_0, i2.RenderType_LeafletMapComponent)), i1.ɵdid(2, 49152, null, 0, i3.LeafletMapComponent, [], { latitude: [0, "latitude"], longitude: [1, "longitude"] }, { latitudeChange: "latitudeChange", longitudeChange: "longitudeChange" }), (_l()(), i1.ɵted(3, null, [" ", " ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.latitude; var currVal_1 = _co.longitude; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.latitude; var currVal_3 = _co.longitude; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
exports.View_TestMapComponent_0 = View_TestMapComponent_0;
function View_TestMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ms-test-map", [], null, null, null, View_TestMapComponent_0, RenderType_TestMapComponent)), i1.ɵdid(1, 114688, null, 0, i4.TestMapComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TestMapComponent_Host_0 = View_TestMapComponent_Host_0;
var TestMapComponentNgFactory = i1.ɵccf("ms-test-map", i4.TestMapComponent, View_TestMapComponent_Host_0, {}, {}, []);
exports.TestMapComponentNgFactory = TestMapComponentNgFactory;
