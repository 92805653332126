"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var app_api_service_1 = require("./../../../common/app-api.service");
var dispose_bag_class_1 = require("app/classes/dispose-bag.class");
var info_service_1 = require("./../info.service");
var utils_service_1 = require("./../../../common/utils.service");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var loading_service_1 = require("app/common/loading.service");
var router_1 = require("@angular/router");
var devextreme_angular_1 = require("devextreme-angular");
var maxImageSize = 1000000; // 1 MB
var FormInfoComponent = /** @class */ (function () {
    function FormInfoComponent(router, utilsService, infoService, loadingService, appApiService) {
        this.router = router;
        this.utilsService = utilsService;
        this.infoService = infoService;
        this.loadingService = loadingService;
        this.appApiService = appApiService;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
        this.isMaxSizeExceeded = false;
        this.isFormInvalid = true;
        this.isTitleFormValid = false;
        this.isDescriptionFormValid = false;
    }
    FormInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.utilsService.setPageTitleByTranslateKey('INFO');
        this.disposeBag.add(rxjs_1.zip(this.loadingService.apiRequest$WithLoading(this.infoService.get()), this.appApiService.getLanguages()).subscribe(function (array) {
            var info = array[0];
            _this.info = info;
            _this.setEmptyData();
            _this.languages = array[1];
            _this.languages = _this.languages.map(function (language) {
                language.key = language.code.toLowerCase();
                return language;
            });
        }));
    };
    FormInfoComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    FormInfoComponent.prototype.setEmptyData = function () {
        if (!this.info.name) {
            this.info.name = '';
        }
        if (!this.info.description) {
            this.info.description = '';
        }
    };
    FormInfoComponent.prototype.onFormSubmit = function (event) {
        var _this = this;
        var upload$ = this.loadingService.apiRequest$WithLoadingAndProgress(this.infoService.save(this.info, this.photoToUpload));
        this.disposeBag.add(upload$.subscribe(function () {
            _this.goToListPage();
        }));
    };
    FormInfoComponent.prototype.onContentReady = function (event) {
        this.isFormInvalid = !this.isFormValid();
    };
    FormInfoComponent.prototype.onFieldDataChanged = function (event) {
        this.isFormInvalid = !this.isFormValid();
    };
    FormInfoComponent.prototype.fileChange = function (event) {
        var files = event;
        this.photoToUpload = null;
        this.isMaxSizeExceeded = false;
        if (files.length > 0) {
            if (files[0].size > maxImageSize) {
                this.uploader.instance.reset();
                this.isMaxSizeExceeded = true;
            }
            else {
                this.photoToUpload = files[0];
            }
        }
    };
    FormInfoComponent.prototype.validate = function () {
        if (!this.dxForm || !this.dxForm.instance) {
            return { isValid: false };
        }
        return this.dxForm.instance.validate();
    };
    FormInfoComponent.prototype.isFormValid = function () {
        var result = this.validate();
        var isFormValid = result.isValid;
        return isFormValid;
    };
    FormInfoComponent.prototype.goToListPage = function () {
        this.router.navigate(['info']);
    };
    return FormInfoComponent;
}());
exports.FormInfoComponent = FormInfoComponent;
