"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var Observable_1 = require("rxjs/Observable");
require("rxjs/add/operator/map");
require("rxjs/add/observable/throw");
require("rxjs/add/operator/do"); // debug
require("rxjs/add/operator/catch");
var log_service_1 = require("./log.service");
var file_saver_1 = require("file-saver");
var token_interceptor_1 = require("../authentication/token-interceptor");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./log.service");
var RequestService = /** @class */ (function () {
    function RequestService(http, logger) {
        this.http = http;
        this.logger = logger;
    }
    RequestService.prototype.get = function (url, headers) {
        if (headers === void 0) { headers = {}; }
        return this.request('GET', url, headers);
    };
    RequestService.prototype.put = function (url, data, headers) {
        if (headers === void 0) { headers = {}; }
        return this.request('PUT', url, data, headers);
    };
    RequestService.prototype.post = function (url, data, headers) {
        if (headers === void 0) { headers = {}; }
        return this.request('POST', url, data, headers);
    };
    RequestService.prototype.patch = function (url, data, headers) {
        if (headers === void 0) { headers = {}; }
        return this.request('PATCH', url, data, headers);
    };
    RequestService.prototype.delete = function (url, headers) {
        if (headers === void 0) { headers = {}; }
        return this.request('DELETE', url, headers);
    };
    RequestService.prototype.request = function (method, url, data, headers) {
        if (headers === void 0) { headers = {}; }
        var options = { headers: headers };
        var req = undefined;
        switch (method) {
            case 'GET': {
                req = this.http.get(url, options);
                break;
            }
            case 'PUT': {
                req = this.http.put(url, data, options);
                break;
            }
            case 'POST': {
                req = this.http.post(url, data, options);
                break;
            }
            case 'PATCH': {
                req = this.http.patch(url, data, options);
                break;
            }
            case 'DELETE': {
                req = this.http.delete(url, options);
                break;
            }
            default: {
                break;
            }
        }
        return this.handleResponseError(req.pipe(operators_1.take(1)));
    };
    RequestService.prototype.handleErrorObservable = function (error) {
        console.error(error);
        if (error instanceof http_1.HttpErrorResponse) {
            var status_1 = error.status;
            if (status_1 && status_1 === 409) {
                this.logger.alreadyBookedError();
            }
            else if (status_1 && status_1 === 406) {
                this.logger.notAccetableError();
            }
            else if (status_1 && status_1 === 400) {
                this.logger.badRequestError();
            }
            else if (status_1 && status_1 === 452) {
                this.logger.pdfLinkIdDuplicateError();
            }
            else {
                this.logger.serverError();
            }
        }
        else {
            this.logger.serverError();
        }
        var message = error.error.message || error.statusText;
        return Observable_1.Observable.throw(message);
    };
    RequestService.prototype.downloadFile = function (url, headers, type, fileName) {
        var _this = this;
        if (fileName === void 0) { fileName = ''; }
        this.http.get(url, { responseType: 'arraybuffer', headers: headers })
            .subscribe(function (response) { return _this.downLoadFileFromResponse(response, type, fileName); });
    };
    /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document.
     */
    RequestService.prototype.downLoadFileFromResponse = function (data, type, fileName) {
        var blob = new Blob([data], { type: type });
        file_saver_1.saveAs(blob, fileName);
    };
    RequestService.prototype.multipartRequestWithFormData = function (method, url, formData, reportProgress, automaticErrorHandling) {
        if (reportProgress === void 0) { reportProgress = false; }
        if (automaticErrorHandling === void 0) { automaticErrorHandling = true; }
        var headers = new http_1.HttpHeaders().set(token_interceptor_1.InterceptorSkipHeader, '');
        var options = {
            headers: headers,
            reportProgress: reportProgress,
        };
        var req = new http_1.HttpRequest(method, url, formData, options);
        if (automaticErrorHandling) {
            return this.handleResponseError(this.http.request(req));
        }
        else {
            return this.http.request(req);
        }
    };
    RequestService.prototype.handleResponseError = function (req) {
        var _this = this;
        return req.catch(function (e) { return Observable_1.Observable.throw(_this.handleErrorObservable(e)); });
    };
    RequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RequestService_Factory() { return new RequestService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LogService)); }, token: RequestService, providedIn: "root" });
    return RequestService;
}());
exports.RequestService = RequestService;
