"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var material_1 = require("@angular/material");
var core_2 = require("@ngx-translate/core");
var user_service_1 = require("../user.service");
var log_service_1 = require("app/common/log.service");
var dispose_bag_class_1 = require("../../../classes/dispose-bag.class");
var loading_service_1 = require("../../../common/loading.service");
var regEx = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
var FormPasswordComponent = /** @class */ (function () {
    function FormPasswordComponent(dialogRef, translateService, fb, userService, logger, data, loadingService) {
        this.dialogRef = dialogRef;
        this.translateService = translateService;
        this.fb = fb;
        this.userService = userService;
        this.logger = logger;
        this.data = data;
        this.loadingService = loadingService;
        this.loading = false;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
    }
    FormPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.translate('INSERT PASSWORD').subscribe(function (res) {
            _this.cardTitle = res;
        });
        this.disposeBag.add(this.loadingService.loading$.subscribe(function (res) { _this.loading = res; }));
        this.form = this.fb.group({
            password: [null, forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern(regEx)])],
            confirmPassword: [null, forms_1.Validators.compose([forms_1.Validators.required, forms_1.Validators.pattern(regEx)])]
        }, {
            validator: this.checkPassword
        });
    };
    FormPasswordComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    FormPasswordComponent.prototype.formToObj = function () {
        var obj = {};
        obj['password'] = this.form.controls.password.value;
        return obj;
    };
    FormPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.form.valid) {
            return;
        }
        var userId = this.data;
        var input = this.formToObj();
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.userService.updatePassword(input, userId)).subscribe(function (res) {
            _this.onClose();
            _this.translate('UPDATED PASSWORD').subscribe(function (msg) {
                _this.logger.success(msg);
            });
        }));
    };
    FormPasswordComponent.prototype.onClose = function () {
        this.dialogRef.close(undefined);
    };
    FormPasswordComponent.prototype.translate = function (key) {
        return this.translateService.get(key);
    };
    //TODO da centralizzare
    FormPasswordComponent.prototype.checkPassword = function (form) {
        return form.controls.password.value === form.controls.confirmPassword.value ? null : { 'mismatch': true };
    };
    FormPasswordComponent.prototype.validatePassword = function () {
        return (this.form.controls.password.hasError('required') || this.form.controls.password.hasError('pattern'))
            && this.form.controls.password.touched;
    };
    FormPasswordComponent.prototype.matchPassword = function () {
        return !this.form.controls.password.hasError('pattern') && this.form.controls.password.value !== this.form.controls.confirmPassword.value
            && this.form.controls.confirmPassword.touched;
    };
    return FormPasswordComponent;
}());
exports.FormPasswordComponent = FormPasswordComponent;
