"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var form_process_paper_copy_request_component_1 = require("./components/paper-copy-request/form-process-paper-copy-request/form-process-paper-copy-request.component");
var paper_copy_request_component_1 = require("./components/paper-copy-request/paper-copy-request.component");
var form_news_component_1 = require("./components/news/form-news/form-news.component");
var router_1 = require("@angular/router");
var main_component_1 = require("./main/main.component");
var user_component_1 = require("./components/user/user.component");
var form_user_component_1 = require("./components/user/form-user/form-user.component");
var login_component_1 = require("./session/login/login.component");
var auth_guard_service_1 = require("./authentication/auth-guard.service");
var catalogue_component_1 = require("./components/catalogue/catalogue.component");
var form_catalogue_component_1 = require("./components/catalogue/form-catalogue/form-catalogue.component");
var version_component_1 = require("./components/version/version.component");
var form_version_component_1 = require("./components/version/form-version/form-version.component");
var info_component_1 = require("./components/info/info.component");
var form_info_component_1 = require("./components/info/form-info/form-info.component");
var test_map_component_1 = require("./components/test-map/test-map.component");
var news_component_1 = require("./components/news/news.component");
var location_component_1 = require("./components/location/location.component");
var form_location_component_1 = require("./components/location/form-location/form-location.component");
var magazine_component_1 = require("./components/magazine/magazine.component");
var form_magazine_component_1 = require("./components/magazine/form-magazine/form-magazine.component");
var magazine_localization_component_1 = require("./components/magazine/localizations/magazine-localization.component");
var form_magazine_localization_component_1 = require("./components/magazine/localizations/form-magazine-localization/form-magazine-localization.component");
var appRoutes = [
    {
        path: 'session/login',
        component: login_component_1.LoginComponent,
    },
    {
        path: '',
        component: main_component_1.MainComponent,
        canActivate: [auth_guard_service_1.AuthGuardService],
        children: [
            { path: 'users', component: user_component_1.UserComponent },
            { path: 'users/add', component: form_user_component_1.FormUserComponent },
            { path: 'users/:id', component: form_user_component_1.FormUserComponent },
            { path: 'catalogue', component: catalogue_component_1.CatalogueComponent },
            { path: 'catalogue/add', component: form_catalogue_component_1.FormCatalogueComponent },
            { path: 'catalogue/:id', component: form_catalogue_component_1.FormCatalogueComponent },
            { path: 'catalogue/:id/version', component: version_component_1.VersionComponent },
            { path: 'catalogue/:id/version/add', component: form_version_component_1.FormVersionComponent },
            { path: 'catalogue/:id/version/:version_id', component: form_version_component_1.FormVersionComponent },
            { path: 'magazine', component: magazine_component_1.MagazineComponent },
            { path: 'magazine/add', component: form_magazine_component_1.FormMagazineComponent },
            { path: 'magazine/:id', component: form_magazine_component_1.FormMagazineComponent },
            { path: 'magazine/:id/language', component: magazine_localization_component_1.MagazineLocalizationComponent },
            { path: 'magazine/:id/language/add', component: form_magazine_localization_component_1.FormMagazineLocalizationComponent },
            { path: 'magazine/:id/language/:language_id', component: form_magazine_localization_component_1.FormMagazineLocalizationComponent },
            { path: 'info', component: info_component_1.InfoComponent },
            { path: 'info/edit', component: form_info_component_1.FormInfoComponent },
            { path: 'map', component: test_map_component_1.TestMapComponent },
            { path: 'news', component: news_component_1.NewsComponent },
            { path: 'news/add', component: form_news_component_1.FormNewsComponent },
            { path: 'news/:id', component: form_news_component_1.FormNewsComponent },
            { path: 'location', component: location_component_1.LocationComponent },
            { path: 'location/add', component: form_location_component_1.FormLocationComponent },
            { path: 'location/:id', component: form_location_component_1.FormLocationComponent },
            { path: 'paper_copy_request', component: paper_copy_request_component_1.PaperCopyRequestComponent },
            { path: 'paper_copy_request/:id/process', component: form_process_paper_copy_request_component_1.FormProcessPaperCopyRequestComponent }
        ]
    }
];
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    return RoutingModule;
}());
exports.RoutingModule = RoutingModule;
