"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var loading_service_1 = require("../../common/loading.service");
var core_1 = require("@angular/core");
var utils_service_1 = require("app/common/utils.service");
var router_1 = require("@angular/router");
var VersionComponent = /** @class */ (function () {
    function VersionComponent(utilsService, loadingService, route) {
        this.utilsService = utilsService;
        this.loadingService = loadingService;
        this.route = route;
    }
    VersionComponent.prototype.ngOnInit = function () {
        this.utilsService.setPageTitleByTranslateKey('CATALOGUE VERSIONS');
        this.catalogueId$ = this.route.params.map(function (params) { return +params['id']; });
    };
    return VersionComponent;
}());
exports.VersionComponent = VersionComponent;
