import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {RequestService} from './request.service';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CrudApiService {
  protected target: string;

  constructor(protected requestService: RequestService, protected apiService: ApiService) { }

  insert(obj: any) {
    return this.requestService.post(this.apiService.createUrlFromBaseUrl(this.target), JSON.stringify(obj))
  }

  getList(): Observable<any[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target))
  }

  getOne(id: string): Observable<any> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, id))
  }

  patch(obj: any, id: string) {
    return this.requestService.patch(this.apiService.createUrlFromBaseUrl(this.target, id), JSON.stringify(obj))
  }

  put(obj: any, id: string) {
    return this.requestService.put(this.apiService.createUrlFromBaseUrl(this.target, id), JSON.stringify(obj))
  }

  delete(id: string) {
    return this.requestService.delete(this.apiService.createUrlFromBaseUrl(this.target, id))
  }
}




