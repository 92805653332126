"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DisposeBag = /** @class */ (function () {
    function DisposeBag() {
        this.subscriptions = [];
    }
    DisposeBag.prototype.add = function (subscription) {
        this.subscriptions.push(subscription);
    };
    DisposeBag.prototype.dispose = function () {
        for (var _i = 0, _a = this.subscriptions; _i < _a.length; _i++) {
            var subscription = _a[_i];
            subscription.unsubscribe();
        }
    };
    return DisposeBag;
}());
exports.DisposeBag = DisposeBag;
