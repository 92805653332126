import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LoadingService } from "app/common/loading.service";
import { UtilsService } from "app/common/utils.service";
import { Observable } from "rxjs";

@Component({
  selector: 'ms-magazine-localization',
  templateUrl: './magazine-localization.component.html',
  styleUrls: ['./magazine-localization.component.scss'],
})
export class MagazineLocalizationComponent implements OnInit {
  magazineId$: Observable<number>;

  constructor(
    private utilsService: UtilsService,
    public loadingService: LoadingService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('MAGAZINE LANGUAGES');
    this.magazineId$ = this.route.params.map(params => +params['id']);
  }
}