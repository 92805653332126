import { HttpModule } from '@angular/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationService } from './authentication.service';
import { AuthGuardService } from './auth-guard.service';
import { TokenInterceptor } from './token-interceptor';

@NgModule({
  declarations: [],
  imports: [
    CommonModule, HttpModule
  ],
  providers: [AuthenticationService, AuthGuardService, TokenInterceptor]
})
export class AuthenticationModule { }
