import { UtilsService } from 'app/common/utils.service';
import { LocationService } from './../location.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { DxDataGridComponent } from 'devextreme-angular';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { Observable } from 'rxjs';
import { ConfigService } from 'app/common/config.service';
import { Router } from '@angular/router';
import { LoadingService } from 'app/common/loading.service';
import { DxService } from 'app/common/dx.service';
import { LogService } from 'app/common/log.service';

@Component({
  selector: 'ms-grid-location',
  templateUrl: './grid-location.component.html',
  styleUrls: ['./grid-location.component.scss']
})
export class GridLocationComponent extends GridBaseComponent implements OnInit, OnDestroy {
  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  disposeBag = new DisposeBag();
  locations$: Observable<any[]>;

  constructor(
    public configService: ConfigService,
    private router: Router,
    private loadingService: LoadingService,
    private dxService: DxService,
    private logger: LogService,
    private locationService: LocationService,
    private utils: UtilsService) {
    super(configService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.linkButtonClick = this.linkButtonClick.bind(this);
    this.removeButtonClick = this.removeButtonClick.bind(this);
  }

  ngOnDestroy() {
    this.disposeBag.dispose();
  }

  linkButtonClick(e) {
    this.router.navigate(['location', e.row.data.id]);
  }

  removeButtonClick(e) {
    this.disposeBag.add(this.askConfirmation(this.locationService.delete(e.row.data.id)).subscribe(
      () => this.loadData(),
      () => this.logger.serverError()
    ));
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => this.loadData()
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => this.router.navigate(['location/add'])
        }
      }
    )
  }

  private loadData() {
    this.locations$ = this.loadingService.apiRequest$WithLoading(this.locationService.getList()).map(locations => {
      return locations.map(location => {
        location.name = this.utils.setViewOfMultiLanguageField(location.name);
        return location;
      })
    });
  }

  private askConfirmation(observable$: Observable<any>) {
    return this.loadingService.apiRequest$WithLoading(
      this.dxService.dxConfirmWithSuccessfulObservable('WARNING', 'ARE YOU SURE', observable$)
    );
  }
}
