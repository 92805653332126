import { UtilsService } from './../../../common/utils.service';
import { User } from './../../user/user.service';
import { LogService } from 'app/common/log.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from 'app/common/loading.service';
import { Observable, zip } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DisposeBag } from './../../../classes/dispose-bag.class';
import { DxFormComponent } from 'devextreme-angular';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AppApiService } from 'app/common/app-api.service';

@Component({
  selector: 'ms-form-process-paper-copy-request',
  templateUrl: './form-process-paper-copy-request.component.html',
  styleUrls: ['./form-process-paper-copy-request.component.scss']
})
export class FormProcessPaperCopyRequestComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  public form: FormGroup;
  public id: string;
  formData: any;
  data: any;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public loadingService: LoadingService,
    private logger: LogService,
    private appApiService: AppApiService,
    private utils: UtilsService) { }

  ngOnInit() {
    this.form = this.fb.group({
      note: [null],
    });
    this.disposeBag.add(this.route.params.subscribe(params => {
      this.id = String(params['id']);
      this.setEmptyData();
      this.loadData();
    }));
  }

  private loadData() {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(zip(this.appApiService.getPaperCopyRequest(this.id), this.utils.translate('COPY'))).map(([paperCopyRequest, translatedCopy]) => {
      paperCopyRequest.user = new User(paperCopyRequest.user);
      paperCopyRequest.detail = this.getDetailString(paperCopyRequest, translatedCopy);
      return paperCopyRequest;
    }).subscribe(paperCopyRequest => {
      this.data = paperCopyRequest;
      this.dxForm.instance.updateData(paperCopyRequest);
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  setEmptyData() {
    this.formData = { note: '' };
  }

  public formToObj(): any {
    return { note: this.form.controls.note.value || '' };
  }

  isFormValid() {
    return this.form.valid;
  }

  public onSubmit() {
    if (!this.isFormValid()) { return; }
    const observable$ = this.processRequest();
    this.handleRequest(observable$);
  }

  private processRequest(): Observable<any> {
    const input = this.formToObj() as any;
    return this.appApiService.processPaperCopyRequest(input, this.id);
  }

  public handleRequest(observable$: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(
      () => this.goToListPage(),
      () => this.logger.serverError()
    ));
    return;
  }

  public goToListPage() {
    this.router.navigate(['paper_copy_request']);
  }

  private getDetailString(body: any, translatedCopy: string): string {
    return body.catalogue_version.catalogue.name + ' (' + body.catalogue_version.name + ' - ' + body.catalogue_version.catalogue.language.code + ') - ' + translatedCopy + body.quantity;
  }

  customizeItemTemplate(item: any) {
    item.template = 'formItem';
  }
}
