<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ cardTitle | translate }}</h4>
        </div> <span fxFlex></span>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="gene-card-content">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="mrgn-b-lg">
            <dx-form id="dxForm" [formData]="location">
              <dxi-item>
                <ms-text-box-multilanguage labelText="{{ 'NAME' | translate }}" [(model)]="location.name"
                  [isRequired]="true" [(isValid)]="isNameFormValid" [languages]="languages">
                </ms-text-box-multilanguage>
              </dxi-item>
              <dxi-item>
                <ms-text-area-multilanguage labelText="{{ 'DESCRIPTION' | translate }}" height="60"
                  [(model)]="location.description" [isRequired]="false" [(isValid)]="isDescriptionFormValid"
                  [languages]="languages"></ms-text-area-multilanguage>
              </dxi-item>
              <dxi-item>
                <ms-text-area-multilanguage labelText="{{ 'SHORT DESCRIPTION' | translate }}" height="60"
                  [(model)]="location.short_description" [isRequired]="false" [(isValid)]="isShortDescriptionFormValid"
                  [languages]="languages"></ms-text-area-multilanguage>
              </dxi-item>
            </dx-form>
          </div>
          <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
            <mat-form-field class="full-wid mrgn-b-lg">
              <input matInput placeholder="{{ 'ADDRESS' | translate }}" formControlName="address">
            </mat-form-field>
            <small class="gene-block"
              *ngIf="form.controls['address'].hasError('required') && form.controls['address'].touched"
              class="mat-text-warn">{{ 'MSG ADDRESS' | translate }}</small>
          </div>
          <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
            <mat-form-field class="full-wid mrgn-b-lg">
              <input matInput placeholder="{{ 'PHONE' | translate }}" formControlName="phone">
            </mat-form-field>
            <small class="gene-block"
              *ngIf="form.controls['phone'].hasError('required') && form.controls['phone'].touched"
              class="mat-text-warn">{{ 'MSG PHONE' | translate }}</small>
          </div>
          <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
            <mat-form-field class="full-wid mrgn-b-lg">
              <input matInput placeholder="{{ 'E-MAIL' | translate }}" formControlName="email">
            </mat-form-field>
            <small class="gene-block"
              *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
              class="mat-text-warn">{{ 'MSG E-MAIL' | translate }}</small>
          </div>
          <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
            <mat-form-field class="full-wid mrgn-b-lg">
              <input matInput placeholder="{{ 'URL' | translate }}" formControlName="url">
            </mat-form-field>
          </div>
          <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
            <mat-form-field class="full-wid mrgn-b-lg">
              <input matInput placeholder="{{ 'CITY' | translate }}" formControlName="city">
            </mat-form-field>
            <small class="gene-block"
              *ngIf="form.controls['city'].hasError('required') && form.controls['city'].touched" class="mat-text-warn">
              {{ 'MSG CITY' | translate }}</small>
          </div>
          <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
            <mat-form-field class="full-wid mrgn-b-lg">
              <input matInput placeholder="{{ 'ZIP' | translate }}" formControlName="zip">
            </mat-form-field>
            <small class="gene-block" *ngIf="form.controls['zip'].hasError('required') && form.controls['zip'].touched"
              class="mat-text-warn">{{ 'MSG ZIP' | translate }}</small>
          </div>
          <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
            <mat-form-field class="full-wid mrgn-b-lg">
              <mat-select placeholder="{{ 'COUNTRY' | translate }}" formControlName="country_id"
                [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let c of countries$ | async" [value]="c.id">{{ c.name }}</mat-option>
              </mat-select>
            </mat-form-field>
            <small class="gene-block"
              *ngIf="form.controls['country_id'].hasError('required') && form.controls['country_id'].touched"
              class="mat-text-warn">{{ 'MSG COUNTRY' | translate}}</small>
          </div>
          <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
            <div class="mrgn-b-lg">
              <mat-checkbox formControlName="main">{{ 'MAIN' | translate }}</mat-checkbox>
            </div>
          </div>
          <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
            <ms-geo-search (selection)="updatePosition($event)"></ms-geo-search>
          </div>
          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <ms-leaflet-map [(latitude)]="location.latitude" [(longitude)]="location.longitude"></ms-leaflet-map>
          </div>
          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <img *ngIf="!!location && !!location.photo && location.photo !== ''" class="previewImg margin-top"
              [attr.src]="location.photo | blobImageUrl | async" />
            <div class="fileuploader-container">
              <dx-file-uploader #uploader selectButtonText="{{'SELECT IMAGE' | translate}}" labelText=""
                accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
              </dx-file-uploader>
            </div>
          </div>
        </div>
        <div class="pad-wrap">
          <mat-card-actions>
            <button mat-raised-button color="primary" type="submit" [disabled]="!isFormValid()">
              {{ 'SUBMIT' | translate }}</button>
            <button mat-raised-button type="button" (click)="goToListPage()">{{ 'CANCEL' | translate }}</button>
          </mat-card-actions>
        </div>
      </form>
    </div>
  </mat-card>
</div>