import { AppApiService } from './../../../common/app-api.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DxFileUploaderComponent, DxFormComponent } from 'devextreme-angular';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PageTitleService } from 'app/core/page-title/page-title.service';
import { UtilsService } from 'app/common/utils.service';
import { LocationService } from '../location.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'app/common/loading.service';
import { Observable, zip } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
  selector: 'ms-form-location',
  templateUrl: './form-location.component.html',
  styleUrls: ['./form-location.component.scss']
})
export class FormLocationComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  @ViewChild(DxFileUploaderComponent, { static: true }) uploader: DxFileUploaderComponent;
  disposeBag = new DisposeBag();
  form: FormGroup;
  cardTitle: string;
  id: string;
  location: any;
  photoToUpload: any;
  languages: any[];
  isNameFormValid: boolean;
  isDescriptionFormValid: boolean;
  isShortDescriptionFormValid: boolean;
  countries$: Observable<any>;

  constructor(
    public loadingService: LoadingService,
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private utils: UtilsService,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private router: Router,
    private appApiService: AppApiService
  ) {
    this.isNameFormValid = false;
    this.isDescriptionFormValid = false;
    this.isShortDescriptionFormValid = false;
  }

  ngOnInit() {
    this.utils.translate('UPDATE LOCATION').subscribe((res: string) => this.cardTitle = res);
    this.utils.translate('LOCATIONS').subscribe((res: string) => this.pageTitleService.setTitle(res));
    this.form = this.fb.group({
      phone: [null, Validators.required],
      url: [null],
      email: [null, Validators.required],
      address: [null, Validators.required],
      zip: [null, Validators.required],
      city: [null, Validators.required],
      country_id: [null, Validators.required],
      main: [null, Validators.required]
    });
    this.countries$ = this.loadingService.apiRequest$WithLoading(this.appApiService.getCountries());
    this.route.params.subscribe(params => {
      this.id = String(params['id']);
      if (!this.isNew()) {
        this.utils.translate('EDIT LOCATION').subscribe((res: string) => this.cardTitle = res);
        this.loadLocationData();
      } else {
        this.form.controls.main.setValue(false);
        this.setEmptyData();
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.appApiService.getLanguages()).subscribe(res => {
          this.languages = res.map((language: any) => {
            language.key = language.code.toLowerCase();
            return language;
          });
        }));
      }
    });
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  setEmptyData() {
    this.location = {
      name: '{}',
      description: '{}',
      short_description: '{}',
      latitude: environment.startLatitude,
      longitude: environment.startLongitude
    };
  }

  loadLocationData() {
    this.disposeBag.add(
      zip(this.loadingService.apiRequest$WithLoading(this.locationService.getOne(this.id)), this.appApiService.getLanguages()).subscribe(array => {
        const location = array[0];
        this.location = location;
        this.objToForm(this.location);
        if (!this.location.description || this.location.description === 'null') { this.location.description = '{}'; }
        if (!this.location.short_description || this.location.short_description === 'null') { this.location.short_description = '{}'; }
        this.languages = array[1];
        this.languages = this.languages.map(language => {
          language.key = language.code.toLowerCase();
          return language;
        });
      })
    );
  }

  objToForm(location: any) {
    this.form.controls.phone.setValue(location.phone);
    this.form.controls.url.setValue(location.url);
    this.form.controls.email.setValue(location.email);
    this.form.controls.address.setValue(location.address);
    this.form.controls.zip.setValue(location.zip);
    this.form.controls.city.setValue(location.city);
    this.form.controls.country_id.setValue(location.country_id);
    this.form.controls.main.setValue(location.main);
  }

  formToObj(): any {
    let obj = {};
    if (this.location) {
      obj = this.location;
      delete obj['photo'];
    } else {
      obj['latitude'] = 0;
      obj['longitude'] = 0;
    }
    obj['name'] = this.location.name;
    obj['description'] = this.location.description;
    obj['short_description'] = this.location.short_description;
    obj['phone'] = this.form.controls.phone.value;
    obj['url'] = this.form.controls.url.value;
    obj['email'] = this.form.controls.email.value;
    obj['address'] = this.form.controls.address.value;
    obj['zip'] = this.form.controls.zip.value;
    obj['city'] = this.form.controls.city.value;
    obj['country_id'] = this.form.controls.country_id.value;
    obj['main'] = this.form.controls.main.value;
    return obj;
  }

  isFormValid() {
    return this.form.valid && this.isNameFormValid && this.isDescriptionFormValid && this.isShortDescriptionFormValid;
  }

  fileChange(event: File[]) {
    this.photoToUpload = null;
    if (event.length > 0) { this.photoToUpload = event[0]; }
  }

  updatePosition(event: any) {
    this.location.latitude = event.lat;
    this.location.longitude = event.lng
  }

  onSubmit() {
    if (!this.isFormValid()) { return; }
    this.handleRequest(this.isNew() ? this.saveLocation() : this.updateLocation(), !!this.photoToUpload);
  }

  isNew(): Boolean {
    return this.id === 'undefined';
  }

  goToListPage() {
    this.router.navigate(['location']);
  }

  private handleRequest(observable$: Observable<any>, progress: boolean) {
    if (!progress) {
      this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(() => this.goToListPage()));
    } else {
      this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(() => this.goToListPage()));
    }
  }

  private updateLocation(): Observable<any> {
    return this.locationService.update(this.id, this.formToObj() as any, this.photoToUpload);
  }

  private saveLocation(): Observable<any> {
    return this.locationService.create(this.formToObj() as any, this.photoToUpload);
  }
}
