import { Injectable } from '@angular/core';
import {from, Observable, of, EMPTY} from 'rxjs';
import {UtilsService} from './utils.service';
import { confirm } from 'devextreme/ui/dialog';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DxService {

  constructor(private utils: UtilsService) { }

  dxiItemEditorOptions(dataObservable: Observable<any>, value: any, displayField: string, valueField: string): Observable<any> {
    return dataObservable.map(dataArray => {
      return {
        dataSource: dataArray,
        displayExpr: (data) => { return !!data ? data[displayField] : null; },
        valueExpr: (data) => { return !!data ? data[valueField] : null; }
      }
    });
  }

  dxConfirm(title: string, messageHtml: string): Observable<boolean> {
    return this.utils.translateArray([title, messageHtml]).pipe(switchMap(([tsTitle, tsMessageHtml]) => from(confirm(tsMessageHtml, tsTitle))))
  }

  dxConfirmWithSuccessfulObservable(title: string, messageHtml: string, successfulObservable: Observable<any>): Observable<any> {
    return this.dxConfirm(title, messageHtml).pipe(switchMap(res => res ? successfulObservable : EMPTY))
  }

  dxToolbarItemOptions(text, onClick): Observable<any> {
    return this.utils.translate(text).map(translatedText => { return { text: translatedText, onClick: onClick }; })
  }
}
