"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var HtmlEditorMultilanguageComponent = /** @class */ (function () {
    function HtmlEditorMultilanguageComponent() {
        this.modelChange = new core_1.EventEmitter();
        this.isValidChange = new core_1.EventEmitter();
        this.formData = {};
        this._isValid = false;
    }
    HtmlEditorMultilanguageComponent.prototype.ngOnInit = function () {
        this.modules = {
            toolbar: [
                'bold',
                'italic',
                'underline',
                { 'header': [1, 2, 3, 4, 5, 6, false] },
                { 'align': [] },
                { 'list': 'ordered' },
                { 'list': 'bullet' },
                { 'script': 'sub' },
                { 'script': 'super' },
                { 'indent': '-1' },
                { 'indent': '+1' },
                'clean'
            ]
        };
    };
    HtmlEditorMultilanguageComponent.prototype.ngOnChanges = function (changes) {
        if (!changes || !changes.model) {
            return;
        }
        var model = changes.model;
        if (model.currentValue !== '{}') {
            this.formData = JSON.parse(model.currentValue);
        }
        this.setIsValid(this.isFormValid());
    };
    HtmlEditorMultilanguageComponent.prototype.onContentChanged = function (e) {
        this.setModel(this.formData);
    };
    Object.defineProperty(HtmlEditorMultilanguageComponent.prototype, "model", {
        get: function () {
            return this._model;
        },
        set: function (val) {
            this.setModel(val);
        },
        enumerable: true,
        configurable: true
    });
    HtmlEditorMultilanguageComponent.prototype.setModel = function (val) {
        this._model = val;
        this.modelChange.emit(JSON.stringify(this.formData));
        this.setIsValid(this.isFormValid());
    };
    HtmlEditorMultilanguageComponent.prototype.isFormValid = function () {
        var _this = this;
        if (!this.isRequired) {
            return true;
        }
        return this.languages.every(function (language) { return !!_this.formData[language.key]; });
    };
    Object.defineProperty(HtmlEditorMultilanguageComponent.prototype, "isValid", {
        get: function () {
            return this._isValid;
        },
        set: function (val) {
            this.setIsValid(val);
        },
        enumerable: true,
        configurable: true
    });
    HtmlEditorMultilanguageComponent.prototype.setIsValid = function (val) {
        this._isValid = val;
        this.isValidChange.emit(this._isValid);
    };
    return HtmlEditorMultilanguageComponent;
}());
exports.HtmlEditorMultilanguageComponent = HtmlEditorMultilanguageComponent;
