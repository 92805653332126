import { AppApiService } from './../../../common/app-api.service';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { InfoService } from './../info.service';
import { UtilsService } from './../../../common/utils.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Info } from '../info.service';
import { Observable, zip } from 'rxjs';
import { LoadingService } from 'app/common/loading.service';
import { Router } from '@angular/router';
import { DxFormComponent, DxFileUploaderComponent } from 'devextreme-angular';

const maxImageSize = 1000000; // 1 MB

@Component({
  selector: 'ms-form-info',
  templateUrl: './form-info.component.html',
  styleUrls: ['./form-info.component.scss']
})
export class FormInfoComponent implements OnInit, OnDestroy {
	@ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
	@ViewChild(DxFileUploaderComponent, { static: true }) uploader: DxFileUploaderComponent;
  info: Info;
	photoToUpload;
	isMaxSizeExceeded: boolean;
	isFormInvalid: boolean;
	isTitleFormValid: boolean;
	isDescriptionFormValid: boolean;
	private disposeBag: DisposeBag;
	languages: any[];

	constructor(
		private router: Router,
		private utilsService: UtilsService,
		private infoService: InfoService,
		public loadingService: LoadingService,
		private appApiService: AppApiService) {
		this.disposeBag = new DisposeBag();
		this.isMaxSizeExceeded = false;
		this.isFormInvalid = true;
		this.isTitleFormValid = false;
		this.isDescriptionFormValid = false;
	}

  ngOnInit() {
		this.utilsService.setPageTitleByTranslateKey('INFO');
  	this.disposeBag.add(zip(this.loadingService.apiRequest$WithLoading(this.infoService.get()), this.appApiService.getLanguages()).subscribe(array => {
			const info = array[0];
			this.info = info;
			this.setEmptyData();
			this.languages = array[1];
			this.languages = this.languages.map(language => {
				language.key = language.code.toLowerCase();
				return language;
			});
		}));
	}
	
	ngOnDestroy(): void {
		this.disposeBag.dispose();
	}

	setEmptyData() {
		if (!this.info.name) { this.info.name = ''; }
		if (!this.info.description) { this.info.description = ''; }
	}

	onFormSubmit(event) {
		const upload$ = this.loadingService.apiRequest$WithLoadingAndProgress(this.infoService.save(this.info, this.photoToUpload));
		this.disposeBag.add(upload$.subscribe(
			() => {
				this.goToListPage();
		}));
	}

	onContentReady(event) {
		this.isFormInvalid = !this.isFormValid();
	}

	onFieldDataChanged(event) {
		this.isFormInvalid = !this.isFormValid();
	}

	fileChange(event) {
    const files = event;
		this.photoToUpload = null;
		this.isMaxSizeExceeded = false;
    if (files.length > 0) {
			if (files[0].size > maxImageSize) {
				this.uploader.instance.reset();
				this.isMaxSizeExceeded = true;
			} else {
				this.photoToUpload = files[0];
      }
		}
	}

	validate() {
		if (!this.dxForm || !this.dxForm.instance) { return { isValid: false }; }
		return this.dxForm.instance.validate();
	}

	isFormValid() {
		const result = this.validate();
		const isFormValid = result.isValid;
		return isFormValid;
  }
	
	public goToListPage() {
		this.router.navigate(['info']);
	}

}