"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var crud_api_service_1 = require("app/common/crud-api.service");
var request_service_1 = require("app/common/request.service");
var api_service_1 = require("app/common/api.service");
var i0 = require("@angular/core");
var i1 = require("../../common/request.service");
var i2 = require("../../common/api.service");
var CatalogueService = /** @class */ (function (_super) {
    __extends(CatalogueService, _super);
    function CatalogueService(requestService, apiService) {
        var _this = _super.call(this, requestService, apiService) || this;
        _this.requestService = requestService;
        _this.apiService = apiService;
        _this.target = 'catalogue';
        return _this;
    }
    CatalogueService.prototype.getVersionList = function (id) {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, String(id), 'version'));
    };
    CatalogueService.prototype.insert = function (catalogue) {
        catalogue.pdf_link_id = !!catalogue.pdf_link_id ? catalogue.pdf_link_id.toLowerCase() : catalogue.pdf_link_id;
        return this.requestService.post(this.apiService.createUrlFromBaseUrl(this.target), JSON.stringify(catalogue));
    };
    CatalogueService.prototype.update = function (catalogue, id) {
        catalogue.pdf_link_id = !!catalogue.pdf_link_id ? catalogue.pdf_link_id.toLowerCase() : catalogue.pdf_link_id;
        return this.requestService.put(this.apiService.createUrlFromBaseUrl(this.target, id), JSON.stringify(catalogue));
    };
    CatalogueService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CatalogueService_Factory() { return new CatalogueService(i0.ɵɵinject(i1.RequestService), i0.ɵɵinject(i2.ApiService)); }, token: CatalogueService, providedIn: "root" });
    return CatalogueService;
}(crud_api_service_1.CrudApiService));
exports.CatalogueService = CatalogueService;
var Um;
(function (Um) {
    Um["KG_M"] = "KG_M";
    Um["LBS_FT"] = "LBS_FT";
})(Um = exports.Um || (exports.Um = {}));
var TranslatedUm;
(function (TranslatedUm) {
    TranslatedUm["KG_M"] = "kg / m";
    TranslatedUm["LBS_FT"] = "lbs / ft";
})(TranslatedUm = exports.TranslatedUm || (exports.TranslatedUm = {}));
