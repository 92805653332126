<dx-data-grid id="gridContainer"
              [dataSource]="news$ | async"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              [showBorders]="true"
              (onToolbarPreparing)="onToolbarPreparing($event)">
    <dxo-export [enabled]="true" fileName="News"></dxo-export>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxi-column type="buttons" [width]="buttonColumnNameWidth">
      <dxi-button icon="trash" [onClick]="removeButtonClick" hint="{{'REMOVE NEWS' | translate}}"></dxi-button>
      <dxi-button icon="edit" [onClick]="linkButtonClick" hint="{{'MODIFY NEWS' | translate}}" [visible]="isNotSent"></dxi-button>
      <dxi-button icon="repeat" [onClick]="cloneButtonClick" hint="{{'CLONE NEWS' | translate}}"></dxi-button>
    </dxi-column>
    <dxi-column dataField="title" caption="{{'TITLE' | translate}}"></dxi-column>
    <dxi-column dataField="date" caption="{{'PUSH SEND DATE' | translate}}" cellTemplate="thumbnailCellTemplate"></dxi-column>
    <div *dxTemplate="let cell of 'thumbnailCellTemplate'">
      <div *ngIf="!!cell.value">{{ cell.value | date:'dd/MM/yyyy, HH:mm' }}</div>
      <div *ngIf="!cell.value">
        <dx-button stylingMode="text" type="normal" text="{{ 'SEND PUSH' | translate }}" (onClick)="sendPush(cell)"></dx-button>
      </div>
    </div>
</dx-data-grid>