<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
    <mat-card fxFlex="100%">
        <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column">
                    <h4>{{ cardTitle | translate }}</h4>
                </div> <span fxFlex></span>
            </div>
            <mat-divider></mat-divider>
        </div>
        <form (submit)="onFormSubmit()">
            <dx-form id="dxForm" [formData]="version" [colCount]="2" (onFieldDataChanged)="onFieldDataChanged()">
                <dxi-item dataField="name" [colSpan]="2" [label]="{text: 'NAME' | translate}">
                    <dxi-validation-rule type="required" [message]="'MSG NAME' | translate"></dxi-validation-rule>
                </dxi-item>
            </dx-form>
            <div class="fileuploader-container" *ngIf="canUpdateFile">
                <dx-file-uploader #uploader selectButtonText="{{'SELECT CATALOGUE' | translate}}" labelText=""
                    accept="application/pdf" uploadMode="useForm" (valueChange)="fileChange($event)">
                </dx-file-uploader>
                </div>
                <div class="field-required-error" *ngIf="isMaxSizeExceeded && canUpdateFile">
                    {{ 'MSG MAX FILE SIZE' | translate}} 150 MB
                </div>
                <div class="field-required-error" *ngIf="!isFileUploaded && canUpdateFile">
                    {{ 'MSG FILE' | translate}}
                </div>
            <div class="pad-wrap" id="form_user_actions">
                <mat-card-actions>
                    <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true" [disabled]="isFormInvalid">
                    </dx-button>
                    <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()" type="normal">
                    </dx-button>
                </mat-card-actions>
            </div>
        </form>
    </mat-card>
</div>