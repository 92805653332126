"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("../../service/auth-service/auth.service");
var core_1 = require("@ngx-translate/core");
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(authService, translate) {
        this.authService = authService;
        this.translate = translate;
    }
    //register method is used to sign up on the template.
    RegisterComponent.prototype.register = function (value) {
        this.authService.signupUserProfile(value);
    };
    return RegisterComponent;
}());
exports.RegisterComponent = RegisterComponent;
