"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var base_interceptor_1 = require("app/classes/base-interceptor");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var authentication_service_1 = require("./authentication.service");
var ErrorInterceptor = /** @class */ (function (_super) {
    __extends(ErrorInterceptor, _super);
    function ErrorInterceptor(authenticationService) {
        var _this = _super.call(this, authenticationService) || this;
        _this.authenticationService = authenticationService;
        return _this;
    }
    ErrorInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        return next.handle(request).pipe(operators_1.catchError(function (error) {
            var user = _this.authenticationService.getUser();
            if (!user) {
                _this.authenticationService.logout();
                location.reload(true);
            }
            // We don't want to refresh token for some requests like login or refresh token itself
            // So we verify url and we throw an error if it's the case
            if (request.url.includes('refresh') || request.url.includes('login')) {
                // We do another check to see if refresh token failed
                // In this case we want to logout user and to redirect it to login page
                if (request.url.includes('refresh')) {
                    // auto logout if 401 response returned from api
                    _this.authenticationService.logout();
                    location.reload(true);
                }
                return rxjs_1.Observable.throwError(error);
            }
            // If error status is different than 401 we want to skip refresh token
            // So we check that and throw the error if it's the case
            if (error.status !== 401) {
                return rxjs_1.Observable.throwError(error);
            }
            return _this.authenticationService.apiRefresh().pipe(operators_1.switchMap(function (token) {
                // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                // for the next time the token needs to be refreshed
                return next.handle(_this.addAuthenticationToken(request));
            }))
                .catch(function (err) {
                _this.authenticationService.logout();
                location.reload(true);
                return rxjs_1.Observable.throwError(error);
            });
        }));
    };
    return ErrorInterceptor;
}(base_interceptor_1.BaseInterceptor));
exports.ErrorInterceptor = ErrorInterceptor;
