"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var loading_service_1 = require("app/common/loading.service");
var utils_service_1 = require("app/common/utils.service");
var MagazineComponent = /** @class */ (function () {
    function MagazineComponent(utilsService, loadingService) {
        this.utilsService = utilsService;
        this.loadingService = loadingService;
    }
    MagazineComponent.prototype.ngOnInit = function () {
        this.utilsService.setPageTitleByTranslateKey('MAGAZINES');
    };
    return MagazineComponent;
}());
exports.MagazineComponent = MagazineComponent;
