"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var utils_service_1 = require("app/common/utils.service");
var loading_service_1 = require("app/common/loading.service");
var LocationComponent = /** @class */ (function () {
    function LocationComponent(utils, loadingService) {
        this.utils = utils;
        this.loadingService = loadingService;
    }
    LocationComponent.prototype.ngOnInit = function () {
        this.utils.setPageTitleByTranslateKey('LOCATIONS');
    };
    return LocationComponent;
}());
exports.LocationComponent = LocationComponent;
