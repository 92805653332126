"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_service_1 = require("./../../common/utils.service");
var core_1 = require("@angular/core");
var loading_service_1 = require("app/common/loading.service");
var InfoComponent = /** @class */ (function () {
    function InfoComponent(utilsService, loadingService) {
        this.utilsService = utilsService;
        this.loadingService = loadingService;
    }
    InfoComponent.prototype.ngOnInit = function () {
        this.utilsService.setPageTitleByTranslateKey('INFO');
    };
    return InfoComponent;
}());
exports.InfoComponent = InfoComponent;
