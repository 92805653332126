"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var devextreme_angular_1 = require("devextreme-angular");
var dispose_bag_class_1 = require("app/classes/dispose-bag.class");
var loading_service_1 = require("app/common/loading.service");
var router_1 = require("@angular/router");
var utils_service_1 = require("app/common/utils.service");
var magazine_localization_service_1 = require("../magazine-localization.service");
var log_service_1 = require("app/common/log.service");
var rxjs_1 = require("rxjs");
var app_api_service_1 = require("app/common/app-api.service");
var dx_service_1 = require("app/common/dx.service");
var maxFileSize = 150000000; // 150 MB
var FormMagazineLocalizationComponent = /** @class */ (function () {
    function FormMagazineLocalizationComponent(loadingService, router, route, utilsService, appApiService, dxService, magazineLocalizationService, logger) {
        this.loadingService = loadingService;
        this.router = router;
        this.route = route;
        this.utilsService = utilsService;
        this.appApiService = appApiService;
        this.dxService = dxService;
        this.magazineLocalizationService = magazineLocalizationService;
        this.logger = logger;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
        this.canUpdateFile = false;
        this.isFileUploaded = false;
        this.isFormInvalid = true;
        this.isMaxSizeExceeded = false;
    }
    FormMagazineLocalizationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.utilsService.setPageTitleByTranslateKey('MAGAZINE LOCALIZATIONS');
        var languages$ = this.appApiService.getMagazineLanguages();
        this.languageEditorOptions$ = this.dxService.dxiItemEditorOptions(languages$, undefined, 'name', 'id');
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.getMagVersion()).subscribe(function (magVersion) {
            _this.magazineVersion = magVersion;
            _this.canUpdateFile = !magVersion.url;
            if (!!magVersion.id) {
                _this.dxForm.instance.updateData(magVersion);
            }
        }));
        this.disposeBag.add(this.route.params.map(function (params) { return params['id']; }).subscribe(function (id) { return _this.magazineId = id; }));
    };
    FormMagazineLocalizationComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    FormMagazineLocalizationComponent.prototype.getMagVersion = function () {
        var _this = this;
        return this.route.params.map(function (params) { return params['language_id']; }).switchMap(function (idFound, _) {
            if (idFound) {
                _this.cardTitle = 'MODIFY VERSION';
                return _this.magazineLocalizationService.getOne(idFound);
            }
            _this.cardTitle = 'ADD VERSION';
            return rxjs_1.of(new magazine_localization_service_1.MagVersion());
        });
    };
    FormMagazineLocalizationComponent.prototype.onFormSubmit = function () {
        var _this = this;
        if (this.magazineVersion.id) {
            this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.updateLocalization()).subscribe(function () { return _this.goToListPage(); }, function (error) { return _this.showError(error); }));
        }
        else {
            if (!this.magazineId) {
                return;
            }
            this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(this.saveVersion()).subscribe(function () { return _this.goToListPage(); }, function (error) { return _this.showError(error); }));
        }
    };
    FormMagazineLocalizationComponent.prototype.showError = function (error) {
        // if error is an instance of HttpErrorResponse, check error.status
        console.log(error);
        if (error.status === 409) {
            this.logger.duplicateLanguageForMagazineError();
            return;
        }
        this.logger.serverError();
    };
    FormMagazineLocalizationComponent.prototype.fileChange = function (event) {
        this.languageFile = null;
        this.isMaxSizeExceeded = false;
        if (event.length > 0) {
            if (event[0].size > maxFileSize) {
                this.uploader.instance.reset();
                this.isMaxSizeExceeded = true;
            }
            else {
                this.languageFile = event[0];
            }
        }
        this.isFileUploaded = !!this.languageFile;
        this.isFormInvalid = !this.isFormValid();
    };
    FormMagazineLocalizationComponent.prototype.onFieldDataChanged = function () {
        this.isFormInvalid = !this.isFormValid();
    };
    FormMagazineLocalizationComponent.prototype.validate = function () {
        if (!this.dxForm || !this.dxForm.instance) {
            return { isValid: false };
        }
        return this.dxForm.instance.validate();
    };
    FormMagazineLocalizationComponent.prototype.isFormValid = function () {
        var result = this.validate();
        var isFormValid = result.isValid;
        return this.magazineVersion.id ? isFormValid : isFormValid && this.isFileUploaded && !this.isMaxSizeExceeded;
    };
    FormMagazineLocalizationComponent.prototype.goToListPage = function () {
        if (!this.magazineId) {
            return;
        }
        this.router.navigate(['magazine', this.magazineId, 'language']);
    };
    FormMagazineLocalizationComponent.prototype.saveVersion = function () {
        this.magazineVersion.magazine_id = this.magazineId;
        return this.magazineLocalizationService.save(this.magazineVersion, this.languageFile);
    };
    FormMagazineLocalizationComponent.prototype.updateLocalization = function () {
        return this.magazineLocalizationService.updateLocalization(this.magazineVersion.id, this.magazineVersion.name, this.magazineVersion.language_id);
    };
    return FormMagazineLocalizationComponent;
}());
exports.FormMagazineLocalizationComponent = FormMagazineLocalizationComponent;
