import { Router } from '@angular/router';
import { UtilsService } from 'app/common/utils.service';
import { AppApiService } from './../../../common/app-api.service';
import { Component, OnInit } from '@angular/core';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { Observable, zip } from 'rxjs';
import { ConfigService } from 'app/common/config.service';
import { LoadingService } from 'app/common/loading.service';
import { User } from 'app/components/user/user.service';

@Component({
  selector: 'ms-grid-paper-copy-request',
  templateUrl: './grid-paper-copy-request.component.html',
  styleUrls: ['./grid-paper-copy-request.component.scss']
})
export class GridPaperCopyRequestComponent extends GridBaseComponent implements OnInit {
  paperCopies$: Observable<any[]>;

  constructor(
    public configService: ConfigService,
    private loadingService: LoadingService,
    private appApiService: AppApiService,
    private utils: UtilsService,
    private router: Router) {
    super(configService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.processButtonClick = this.processButtonClick.bind(this);

   
  }

  processButtonClick(e) {
    this.router.navigate(['paper_copy_request', e.row.data.id, 'process']);
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => this.loadData()
      }
    });
  }

  private loadData() {
    this.paperCopies$ = this.loadingService.apiRequest$WithLoading(zip(this.appApiService.getPaperCopiesRequired(), this.utils.translate('COPY'))).map(items => {
      const paperCopies = items[0];
      const translatedCopy = items[1];
      return paperCopies.map(paperCopy => {
        paperCopy.user = new User(paperCopy.user);
        paperCopy.detail = this.getDetailString(paperCopy, translatedCopy);
        return paperCopy;
      });
    });
  }

  isNotProcessed(e) {
    return !e.row.data.processed;
  }

  private getDetailString(body: any, translatedCopy: string): string {
    return body.catalogue_version.catalogue.name + ' (' + body.catalogue_version.name + ' - ' + body.catalogue_version.catalogue.language.code + ') - ' + translatedCopy + body.quantity;
  }

  customizeItemTemplate(item: any) {
    item.template = 'formItem';
  }

  onRowPrepared(e) {
    if (!(e.rowType === 'data' && e.data.processed === false)) { return; }
    e.rowElement.querySelector('.dx-command-expand').firstChild.classList.remove('dx-datagrid-group-closed');
    e.rowElement.querySelector('.dx-command-expand').classList.remove('dx-datagrid-expand');
  }
}
