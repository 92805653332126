"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_service_1 = require("./../../../common/utils.service");
var user_service_1 = require("./../../user/user.service");
var log_service_1 = require("app/common/log.service");
var router_1 = require("@angular/router");
var router_2 = require("@angular/router");
var loading_service_1 = require("app/common/loading.service");
var rxjs_1 = require("rxjs");
var forms_1 = require("@angular/forms");
var dispose_bag_class_1 = require("./../../../classes/dispose-bag.class");
var devextreme_angular_1 = require("devextreme-angular");
var core_1 = require("@angular/core");
var app_api_service_1 = require("app/common/app-api.service");
var FormProcessPaperCopyRequestComponent = /** @class */ (function () {
    function FormProcessPaperCopyRequestComponent(fb, route, router, loadingService, logger, appApiService, utils) {
        this.fb = fb;
        this.route = route;
        this.router = router;
        this.loadingService = loadingService;
        this.logger = logger;
        this.appApiService = appApiService;
        this.utils = utils;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
    }
    FormProcessPaperCopyRequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.fb.group({
            note: [null],
        });
        this.disposeBag.add(this.route.params.subscribe(function (params) {
            _this.id = String(params['id']);
            _this.setEmptyData();
            _this.loadData();
        }));
    };
    FormProcessPaperCopyRequestComponent.prototype.loadData = function () {
        var _this = this;
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(rxjs_1.zip(this.appApiService.getPaperCopyRequest(this.id), this.utils.translate('COPY'))).map(function (_a) {
            var paperCopyRequest = _a[0], translatedCopy = _a[1];
            paperCopyRequest.user = new user_service_1.User(paperCopyRequest.user);
            paperCopyRequest.detail = _this.getDetailString(paperCopyRequest, translatedCopy);
            return paperCopyRequest;
        }).subscribe(function (paperCopyRequest) {
            _this.data = paperCopyRequest;
            _this.dxForm.instance.updateData(paperCopyRequest);
        }));
    };
    FormProcessPaperCopyRequestComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    FormProcessPaperCopyRequestComponent.prototype.setEmptyData = function () {
        this.formData = { note: '' };
    };
    FormProcessPaperCopyRequestComponent.prototype.formToObj = function () {
        return { note: this.form.controls.note.value || '' };
    };
    FormProcessPaperCopyRequestComponent.prototype.isFormValid = function () {
        return this.form.valid;
    };
    FormProcessPaperCopyRequestComponent.prototype.onSubmit = function () {
        if (!this.isFormValid()) {
            return;
        }
        var observable$ = this.processRequest();
        this.handleRequest(observable$);
    };
    FormProcessPaperCopyRequestComponent.prototype.processRequest = function () {
        var input = this.formToObj();
        return this.appApiService.processPaperCopyRequest(input, this.id);
    };
    FormProcessPaperCopyRequestComponent.prototype.handleRequest = function (observable$) {
        var _this = this;
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(function () { return _this.goToListPage(); }, function () { return _this.logger.serverError(); }));
        return;
    };
    FormProcessPaperCopyRequestComponent.prototype.goToListPage = function () {
        this.router.navigate(['paper_copy_request']);
    };
    FormProcessPaperCopyRequestComponent.prototype.getDetailString = function (body, translatedCopy) {
        return body.catalogue_version.catalogue.name + ' (' + body.catalogue_version.name + ' - ' + body.catalogue_version.catalogue.language.code + ') - ' + translatedCopy + body.quantity;
    };
    FormProcessPaperCopyRequestComponent.prototype.customizeItemTemplate = function (item) {
        item.template = 'formItem';
    };
    return FormProcessPaperCopyRequestComponent;
}());
exports.FormProcessPaperCopyRequestComponent = FormProcessPaperCopyRequestComponent;
