"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./leaflet-map.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./leaflet-map.component");
var styles_LeafletMapComponent = [i0.styles];
var RenderType_LeafletMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LeafletMapComponent, data: {} });
exports.RenderType_LeafletMapComponent = RenderType_LeafletMapComponent;
function View_LeafletMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "MapLocation"], ["style", "height: 350px"]], null, null, null, null, null))], null, null); }
exports.View_LeafletMapComponent_0 = View_LeafletMapComponent_0;
function View_LeafletMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ms-leaflet-map", [], null, null, null, View_LeafletMapComponent_0, RenderType_LeafletMapComponent)), i1.ɵdid(1, 49152, null, 0, i2.LeafletMapComponent, [], null, null)], null, null); }
exports.View_LeafletMapComponent_Host_0 = View_LeafletMapComponent_Host_0;
var LeafletMapComponentNgFactory = i1.ɵccf("ms-leaflet-map", i2.LeafletMapComponent, View_LeafletMapComponent_Host_0, { latitude: "latitude", longitude: "longitude" }, { latitudeChange: "latitudeChange", longitudeChange: "longitudeChange" }, []);
exports.LeafletMapComponentNgFactory = LeafletMapComponentNgFactory;
