import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(public snackBar: MatSnackBar, private translate: TranslateService) { }

  info(message: string)  {
    this.log(message, 'info')
  }

  warning(message: string)  {
    this.log(message, 'warning')
  }

  success(message: string)  {
    this.log(message, 'success')
  }

  error(message: string)  {
    this.log(message, 'error')
  }

  private log(message: string, type: string) {
    let extraClasses = []
    switch (type) {
      case 'success': {
        extraClasses = ['toast-dark', 'toast-success']
        break;
      }
      case 'info': {
        extraClasses =  ['toast-light', 'toast-assert']
        break;
      }
      case 'warning': {
        extraClasses = ['toast-light', 'toast-warning']
        break;
      }
      case 'error': {
        extraClasses = ['toast-dark', 'toast-error']
        break;
      }
      default: {
        break;
      }
    }

    this.translate.get(message).subscribe((translatedText: string) => {
      this.openSnackBar(translatedText, extraClasses)
    }, error => {
      this.openSnackBar(message, extraClasses)
    })
  }

  public serverError() {
    this.translate.get('SERVER ERROR').subscribe((translatedText: string) => {
      this.error(translatedText)
    })
  }

  public alreadyBookedError() {
    this.translate.get('ALREADY BOOKED').subscribe((translatedText: string) => {
      this.error(translatedText.toUpperCase())
    })
  }

  public notAccetableError() {
    this.translate.get('NOTACCETABLE ERROR').subscribe((translatedText: string) => {
      this.error(translatedText.toUpperCase())
    })
  }

  public badRequestError() {
    this.translate.get('BADREQUEST ERROR').subscribe((translatedText: string) => {
      this.error(translatedText.toUpperCase())
    })
  }

  public pdfLinkIdDuplicateError() {
    this.translate.get('PDFLINKIDDUPLICATE ERROR').subscribe((translatedText: string) => {
      this.error(translatedText.toUpperCase())
    })
  }

  public duplicateLanguageForMagazineError() {
    this.translate.get('DUPLICATE LANGUAGE FOR MAGAZINE').subscribe((translatedText: string) => {
      this.error(translatedText.toUpperCase())
    })
  }

  public openSnackBar(message: string, extraClasses: any) {
    this.snackBar.open(message, this.translate.instant('CLOSE').toUpperCase(), {
      duration: 30000,
      panelClass: extraClasses
    })
  }
}
