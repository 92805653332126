"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var platform_browser_1 = require("@angular/platform-browser");
var http_1 = require("@angular/common/http");
var BlobImageUrlPipe = /** @class */ (function () {
    function BlobImageUrlPipe(http, sanitizer) {
        this.http = http;
        this.sanitizer = sanitizer;
    }
    BlobImageUrlPipe.prototype.transform = function (url) {
        var _this = this;
        return this.http
            .get(url, { responseType: 'blob' })
            .map(function (val) { return _this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val)); });
    };
    return BlobImageUrlPipe;
}());
exports.BlobImageUrlPipe = BlobImageUrlPipe;
