import { ConfigService } from 'app/common/config.service';
import { AppApiService } from './../../../common/app-api.service';
import { DxService } from './../../../common/dx.service';
import { DxFormComponent } from 'devextreme-angular';
import { Router } from '@angular/router';
import { LoadingService } from '../../../common/loading.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CatalogueService } from '../catalogue.service';
import { UtilsService } from '../../../common/utils.service';
import { Observable, zip, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DisposeBag } from '../../../classes/dispose-bag.class';
import { tap } from 'rxjs/operators';

const englishCode = 'EN';

@Component({
	selector: 'ms-form-catalogue',
	templateUrl: './form-catalogue.component.html',
	styleUrls: ['./form-catalogue.component.scss']
})
export class FormCatalogueComponent implements OnInit, OnDestroy {
	@ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
	catalogue: any;
	cardTitle: String;
	disposeBag = new DisposeBag();
	isFormInvalid: boolean;
	id: string
	categoryEditorOptions$: Observable<any>;
	umEditorOptions$: Observable<any>;
	patternRules = '^[a-zA-Z0-9]*$';

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private utilsService: UtilsService,
		private dxService: DxService,
		private appApiService: AppApiService,
		private catalogueService: CatalogueService,
		public loadingService: LoadingService,
		public configService: ConfigService) {
		this.isFormInvalid = true;
	}

	ngOnInit() {
		this.utilsService.setPageTitleByTranslateKey('CATALOGUES');
		this.setEmptyData();
		const categories$ = this.appApiService.getCategories().map((categories: any[]) => categories.map(category => {
			for (let name of category.extended_name.split('/')) {
				category.extendedName = !category.extendedName ? this.utilsService.setViewOfMultiLanguageField(name) :
					`${category.extendedName}/${this.utilsService.setViewOfMultiLanguageField(name)}`;
			}
			return category;
		}));
		this.categoryEditorOptions$ = this.dxService.dxiItemEditorOptions(categories$, this.catalogue.category_id, 'extendedName', 'id')
		this.umEditorOptions$ = this.dxService.dxiItemEditorOptions(Observable.of(this.configService.getUmDict()), this.catalogue.um, 'text', 'id');
		this.disposeBag.add(this.loadingService.apiRequest$WithLoading(zip(this.getCatalogue(), this.appApiService.getLanguages())).subscribe(array => {
			const catalogue = array[0];
			const languages = array[1];
			this.catalogue = catalogue;
			if (this.catalogue.public == null) { this.catalogue.public = false; }
			languages.map((langauge: any) => {
				if (langauge.code === englishCode) { this.catalogue.language_id = langauge.id; }
			});
			if (!!catalogue.id) { this.dxForm.instance.updateData(catalogue); }
		}));
	}

	setEmptyData() {
		this.catalogue = {};
	}

	ngOnDestroy(): void {
		this.disposeBag.dispose();
	}

	getCatalogue(): Observable<any> {
		return this.route.params.map(params => params['id']).pipe(tap(id => this.id = String(id))).switchMap((idFound, _) => {
			if (idFound) {
				this.cardTitle = 'MODIFY CATALOGUE';
				return this.catalogueService.getOne(idFound);
			}
			this.cardTitle = 'ADD CATALOGUE';
			return of({});
		});
	}

	getLanguage() {
		this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.appApiService.getLanguages()).subscribe(languages => {
			languages.map((language: any) => {
				if (language.code === englishCode) { this.catalogue.language_id = language.id; }
			});
		}));
	}

	onFieldDataChanged() {
		this.isFormInvalid = !this.isFormValid();
	}

	onFormSubmit() {
		const observable$ = this.isNew() ? this.catalogueService.insert(this.catalogue) : this.catalogueService.update(this.catalogue, this.id);
		this.handleRequest(observable$);
	}

	public handleRequest(input: Observable<any>) {
		this.disposeBag.add(this.loadingService.apiRequest$WithLoading(input).subscribe(() => this.goToListPage()));
	}

	public isNew(): boolean {
		return this.id === 'undefined';
	}

	validate() {
		if (!this.dxForm || !this.dxForm.instance) { return { isValid: false }; }
		return this.dxForm.instance.validate();
	}

	isFormValid(): boolean {
		const result = this.validate();
		return result.isValid;
	}

	public goToListPage() {
		this.router.navigate(['catalogue']);
	}
}
