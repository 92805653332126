import { ConfigService } from 'app/common/config.service';
import { AppApiService } from './../../../common/app-api.service';
import { DxService } from './../../../common/dx.service';
import { DxFormComponent } from 'devextreme-angular';
import { Router } from '@angular/router';
import { LoadingService } from '../../../common/loading.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from '../../../common/utils.service';
import { Observable, zip, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DisposeBag } from '../../../classes/dispose-bag.class';
import { tap } from 'rxjs/operators';
import { MagazineService } from '../magazine.service';

const englishCode = 'EN';

@Component({
	selector: 'ms-form-magazine',
	templateUrl: './form-magazine.component.html',
	styleUrls: ['./form-magazine.component.scss']
})
export class FormMagazineComponent implements OnInit, OnDestroy {
	@ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
	magazine: any;
	cardTitle: String;
	disposeBag = new DisposeBag();
	isFormInvalid: boolean;
	id: string
	publishingYears = [ "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035"];

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private utilsService: UtilsService,
		private appApiService: AppApiService,
		private magazineService: MagazineService,
		public loadingService: LoadingService,
		public configService: ConfigService) {
		this.isFormInvalid = true;
	}

	ngOnInit() {
		this.utilsService.setPageTitleByTranslateKey('MAGAZINES');
		this.setEmptyData();

		this.disposeBag.add(this.loadingService.apiRequest$WithLoading(zip(this.getMagazine(), this.appApiService.getLanguages())).subscribe(array => {
			const magazine = array[0];
			const languages = array[1];
			this.magazine = magazine;
			if (this.magazine.public == null) { this.magazine.public = false; }
			languages.map((langauge: any) => {
				if (langauge.code === englishCode) { this.magazine.language_id = langauge.id; }
			});
			if (!!magazine.id) { this.dxForm.instance.updateData(magazine); }
		}));
	}

	setEmptyData() {
		this.magazine = {};
	}

	ngOnDestroy(): void {
		this.disposeBag.dispose();
	}

	getMagazine(): Observable<any> {
		return this.route.params.map(params => params['id']).pipe(tap(id => this.id = String(id))).switchMap((idFound, _) => {
			if (idFound) {
				this.cardTitle = 'MODIFY MAGAZINE';
				return this.magazineService.getOne(idFound);
			}
			this.cardTitle = 'ADD MAGAZINE';
			return of({});
		});
	}

	getLanguage() {
		this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.appApiService.getLanguages()).subscribe(languages => {
			languages.map((language: any) => {
				if (language.code === englishCode) { this.magazine.language_id = language.id; }
			});
		}));
	}

	onFieldDataChanged() {
		this.isFormInvalid = !this.isFormValid();
	}

	onFormSubmit() {
		const request = this.isNew() ? this.magazineService.insert(this.magazine) : 
																		this.magazineService.put(this.magazine, this.id);
		this.handleRequest(request);
	}

	public handleRequest(input: Observable<any>) {
		this.disposeBag.add(this.loadingService.apiRequest$WithLoading(input).subscribe(() => this.goToListPage()));
	}

	public isNew(): boolean {
		return this.id === 'undefined';
	}

	validate() {
		if (!this.dxForm || !this.dxForm.instance) { return { isValid: false }; }
		return this.dxForm.instance.validate();
	}

	isFormValid(): boolean {
		const result = this.validate();
		return result.isValid;
	}

	public goToListPage() {
		this.router.navigate(['magazine']);
	}
}
