"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var api_service_1 = require("app/common/api.service");
var crud_api_service_1 = require("app/common/crud-api.service");
var request_service_1 = require("app/common/request.service");
var i0 = require("@angular/core");
var i1 = require("../../common/request.service");
var i2 = require("../../common/api.service");
var MagazineService = /** @class */ (function (_super) {
    __extends(MagazineService, _super);
    function MagazineService(requestService, apiService) {
        var _this = _super.call(this, requestService, apiService) || this;
        _this.requestService = requestService;
        _this.apiService = apiService;
        _this.target = 'magazine';
        return _this;
    }
    MagazineService.prototype.getLanguageList = function (id) {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, String(id), 'language'));
    };
    MagazineService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MagazineService_Factory() { return new MagazineService(i0.ɵɵinject(i1.RequestService), i0.ɵɵinject(i2.ApiService)); }, token: MagazineService, providedIn: "root" });
    return MagazineService;
}(crud_api_service_1.CrudApiService));
exports.MagazineService = MagazineService;
