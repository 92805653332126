"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./location.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../common/mt-loading/mt-loading.component.ngfactory");
var i3 = require("../../common/mt-loading/mt-loading.component");
var i4 = require("@ngx-loading-bar/core");
var i5 = require("@angular/common");
var i6 = require("./grid-location/grid-location.component.ngfactory");
var i7 = require("./grid-location/grid-location.component");
var i8 = require("../../common/config.service");
var i9 = require("@angular/router");
var i10 = require("../../common/loading.service");
var i11 = require("../../common/dx.service");
var i12 = require("../../common/log.service");
var i13 = require("./location.service");
var i14 = require("../../common/utils.service");
var i15 = require("./location.component");
var styles_LocationComponent = [i0.styles];
var RenderType_LocationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationComponent, data: {} });
exports.RenderType_LocationComponent = RenderType_LocationComponent;
function View_LocationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ms-mt-loading", [], null, null, null, i2.View_MtLoadingComponent_0, i2.RenderType_MtLoadingComponent)), i1.ɵdid(1, 770048, null, 0, i3.MtLoadingComponent, [i4.LoadingBarService], { show: [0, "show"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ms-grid-location", [], null, null, null, i6.View_GridLocationComponent_0, i6.RenderType_GridLocationComponent)), i1.ɵdid(4, 245760, null, 0, i7.GridLocationComponent, [i8.ConfigService, i9.Router, i10.LoadingService, i11.DxService, i12.LogService, i13.LocationService, i14.UtilsService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.loadingService.loading$)); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, null); }
exports.View_LocationComponent_0 = View_LocationComponent_0;
function View_LocationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ms-location", [], null, null, null, View_LocationComponent_0, RenderType_LocationComponent)), i1.ɵdid(1, 114688, null, 0, i15.LocationComponent, [i14.UtilsService, i10.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LocationComponent_Host_0 = View_LocationComponent_Host_0;
var LocationComponentNgFactory = i1.ɵccf("ms-location", i15.LocationComponent, View_LocationComponent_Host_0, {}, {}, []);
exports.LocationComponentNgFactory = LocationComponentNgFactory;
