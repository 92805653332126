import { Injectable } from '@angular/core';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { ApiService } from 'app/common/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService);
    this.target = 'location';
  }

  create(location: any, file: any): Observable<any> {
    if (!file) { return this.insert(location); }
    const formData = this.setFormData(location, file);
    return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target, 'photo'), formData, true);
  }

  update(id: string, location: any, file: any): Observable<any> {
    if (!file) { return this.put(location, id); }
    const formData = this.setFormData(location, file);
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id, 'photo'), formData, true);
  }

  private setFormData(location: any, file: any): FormData {
    const formData = new FormData();
    formData.append('data', JSON.stringify(location));
    formData.append('files', file);
    return formData;
  }
}
