import { Injectable } from '@angular/core';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { ApiService } from 'app/common/api.service';
import { Observable } from 'rxjs/Observable';
import { of} from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InfoService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService);
    this.target = 'info'
  }

  get(): Observable<Info> {
   return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target));
  }

  save(info: Info, photoToUpload: any): Observable<any> {
    const formData = new FormData();
    this.setOptionalValues(info);
    formData.append('data', JSON.stringify(info));
    if (photoToUpload) {
      formData.append('files', photoToUpload);
    }
    return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
  }

  setOptionalValues(info: Info) {
    if (!info.facebook) {
      info.facebook = '';
    }
    if (!info.instagram) {
      info.instagram = '';
    }
    if (!info.linkedin) {
      info.linkedin = '';
    }
    if (!info.twitter) {
      info.twitter = '';
    }
    if (!info.youtube) {
      info.youtube = '';
    }
  }
}

export class Info {
  public name: string;
  public description: string;
  public photo: string;
  public phone: string;
  public address: string;
  public mail: string;
  public website: string;
  public facebook: string;
  public twitter: string;
  public youtube: string;
  public linkedin: string;
  public instagram: string;
}
