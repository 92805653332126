"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./paper-copy-request.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../common/mt-loading/mt-loading.component.ngfactory");
var i3 = require("../../common/mt-loading/mt-loading.component");
var i4 = require("@ngx-loading-bar/core");
var i5 = require("@angular/common");
var i6 = require("./grid-paper-copy-request/grid-paper-copy-request.component.ngfactory");
var i7 = require("./grid-paper-copy-request/grid-paper-copy-request.component");
var i8 = require("../../common/config.service");
var i9 = require("../../common/loading.service");
var i10 = require("../../common/app-api.service");
var i11 = require("../../common/utils.service");
var i12 = require("@angular/router");
var i13 = require("./paper-copy-request.component");
var styles_PaperCopyRequestComponent = [i0.styles];
var RenderType_PaperCopyRequestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaperCopyRequestComponent, data: {} });
exports.RenderType_PaperCopyRequestComponent = RenderType_PaperCopyRequestComponent;
function View_PaperCopyRequestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ms-mt-loading", [], null, null, null, i2.View_MtLoadingComponent_0, i2.RenderType_MtLoadingComponent)), i1.ɵdid(1, 770048, null, 0, i3.MtLoadingComponent, [i4.LoadingBarService], { show: [0, "show"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ms-grid-paper-copy-request", [], null, null, null, i6.View_GridPaperCopyRequestComponent_0, i6.RenderType_GridPaperCopyRequestComponent)), i1.ɵdid(4, 114688, null, 0, i7.GridPaperCopyRequestComponent, [i8.ConfigService, i9.LoadingService, i10.AppApiService, i11.UtilsService, i12.Router], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.loadingService.loading$)); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, null); }
exports.View_PaperCopyRequestComponent_0 = View_PaperCopyRequestComponent_0;
function View_PaperCopyRequestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ms-paper-copy-request", [], null, null, null, View_PaperCopyRequestComponent_0, RenderType_PaperCopyRequestComponent)), i1.ɵdid(1, 114688, null, 0, i13.PaperCopyRequestComponent, [i11.UtilsService, i9.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_PaperCopyRequestComponent_Host_0 = View_PaperCopyRequestComponent_Host_0;
var PaperCopyRequestComponentNgFactory = i1.ɵccf("ms-paper-copy-request", i13.PaperCopyRequestComponent, View_PaperCopyRequestComponent_Host_0, {}, {}, []);
exports.PaperCopyRequestComponentNgFactory = PaperCopyRequestComponentNgFactory;
