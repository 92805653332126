import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudApiService } from 'app/common/crud-api.service';
import { RequestService } from 'app/common/request.service';
import { ApiService } from 'app/common/api.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogueService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'catalogue'
  }

  getVersionList(id: number): Observable<any[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, String(id), 'version'))
  }

  insert(catalogue: any) {
    catalogue.pdf_link_id = !!catalogue.pdf_link_id ? catalogue.pdf_link_id.toLowerCase() : catalogue.pdf_link_id;
    return this.requestService.post(this.apiService.createUrlFromBaseUrl(this.target), JSON.stringify(catalogue));
  }

  update(catalogue: any, id: string) {
    catalogue.pdf_link_id = !!catalogue.pdf_link_id ? catalogue.pdf_link_id.toLowerCase() : catalogue.pdf_link_id;
    return this.requestService.put(this.apiService.createUrlFromBaseUrl(this.target, id), JSON.stringify(catalogue));
  }
}

export enum Um {
  KG_M = 'KG_M',
  LBS_FT = 'LBS_FT',
}

export enum TranslatedUm {
  KG_M = 'kg / m',
  LBS_FT = 'lbs / ft',
}
