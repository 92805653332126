"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_service_1 = require("../../service/auth-service/auth.service");
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var i2 = require("../../service/auth-service/auth.service");
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, userAuthService) {
        this.router = router;
        this.userAuthService = userAuthService;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        if (this.userAuthService.getLocalStorageUser()) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/session/loginV2'], { queryParams: { returnUrl: state.url } });
        return false;
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
exports.AuthGuard = AuthGuard;
