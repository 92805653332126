"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var L = require("leaflet");
var LeafletMapComponent = /** @class */ (function () {
    function LeafletMapComponent() {
        this.latitudeChange = new core_1.EventEmitter();
        this.longitudeChange = new core_1.EventEmitter();
        this.lat = null;
        this.lng = null;
        this.initMap();
    }
    LeafletMapComponent.prototype.initMap = function () {
        try {
            if (!this.lat || !this.lng) {
                return;
            }
            var curLocation = new L.LatLng(this.lat, this.lng);
            if (this.map == null) {
                this.map = L.map('MapLocation').setView(curLocation, 13);
                this.loadMap();
            }
            if (this.marker != null) {
                this.marker.setLatLng(curLocation);
                this.map.panTo(curLocation);
            }
            else {
                this.addMarker(curLocation);
            }
        }
        catch (e) { }
    };
    LeafletMapComponent.prototype.loadMap = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                }).addTo(this.map);
                this.map.attributionControl.setPrefix(false);
                return [2 /*return*/];
            });
        });
    };
    LeafletMapComponent.prototype.addMarker = function (inputMarker) {
        var _this = this;
        var icon = L.icon({
            iconUrl: '/assets/img/leaflet/marker-icon.png',
            shadowUrl: '/assets/img/leaflet/marker-shadow.png',
            iconSize: [24, 41],
            iconAnchor: [12, 41]
        });
        this.marker = L.marker(inputMarker, { draggable: true, icon: icon });
        this.marker.on('dragend', function () {
            var position = _this.marker.getLatLng();
            _this.setLatitude(position.lat);
            _this.setLongitude(position.lng);
        });
        this.map.addLayer(this.marker);
    };
    Object.defineProperty(LeafletMapComponent.prototype, "latitude", {
        get: function () {
            return this.lat;
        },
        set: function (val) {
            this.setLatitude(val);
            this.initMap();
        },
        enumerable: true,
        configurable: true
    });
    LeafletMapComponent.prototype.setLatitude = function (val) {
        this.lat = val;
        this.latitudeChange.emit(this.lat);
    };
    Object.defineProperty(LeafletMapComponent.prototype, "longitude", {
        get: function () {
            return this.lng;
        },
        set: function (val) {
            this.setLongitude(val);
            this.initMap();
        },
        enumerable: true,
        configurable: true
    });
    LeafletMapComponent.prototype.setLongitude = function (val) {
        this.lng = val;
        this.longitudeChange.emit(this.lng);
    };
    return LeafletMapComponent;
}());
exports.LeafletMapComponent = LeafletMapComponent;
