"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config_service_1 = require("app/common/config.service");
var app_api_service_1 = require("./../../../common/app-api.service");
var devextreme_angular_1 = require("devextreme-angular");
var router_1 = require("@angular/router");
var loading_service_1 = require("../../../common/loading.service");
var core_1 = require("@angular/core");
var utils_service_1 = require("../../../common/utils.service");
var rxjs_1 = require("rxjs");
var router_2 = require("@angular/router");
var dispose_bag_class_1 = require("../../../classes/dispose-bag.class");
var operators_1 = require("rxjs/operators");
var magazine_service_1 = require("../magazine.service");
var englishCode = 'EN';
var FormMagazineComponent = /** @class */ (function () {
    function FormMagazineComponent(router, route, utilsService, appApiService, magazineService, loadingService, configService) {
        this.router = router;
        this.route = route;
        this.utilsService = utilsService;
        this.appApiService = appApiService;
        this.magazineService = magazineService;
        this.loadingService = loadingService;
        this.configService = configService;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
        this.publishingYears = ["2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035"];
        this.isFormInvalid = true;
    }
    FormMagazineComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.utilsService.setPageTitleByTranslateKey('MAGAZINES');
        this.setEmptyData();
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(rxjs_1.zip(this.getMagazine(), this.appApiService.getLanguages())).subscribe(function (array) {
            var magazine = array[0];
            var languages = array[1];
            _this.magazine = magazine;
            if (_this.magazine.public == null) {
                _this.magazine.public = false;
            }
            languages.map(function (langauge) {
                if (langauge.code === englishCode) {
                    _this.magazine.language_id = langauge.id;
                }
            });
            if (!!magazine.id) {
                _this.dxForm.instance.updateData(magazine);
            }
        }));
    };
    FormMagazineComponent.prototype.setEmptyData = function () {
        this.magazine = {};
    };
    FormMagazineComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    FormMagazineComponent.prototype.getMagazine = function () {
        var _this = this;
        return this.route.params.map(function (params) { return params['id']; }).pipe(operators_1.tap(function (id) { return _this.id = String(id); })).switchMap(function (idFound, _) {
            if (idFound) {
                _this.cardTitle = 'MODIFY MAGAZINE';
                return _this.magazineService.getOne(idFound);
            }
            _this.cardTitle = 'ADD MAGAZINE';
            return rxjs_1.of({});
        });
    };
    FormMagazineComponent.prototype.getLanguage = function () {
        var _this = this;
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.appApiService.getLanguages()).subscribe(function (languages) {
            languages.map(function (language) {
                if (language.code === englishCode) {
                    _this.magazine.language_id = language.id;
                }
            });
        }));
    };
    FormMagazineComponent.prototype.onFieldDataChanged = function () {
        this.isFormInvalid = !this.isFormValid();
    };
    FormMagazineComponent.prototype.onFormSubmit = function () {
        var request = this.isNew() ? this.magazineService.insert(this.magazine) :
            this.magazineService.put(this.magazine, this.id);
        this.handleRequest(request);
    };
    FormMagazineComponent.prototype.handleRequest = function (input) {
        var _this = this;
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(input).subscribe(function () { return _this.goToListPage(); }));
    };
    FormMagazineComponent.prototype.isNew = function () {
        return this.id === 'undefined';
    };
    FormMagazineComponent.prototype.validate = function () {
        if (!this.dxForm || !this.dxForm.instance) {
            return { isValid: false };
        }
        return this.dxForm.instance.validate();
    };
    FormMagazineComponent.prototype.isFormValid = function () {
        var result = this.validate();
        return result.isValid;
    };
    FormMagazineComponent.prototype.goToListPage = function () {
        this.router.navigate(['magazine']);
    };
    return FormMagazineComponent;
}());
exports.FormMagazineComponent = FormMagazineComponent;
