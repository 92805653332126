"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./html-editor-multilanguage.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("../../../../node_modules/ngx-quill/ngx-quill.ngfactory");
var i4 = require("ngx-quill");
var i5 = require("@angular/platform-browser");
var i6 = require("@angular/common");
var i7 = require("@angular/forms");
var i8 = require("./html-editor-multilanguage.component");
var styles_HtmlEditorMultilanguageComponent = [i0.styles];
var RenderType_HtmlEditorMultilanguageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HtmlEditorMultilanguageComponent, data: {} });
exports.RenderType_HtmlEditorMultilanguageComponent = RenderType_HtmlEditorMultilanguageComponent;
function View_HtmlEditorMultilanguageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "field-required-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("REQUIRED")); _ck(_v, 1, 0, currVal_0); }); }
function View_HtmlEditorMultilanguageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "editor-margin"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "quill-editor", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onContentChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.formData[_v.context.$implicit.key] = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onContentChanged" === en)) {
        var pd_1 = (_co.onContentChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_QuillEditorComponent_0, i3.RenderType_QuillEditorComponent)), i1.ɵdid(2, 4898816, null, 0, i4.QuillEditorComponent, [i1.ElementRef, i5.DomSanitizer, i6.DOCUMENT, i1.PLATFORM_ID, i1.Renderer2, i1.NgZone, i4.QUILL_CONFIG_TOKEN], { modules: [0, "modules"], placeholder: [1, "placeholder"] }, { onContentChanged: "onContentChanged" }), i1.ɵprd(1024, null, i7.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.QuillEditorComponent]), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.QuillEditorComponent]), i1.ɵdid(5, 671744, null, 0, i7.NgModel, [[8, null], [6, i7.NG_VALIDATORS], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(7, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlEditorMultilanguageComponent_2)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.modules; var currVal_8 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_7, currVal_8); var currVal_9 = _co.formData[_v.context.$implicit.key]; _ck(_v, 5, 0, currVal_9); var currVal_10 = (!_co.formData[_v.context.$implicit.key] && _co.isRequired); _ck(_v, 9, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_HtmlEditorMultilanguageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HtmlEditorMultilanguageComponent_1)), i1.ɵdid(1, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.languages; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_HtmlEditorMultilanguageComponent_0 = View_HtmlEditorMultilanguageComponent_0;
function View_HtmlEditorMultilanguageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ms-html-editor-multilanguage", [], null, null, null, View_HtmlEditorMultilanguageComponent_0, RenderType_HtmlEditorMultilanguageComponent)), i1.ɵdid(1, 638976, null, 0, i8.HtmlEditorMultilanguageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_HtmlEditorMultilanguageComponent_Host_0 = View_HtmlEditorMultilanguageComponent_Host_0;
var HtmlEditorMultilanguageComponentNgFactory = i1.ɵccf("ms-html-editor-multilanguage", i8.HtmlEditorMultilanguageComponent, View_HtmlEditorMultilanguageComponent_Host_0, { languages: "languages", isRequired: "isRequired", height: "height", model: "model", isValid: "isValid" }, { modelChange: "modelChange", isValidChange: "isValidChange" }, []);
exports.HtmlEditorMultilanguageComponentNgFactory = HtmlEditorMultilanguageComponentNgFactory;
