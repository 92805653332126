import { FormProcessPaperCopyRequestComponent } from './components/paper-copy-request/form-process-paper-copy-request/form-process-paper-copy-request.component';
import { PaperCopyRequestComponent } from './components/paper-copy-request/paper-copy-request.component';
import { FormNewsComponent } from './components/news/form-news/form-news.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { UserComponent } from './components/user/user.component';
import {FormUserComponent} from './components/user/form-user/form-user.component';
import { LoginComponent } from './session/login/login.component';
import { AuthGuardService } from './authentication/auth-guard.service';
import { CatalogueComponent } from './components/catalogue/catalogue.component';
import { FormCatalogueComponent } from './components/catalogue/form-catalogue/form-catalogue.component';
import {VersionComponent} from './components/version/version.component';
import {FormVersionComponent} from './components/version/form-version/form-version.component';
import { InfoComponent } from './components/info/info.component';
import { FormInfoComponent } from './components/info/form-info/form-info.component';
import { TestMapComponent } from './components/test-map/test-map.component';
import { NewsComponent } from './components/news/news.component';
import { LocationComponent } from './components/location/location.component';
import { FormLocationComponent } from './components/location/form-location/form-location.component';
import { MagazineComponent } from './components/magazine/magazine.component';
import { FormMagazineComponent } from './components/magazine/form-magazine/form-magazine.component';
import { MagazineLocalizationComponent } from './components/magazine/localizations/magazine-localization.component';
import { FormMagazineLocalizationComponent } from './components/magazine/localizations/form-magazine-localization/form-magazine-localization.component';

const appRoutes: Routes = [
   {
      path: 'session/login',
      component: LoginComponent,
   },
   {
      path: '',
      component: MainComponent,
      canActivate: [AuthGuardService],
      children: [
         { path: 'users', component: UserComponent },
         { path: 'users/add', component: FormUserComponent },
         { path: 'users/:id', component: FormUserComponent },
         { path: 'catalogue', component: CatalogueComponent },
         { path: 'catalogue/add', component: FormCatalogueComponent },
         { path: 'catalogue/:id', component: FormCatalogueComponent },
         { path: 'catalogue/:id/version', component: VersionComponent },
         { path: 'catalogue/:id/version/add', component: FormVersionComponent },
         { path: 'catalogue/:id/version/:version_id', component: FormVersionComponent },
         { path: 'magazine', component: MagazineComponent },
         { path: 'magazine/add', component: FormMagazineComponent },
         { path: 'magazine/:id', component: FormMagazineComponent },
         { path: 'magazine/:id/language', component: MagazineLocalizationComponent },
         { path: 'magazine/:id/language/add', component: FormMagazineLocalizationComponent},
         { path: 'magazine/:id/language/:language_id', component: FormMagazineLocalizationComponent },
         { path: 'info', component: InfoComponent },
         { path: 'info/edit', component: FormInfoComponent },
         { path: 'map', component: TestMapComponent },
         { path: 'news', component: NewsComponent },
         { path: 'news/add', component: FormNewsComponent },
         { path: 'news/:id', component: FormNewsComponent },
         { path: 'location', component: LocationComponent },
         { path: 'location/add', component: FormLocationComponent },
         { path: 'location/:id', component: FormLocationComponent },
         { path: 'paper_copy_request', component: PaperCopyRequestComponent },
         { path: 'paper_copy_request/:id/process', component: FormProcessPaperCopyRequestComponent }
      ]
   }
]

@NgModule({
  	imports: [RouterModule.forRoot(appRoutes)],
 	exports: [RouterModule],
  	providers: []
})
export class RoutingModule { }
