import {Subscription} from 'rxjs';

export class DisposeBag {
  subscriptions: Subscription[];

  constructor() {
    this.subscriptions = [];
  }

  add(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  dispose() {
    for (const subscription of this.subscriptions) { subscription.unsubscribe(); }
  }
}
