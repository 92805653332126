"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".margin-top[_ngcontent-%COMP%] {\n  margin-top: 20px;\n}\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbGliL2plbmtpbnMvd29ya3NwYWNlL2Zhc3NpLWNtcy1kZXYvc3JjL2FwcC9jb21wb25lbnRzL2xvY2F0aW9uL2Zvcm0tbG9jYXRpb24vZm9ybS1sb2NhdGlvbi5jb21wb25lbnQuc2NzcyIsInNyYy9hcHAvY29tcG9uZW50cy9sb2NhdGlvbi9mb3JtLWxvY2F0aW9uL2Zvcm0tbG9jYXRpb24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxnQkFBQTtBQ0NGIiwiZmlsZSI6InNyYy9hcHAvY29tcG9uZW50cy9sb2NhdGlvbi9mb3JtLWxvY2F0aW9uL2Zvcm0tbG9jYXRpb24uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIubWFyZ2luLXRvcCB7XG4gIG1hcmdpbi10b3A6IDIwcHg7XG59IiwiLm1hcmdpbi10b3Age1xuICBtYXJnaW4tdG9wOiAyMHB4O1xufSJdfQ== */"];
exports.styles = styles;
