import { Component, OnInit, OnChanges, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ms-html-editor-multilanguage',
  templateUrl: './html-editor-multilanguage.component.html',
  styleUrls: ['./html-editor-multilanguage.component.scss']
})
export class HtmlEditorMultilanguageComponent implements OnInit, OnChanges {
  @Output() modelChange = new EventEmitter<string>();
  @Output() isValidChange = new EventEmitter();
  @Input() languages: any[];
  @Input() isRequired: boolean;
  @Input() height: number;
  _model: any;
  _isValid: boolean;
  formData: any;
  modules: any;

  constructor() {
    this.formData = {};
    this._isValid = false;
  }

  ngOnInit() {
    this.modules = {
      toolbar: [
        'bold',
        'italic',
        'underline',
        { 'header': [1, 2, 3, 4, 5, 6, false] },
        { 'align': [] },
        { 'list': 'ordered' },
        { 'list': 'bullet' },
        { 'script': 'sub' },
        { 'script': 'super' },
        { 'indent': '-1' },
        { 'indent': '+1' },
        'clean'
      ]
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes || !changes.model) { return; }
    const model = changes.model;
    if (model.currentValue !== '{}') {
      this.formData = JSON.parse(model.currentValue);
    }
    this.setIsValid(this.isFormValid());
  }

  onContentChanged(e) {
    this.setModel(this.formData);
  }

  @Input()
  get model() {
    return this._model;
  }

  set model(val) {
    this.setModel(val);
  }

  setModel(val) {
    this._model = val;
    this.modelChange.emit(JSON.stringify(this.formData));
    this.setIsValid(this.isFormValid());
  }

  isFormValid() {
    if (!this.isRequired) { return true; }
    return this.languages.every(language => !!this.formData[language.key]);
  }

  @Input()
  get isValid() {
    return this._isValid;
  }

  set isValid(val) {
    this.setIsValid(val);
  }

  setIsValid(val) {
    this._isValid = val;
    this.isValidChange.emit(this._isValid);
  }
}
