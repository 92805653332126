"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var crud_api_service_1 = require("app/common/crud-api.service");
var request_service_1 = require("app/common/request.service");
var api_service_1 = require("app/common/api.service");
var i0 = require("@angular/core");
var i1 = require("../../common/request.service");
var i2 = require("../../common/api.service");
var NewsService = /** @class */ (function (_super) {
    __extends(NewsService, _super);
    function NewsService(requestService, apiService) {
        var _this = _super.call(this, requestService, apiService) || this;
        _this.requestService = requestService;
        _this.apiService = apiService;
        _this.target = 'news';
        return _this;
    }
    NewsService.prototype.push = function (id) {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, id, 'push'));
    };
    NewsService.prototype.create = function (news, file) {
        news = this.updateNewsForInsert(news);
        if (!file) {
            return this.insert(news);
        }
        var formData = this.setFormData(news, file);
        return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target, 'photo'), formData, true);
    };
    NewsService.prototype.update = function (id, news, file) {
        news = this.updateNewsForUpdate(news);
        if (!file) {
            return this.put(news, id);
        }
        var formData = this.setFormData(news, file);
        return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target, id, 'photo'), formData, true);
    };
    NewsService.prototype.updateNewsForInsert = function (news) {
        if (news.description == null) {
            news.description = '';
        }
        if (news.link == null) {
            news.link = '';
        }
        delete news['date'];
        return news;
    };
    NewsService.prototype.updateNewsForUpdate = function (news) {
        delete news['date'];
        return news;
    };
    NewsService.prototype.setFormData = function (news, file) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(news));
        formData.append('files', file);
        return formData;
    };
    NewsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NewsService_Factory() { return new NewsService(i0.ɵɵinject(i1.RequestService), i0.ɵɵinject(i2.ApiService)); }, token: NewsService, providedIn: "root" });
    return NewsService;
}(crud_api_service_1.CrudApiService));
exports.NewsService = NewsService;
