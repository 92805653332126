
import { UtilsService } from './../../../common/utils.service';
import { AppApiService } from './../../../common/app-api.service';
import { GridBaseComponent } from './../../../classes/grid-base.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DisposeBag } from '../../../classes/dispose-bag.class';
import { DxService } from '../../../common/dx.service';
import { LoadingService } from '../../../common/loading.service';
import { Observable, zip } from 'rxjs';
import { LogService } from 'app/common/log.service';
import { ConfigService } from 'app/common/config.service';
import { VersionService } from 'app/components/version/version.service';
import { MagazineService } from '../magazine.service';
import { TranslatedUm } from 'app/components/catalogue/catalogue.service';

@Component({
  selector: 'ms-grid-magazine',
  templateUrl: './grid-magazine.component.html',
  styleUrls: ['./grid-magazine.component.scss']
})
export class GridMagazineComponent extends GridBaseComponent implements OnInit, OnDestroy {
  magazines$: Observable<any[]>;
  disposeBag = new DisposeBag();

  constructor(
    public configService: ConfigService,
    private router: Router,
    private magazineService: MagazineService,
    private dxService: DxService,
    private loadingService: LoadingService,
    private logger: LogService,
    private versionService: VersionService,
    private appApiService: AppApiService,
    private utilsService: UtilsService
  ) {
    super(configService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.editButtonClick = this.editButtonClick.bind(this);
    this.removeButtonClick = this.removeButtonClick.bind(this);
    this.languagesButtonClick = this.languagesButtonClick.bind(this);
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  editButtonClick(e: any) {
    this.router.navigate(['magazine', e.row.data.id]);
  }

  languagesButtonClick(e: any) {
    this.router.navigate(['magazine', e.row.data.id, 'language']);
  }

  removeButtonClick(e: any) {
    this.disposeBag.add(this.deleteRowWithConfirmation(e.row.data.id).subscribe(
      () => this.loadData(),
      () => this.logger.serverError()
    ));
  }

  downloadFile(url: string, name: string) {
    this.versionService.dowloadFile(url, `${name}.pdf`);
  }

  private deleteRowWithConfirmation(dataId: string) {
    return this.loadingService.apiRequest$WithLoading(
      this.dxService.dxConfirmWithSuccessfulObservable('WARNING', 'ARE YOU SURE', this.magazineService.delete(dataId)));
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => this.loadData()
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => this.router.navigate(['magazine/add'])
      }
    });
  }

  private loadData() {
    this.magazines$ = this.loadingService.apiRequest$WithLoading(this.magazineService.getList());
  }
}
