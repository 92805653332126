"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var catalogue_service_1 = require("./../components/catalogue/catalogue.service");
var utils_service_1 = require("./utils.service");
var user_service_1 = require("app/components/user/user.service");
var i0 = require("@angular/core");
var i1 = require("./utils.service");
var ConfigService = /** @class */ (function () {
    function ConfigService(utils) {
        this.utils = utils;
    }
    ConfigService.prototype.getRolesDict = function () {
        return [
            { id: user_service_1.RolesTypes.ADMIN, text: 'ADMIN' },
            { id: user_service_1.RolesTypes.USER, text: 'USER' }
        ];
    };
    ConfigService.prototype.getUmDict = function () {
        return [
            { id: catalogue_service_1.Um.KG_M, text: catalogue_service_1.TranslatedUm.KG_M },
            { id: catalogue_service_1.Um.LBS_FT, text: catalogue_service_1.TranslatedUm.LBS_FT }
        ];
    };
    ConfigService.prototype.getRolesTypes = function () {
        return this.getRolesDict().map(function (role) { return role.text; });
    };
    ConfigService.prototype.translatedRoleDict = function () {
        return this.utils.translateDict(this.getRolesTypes());
    };
    ConfigService.prototype.getShortTextWidth = function () {
        return '270';
    };
    ConfigService.prototype.getTimestampWidth = function () {
        return '150';
    };
    ConfigService.prototype.getNumericWidth = function () {
        return '100';
    };
    ConfigService.prototype.getElapsedTimeWidth = function () {
        return '150';
    };
    ConfigService.prototype.getUserFullNameWidth = function () {
        return '200';
    };
    ConfigService.prototype.getButtonColumnNameWidth = function () {
        return '110';
    };
    ConfigService.prototype.getThumbnailCellWidth = function () {
        return '130';
    };
    ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.ɵɵinject(i1.UtilsService)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
exports.ConfigService = ConfigService;
