"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var utils_service_1 = require("./utils.service");
var dialog_1 = require("devextreme/ui/dialog");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var i1 = require("./utils.service");
var DxService = /** @class */ (function () {
    function DxService(utils) {
        this.utils = utils;
    }
    DxService.prototype.dxiItemEditorOptions = function (dataObservable, value, displayField, valueField) {
        return dataObservable.map(function (dataArray) {
            return {
                dataSource: dataArray,
                displayExpr: function (data) { return !!data ? data[displayField] : null; },
                valueExpr: function (data) { return !!data ? data[valueField] : null; }
            };
        });
    };
    DxService.prototype.dxConfirm = function (title, messageHtml) {
        return this.utils.translateArray([title, messageHtml]).pipe(operators_1.switchMap(function (_a) {
            var tsTitle = _a[0], tsMessageHtml = _a[1];
            return rxjs_1.from(dialog_1.confirm(tsMessageHtml, tsTitle));
        }));
    };
    DxService.prototype.dxConfirmWithSuccessfulObservable = function (title, messageHtml, successfulObservable) {
        return this.dxConfirm(title, messageHtml).pipe(operators_1.switchMap(function (res) { return res ? successfulObservable : rxjs_1.EMPTY; }));
    };
    DxService.prototype.dxToolbarItemOptions = function (text, onClick) {
        return this.utils.translate(text).map(function (translatedText) { return { text: translatedText, onClick: onClick }; });
    };
    DxService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DxService_Factory() { return new DxService(i0.ɵɵinject(i1.UtilsService)); }, token: DxService, providedIn: "root" });
    return DxService;
}());
exports.DxService = DxService;
