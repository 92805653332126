"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var core_1 = require("@ngx-translate/core");
var LockScreenComponent = /** @class */ (function () {
    function LockScreenComponent(router, translate) {
        this.router = router;
        this.translate = translate;
    }
    // when submit button click, router navigates to a home page.
    LockScreenComponent.prototype.onSubmit = function () {
        this.router.navigate(['/']);
    };
    return LockScreenComponent;
}());
exports.LockScreenComponent = LockScreenComponent;
