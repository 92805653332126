"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var app_api_service_1 = require("./../../../common/app-api.service");
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var page_title_service_1 = require("../../../core/page-title/page-title.service");
var utils_service_1 = require("../../../common/utils.service");
var router_1 = require("@angular/router");
var news_service_1 = require("../news.service");
var loading_service_1 = require("app/common/loading.service");
var rxjs_1 = require("rxjs");
var dispose_bag_class_1 = require("app/classes/dispose-bag.class");
var devextreme_angular_1 = require("devextreme-angular");
var log_service_1 = require("app/common/log.service");
var FormNewsComponent = /** @class */ (function () {
    function FormNewsComponent(fb, pageTitleService, newsService, utils, loadingService, route, router, logger, appApiService) {
        this.fb = fb;
        this.pageTitleService = pageTitleService;
        this.newsService = newsService;
        this.utils = utils;
        this.loadingService = loadingService;
        this.route = route;
        this.router = router;
        this.logger = logger;
        this.appApiService = appApiService;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
        this.isTitleFormValid = false;
        this.isDescriptionFormValid = false;
    }
    FormNewsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.disposeBag.add(rxjs_1.zip(this.utils.translate('UPLOAD NEWS'), this.utils.translate('NEWS')).subscribe(function (array) {
            _this.cardTitle = array[0];
            _this.pageTitleService.setTitle(array[1]);
        }));
        this.form = this.fb.group({
            link: [null]
        });
        this.disposeBag.add(rxjs_1.zip(this.route.params, this.route.queryParams).subscribe(function (array) {
            _this.id = String(array[0].id);
            _this.queryParams = array[1];
            if (!_this.isNew()) {
                if (!_this.isCloned()) {
                    _this.utils.translate('EDIT NEWS').subscribe(function (res) { return _this.cardTitle = res; });
                }
                _this.loadNewsData();
            }
            else {
                _this.setEmptyData();
                _this.disposeBag.add(_this.loadingService.apiRequest$WithLoading(_this.appApiService.getLanguages()).subscribe(function (res) {
                    _this.languages = res.map(function (language) {
                        language.key = language.code.toLowerCase();
                        return language;
                    });
                }, function () { return _this.logger.serverError(); }));
            }
        }));
    };
    FormNewsComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    FormNewsComponent.prototype.setEmptyData = function () {
        this.news = {
            title: '',
            description: ''
        };
    };
    FormNewsComponent.prototype.loadNewsData = function () {
        var _this = this;
        this.disposeBag.add(rxjs_1.zip(this.loadingService.apiRequest$WithLoading(this.newsService.getOne(this.id)), this.appApiService.getLanguages()).subscribe(function (array) {
            var news = array[0];
            _this.news = news;
            _this.objToForm(_this.news);
            if (_this.isCloned()) {
                _this.id = 'undefined';
                delete _this.news.photo;
            }
            _this.languages = array[1];
            _this.languages = _this.languages.map(function (language) {
                language.key = language.code.toLowerCase();
                return language;
            });
        }, function () { return _this.logger.serverError(); }));
    };
    FormNewsComponent.prototype.objToForm = function (news) {
        this.form.controls.link.setValue(news.link);
    };
    FormNewsComponent.prototype.formToObj = function () {
        var obj = {};
        obj['title'] = this.news.title;
        obj['description'] = this.news.description;
        obj['link'] = this.form.controls.link.value;
        return obj;
    };
    FormNewsComponent.prototype.isFormValid = function () {
        return this.form.valid && this.isTitleFormValid && this.isDescriptionFormValid;
    };
    FormNewsComponent.prototype.fileChange = function (event) {
        var files = event;
        this.photoToUpload = null;
        if (files.length > 0) {
            this.photoToUpload = files[0];
        }
    };
    FormNewsComponent.prototype.onSubmit = function () {
        if (!this.isFormValid()) {
            return;
        }
        var observable$ = this.isNew() ? this.saveNews() : this.updateNews();
        var progress = !!this.photoToUpload;
        this.handleRequest(observable$, progress);
    };
    FormNewsComponent.prototype.handleRequest = function (observable$, progress) {
        var _this = this;
        if (!progress) {
            this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(function () { return _this.goToListPage(); }, function () { return _this.logger.serverError(); }));
            return;
        }
        this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(function () { return _this.goToListPage(); }, function () { return _this.logger.serverError(); }));
    };
    FormNewsComponent.prototype.updateNews = function () {
        var input = this.formToObj();
        return this.newsService.update(this.id, input, this.photoToUpload);
    };
    FormNewsComponent.prototype.saveNews = function () {
        var input = this.formToObj();
        return this.newsService.create(input, this.photoToUpload);
    };
    FormNewsComponent.prototype.goToListPage = function () {
        this.router.navigate(['news']);
    };
    FormNewsComponent.prototype.isNew = function () {
        return this.id === 'undefined';
    };
    FormNewsComponent.prototype.isCloned = function () {
        if (!this.queryParams || !this.queryParams.clone) {
            return false;
        }
        return this.queryParams.clone;
    };
    return FormNewsComponent;
}());
exports.FormNewsComponent = FormNewsComponent;
