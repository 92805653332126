import { Um, TranslatedUm } from './../components/catalogue/catalogue.service';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {UtilsService} from './utils.service';
import { RolesTypes } from 'app/components/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private utils: UtilsService) { }

  getRolesDict(): any[] {
    return [
      { id: RolesTypes.ADMIN, text: 'ADMIN' },
      { id: RolesTypes.USER, text: 'USER' }
    ];
  }

  getUmDict(): any[] {
    return [
      { id: Um.KG_M, text: TranslatedUm.KG_M },
      { id: Um.LBS_FT, text: TranslatedUm.LBS_FT}
    ];
  }

  getRolesTypes(): string[] {
    return this.getRolesDict().map(role => { return role.text })
  }

  translatedRoleDict(): Observable<any> {
    return this.utils.translateDict(this.getRolesTypes())
  }

  getShortTextWidth(): string {
    return '270';
  }

  getTimestampWidth(): string {
    return '150';
  }

  getNumericWidth(): string {
    return '100';
  }

  getElapsedTimeWidth(): string {
    return '150';
  }

  getUserFullNameWidth(): string {
    return '200';
  }

  getButtonColumnNameWidth(): string {
    return '110';
  }

  getThumbnailCellWidth(): string {
    return '130';
  }
}
