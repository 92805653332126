"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_service_1 = require("../../service/auth-service/auth.service");
var core_1 = require("@ngx-translate/core");
var RegisterV2Component = /** @class */ (function () {
    function RegisterV2Component(authService, translate) {
        this.authService = authService;
        this.translate = translate;
        this.slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, "autoplay": true, "autoplaySpeed": 1000, "dots": false, "arrows": false };
        this.sessionSlider = [
            {
                image: "assets/img/login-slider1.jpg",
                name: "Francisco Abbott",
                designation: "CEO-Gene",
                content: "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy."
            },
            {
                image: "assets/img/login-slider2.jpg",
                name: "Samona Brown",
                designation: "Designer",
                content: "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy."
            },
            {
                image: "assets/img/login-slider3.jpg",
                name: "Anna Smith",
                designation: "Managing Director",
                content: "Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy."
            }
        ];
    }
    //register method is used to sign up on the template.
    RegisterV2Component.prototype.register = function (value) {
        this.authService.signupUserProfile(value);
    };
    return RegisterV2Component;
}());
exports.RegisterV2Component = RegisterV2Component;
