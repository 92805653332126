<dx-data-grid id="gridContainer"
              [dataSource]="users | async"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [allowColumnResizing]="true"
              [showBorders]="true"
              (onToolbarPreparing)="onToolbarPreparing($event)">
    <dxo-export [enabled]="true" fileName="Users"></dxo-export>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]">
    </dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxi-column type="buttons" [width]="buttonColumnNameWidth">
        <dxi-button icon="edit" [onClick]="linkButtonClick" hint="{{'MODIFY THE USER' | translate}}"></dxi-button>
    </dxi-column>
    <dxi-column dataField="name" caption="{{'NAME' | translate}}" [width]="shortTextWidth"></dxi-column>
    <dxi-column dataField="surname" caption="{{'SURNAME' | translate}}" [width]="shortTextWidth"></dxi-column>
    <dxi-column dataField="company" caption="{{'COMPANY' | translate}}"></dxi-column>
    <dxi-column dataField="role" caption="{{'ROLE' | translate}}"></dxi-column>
    <dxi-column dataField="enabled" caption="{{'ENABLED' | translate}}"></dxi-column>
</dx-data-grid>