import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'app/common/utils.service';
import { LoadingService } from 'app/common/loading.service';

@Component({
  selector: 'ms-paper-copy-request',
  templateUrl: './paper-copy-request.component.html',
  styleUrls: ['./paper-copy-request.component.scss']
})
export class PaperCopyRequestComponent implements OnInit {

  constructor(private utilsService: UtilsService, public loadingService: LoadingService) { }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('PAPER COPY REQUEST');
  }
}
