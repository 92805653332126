import {Component, OnInit, Input, Inject, OnDestroy} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../user.service';
import { LogService } from 'app/common/log.service';
import {DisposeBag} from '../../../classes/dispose-bag.class';
import {LoadingService} from '../../../common/loading.service';

const regEx = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'

@Component({
  selector: 'ms-form-password',
  templateUrl: './form-password.component.html',
  styleUrls: ['./form-password.component.scss']
})
export class FormPasswordComponent implements OnInit, OnDestroy {
    loading = false;
    disposeBag = new DisposeBag();
    public form: FormGroup
    public cardTitle: string
  
    constructor(public dialogRef: MatDialogRef<FormPasswordComponent>, private translateService: TranslateService,
                private fb: FormBuilder, private userService: UserService, private logger: LogService,  @Inject(MAT_DIALOG_DATA) public data: string,
                private loadingService: LoadingService) {
    }
  
    ngOnInit() {
      this.translate('INSERT PASSWORD').subscribe((res: string) => {
        this.cardTitle = res
      })
      this.disposeBag.add(this.loadingService.loading$.subscribe(res => { this.loading = res; }));

      this.form = this.fb.group({
          password: [null, Validators.compose([Validators.required, Validators.pattern(regEx)])],
          confirmPassword: [null, Validators.compose([Validators.required, Validators.pattern(regEx)])]
        },
        {
          validator: this.checkPassword
        })
    }

    ngOnDestroy(): void {
      this.disposeBag.dispose();
    }
  
    public formToObj() {
      let obj = {}
      obj['password'] = this.form.controls.password.value
      return obj
    }
  
    public onSubmit() {
      if (!this.form.valid) { return; }
      const userId = this.data
      const input = this.formToObj()
      this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.userService.updatePassword(input, userId)).subscribe(
        res => {
          this.onClose()
          this.translate('UPDATED PASSWORD').subscribe((msg: string) => {
            this.logger.success(msg);
          })
        }
      ));
    }
  
    public onClose() {
      this.dialogRef.close(undefined)
    }
  
    translate(key: string) {
      return this.translateService.get(key)
    }
    
  //TODO da centralizzare
  checkPassword(form: FormGroup) {
    return form.controls.password.value === form.controls.confirmPassword.value ? null : {'mismatch': true};
  }

  validatePassword() {
    return (this.form.controls.password.hasError('required') || this.form.controls.password.hasError('pattern'))
      && this.form.controls.password.touched
  }
  
  matchPassword() {
    return !this.form.controls.password.hasError('pattern') && this.form.controls.password.value !== this.form.controls.confirmPassword.value
      && this.form.controls.confirmPassword.touched
  }
}
  