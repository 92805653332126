import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {delay, finalize, tap} from 'rxjs/operators';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading$: Observable<boolean>;
  private loading: Subject<boolean>;
  progress$: Observable<number>;
  private progress: Subject<number>;

  constructor() {
    this.loading = new Subject<boolean>();
    this.loading$ = this.loading.asObservable().pipe(delay(0))
    this.progress = new Subject<number>();
    this.progress$ = this.progress.asObservable().pipe(delay(0))
  }

  public updateLoading(loading: boolean): void {
    this.loading.next(loading);
  }

  public apiRequest$WithLoading(apiRequest$: Observable<any>): Observable<any> {
    this.updateLoading(true);
    return apiRequest$.pipe(finalize(() => { this.updateLoading(false); }))
  }

  public apiRequest$WithLoadingAndProgress(apiRequest$: Observable<any>): Observable<any> {
    this.updateLoading(true);
    return apiRequest$
      .pipe(
        tap(ev => this.handleProgress(ev)),
        finalize(() => { this.updateLoading(false); }))
      .filter(event => event instanceof HttpResponse)
  }

  handleProgress(event) {
    if (event.loaded < event.total) {
      const percentDone = Math.round(100 * event.loaded / event.total);
      this.updateProgress(percentDone)
    } else {
      this.updateProgress(0)
    }
  }

  public updateProgress(progress: number): void {
    this.progress.next(progress);
  }
}
