<div id = "main-app" class="app sidebar-color-dark header-color-dark" [dir]="layout" [ngClass]="{'app-dark': dark, 'collapsed-sidebar': coreService.collapseSidebar, 'compact-sidebar': compactSidebar, 'customizer-in': customizerIn}" [class.side-panel-opened]="coreService.sidenavOpen" [class.side-panel-closed]="!coreService.sidenavOpen">
<mat-sidenav-container class="gene-container">
   <mat-sidenav #sidenav [(mode)]="coreService.sidenavMode" [(opened)]="coreService.sidenavOpen" class="sidebar-area">
      <ms-side-bar [menuList]="menuItems" [username]="username" [verticalMenuStatus] = true></ms-side-bar>
   </mat-sidenav>
   <mat-toolbar class="gene-header-toolbar">
      <div class="gene-ham-icon" tourAnchor="start.tour" >
         <button class="" mat-mini-fab color="primary" mat-card-icon (click)="toggleSidebar()">
            <mat-icon>menu</mat-icon>
         </button>
      </div>
      <a class="navbar-brand" href="#">{{header}}</a>
      <!-- <breadcrumb class="gene-breadcrumbs"></breadcrumb> -->
      <span fxFlex></span>
      <div class="secondary-menu">
         <button class="user-button" mat-button [matMenuTriggerFor]="menu">
            <div fxLayout="row" fxLayoutAlign="center center">
               <mat-icon>account_circle</mat-icon>
            </div>
         </button>
      </div>
   </mat-toolbar>
   <div class="gene-base-container" #scrollContainer>
      <div class="inner-container" fxLayout="row wrap">
         <div class="full-wid" fxFlex="100">
            <router-outlet (activate)="onActivate($event, scrollContainer)"></router-outlet>
            <ng-container>
               <tour-step-template>
                  <ng-template let-step="step">
                     <div (click)="$event.stopPropagation()" class="popover bs-popover-bottom">
                        <div class="popover-header">
                           {{step?.title}}
                        </div>
                        <div class="popover-body">
                           <p class="tour-step-content">
                              {{step?.content}}
                           </p>
                        </div>
                        <div class="tour-step-navigation">
                           <button mat-icon-button [disabled]="!tourService.hasPrev(step)" (click)="tourService.prev()">
                           <mat-icon>chevron_left</mat-icon>
                           </button>
                           <button mat-icon-button [disabled]="!tourService.hasNext(step)" (click)="tourService.next()">
                           <mat-icon>chevron_right</mat-icon>
                           </button>
                           <button mat-button (click)="tourService.end()">End</button>
                        </div>
                     </div>
                  </ng-template>
               </tour-step-template>
            </ng-container>
         </div>
      </div>
   </div>
</mat-sidenav-container>
</div>
<mat-menu class="user-menu" x-position="before" y-position="below" #menu="matMenu">
    <button [routerLink]="['/session/login']" mat-menu-item>
      <mat-icon>exit_to_app</mat-icon>
      <span>{{'Logout'|translate}}</span>
   </button>
</mat-menu>