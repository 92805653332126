"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var core_1 = require("@angular/core");
var menu_items_1 = require("../core/menu/menu-items/menu-items");
var ng5_breadcrumb_1 = require("ng5-breadcrumb");
var page_title_service_1 = require("../core/page-title/page-title.service");
var core_2 = require("@ngx-translate/core");
var router_1 = require("@angular/router");
var ngx_tour_md_menu_1 = require("ngx-tour-md-menu");
var auth_service_1 = require("../service/auth-service/auth.service");
var core_service_1 = require("../service/core/core.service");
var authentication_service_1 = require("../authentication/authentication.service");
var utils_service_1 = require("../common/utils.service");
var screenfull = require('screenfull');
var MainComponent = /** @class */ (function () {
    function MainComponent(tourService, menuItems, breadcrumbService, pageTitleService, translate, router, authService, coreService, routes, activatedRoute, authenticationService, utilsService) {
        this.tourService = tourService;
        this.menuItems = menuItems;
        this.breadcrumbService = breadcrumbService;
        this.pageTitleService = pageTitleService;
        this.translate = translate;
        this.router = router;
        this.authService = authService;
        this.coreService = coreService;
        this.routes = routes;
        this.activatedRoute = activatedRoute;
        this.authenticationService = authenticationService;
        this.utilsService = utilsService;
        this.root = 'ltr';
        this.layout = 'ltr';
        this.currentLang = 'it';
        this.customizerIn = false;
        this.showSettings = false;
        this.chatpanelOpen = false;
        this.sidenavOpen = true;
        this.isMobile = false;
        this.isFullscreen = false;
        this.sidenavMode = 'side';
        this.sideBarFilterClass = [
            {
                sideBarSelect: "sidebar-color-1",
                colorSelect: "sidebar-color-dark"
            },
            {
                sideBarSelect: "sidebar-color-2",
                colorSelect: "sidebar-color-primary",
            },
            {
                sideBarSelect: "sidebar-color-3",
                colorSelect: "sidebar-color-accent"
            },
            {
                sideBarSelect: "sidebar-color-4",
                colorSelect: "sidebar-color-warn"
            },
            {
                sideBarSelect: "sidebar-color-5",
                colorSelect: "sidebar-color-green"
            }
        ];
        this.headerFilterClass = [
            {
                headerSelect: "header-color-1",
                colorSelect: "header-color-dark"
            },
            {
                headerSelect: "header-color-2",
                colorSelect: "header-color-primary"
            },
            {
                headerSelect: "header-color-3",
                colorSelect: "header-color-accent"
            },
            {
                headerSelect: "header-color-4",
                colorSelect: "header-color-warning"
            },
            {
                headerSelect: "header-color-5",
                colorSelect: "header-color-green"
            }
        ];
        this.chatList = [
            {
                image: "assets/img/user-1.jpg",
                name: "John Smith",
                chat: "Lorem ipsum simply dummy",
                mode: "online"
            },
            {
                image: "assets/img/user-2.jpg",
                name: "Amanda Brown",
                chat: "Lorem ipsum simply dummy",
                mode: "online"
            },
            {
                image: "assets/img/user-3.jpg",
                name: "Justin Randolf",
                chat: "Lorem ipsum simply dummy",
                mode: "offline"
            },
            {
                image: "assets/img/user-4.jpg",
                name: "Randy SunSung",
                chat: "Lorem ipsum simply dummy",
                mode: "online"
            },
            {
                image: "assets/img/user-5.jpg",
                name: "Lisa Myth",
                chat: "Lorem ipsum simply dummy",
                mode: "online"
            },
        ];
        this.username = '';
        this.role = 'USER';
        this.roleUpperCase = '';
        var browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|it/) ? browserLang : 'it');
        this.tourService.initialize([{
                anchorId: 'start.tour',
                content: 'Welcome to Gene admin panel!',
                placement: 'below',
                title: 'Welcome to Gene',
            },
            {
                anchorId: 'tour-search',
                content: 'Enjoying Search box with sugestion and many more things',
                placement: 'below',
                title: 'Search Box',
            },
            {
                anchorId: 'tour-full-screen',
                content: 'By pressing this button you can switch to fullscreen mode.',
                placement: 'below',
                title: 'Full Screen',
            },
            {
                anchorId: 'tour-ui',
                content: 'Show your site stats with unique designed cards',
                placement: 'below',
                title: 'Stats Cards',
            }]);
        if (window.innerWidth > 1199) {
            // this.tourService.start();
        }
        breadcrumbService.addFriendlyNameForRoute('/dashboard', 'Dashboard');
        breadcrumbService.addFriendlyNameForRoute('/dashboard/saas', 'SAAS');
        breadcrumbService.addFriendlyNameForRoute('/dashboard/web-analytics', 'Web Analytics');
        breadcrumbService.addFriendlyNameForRoute('/inbox', '');
        breadcrumbService.addFriendlyNameForRoute('/chat', '');
        breadcrumbService.addFriendlyNameForRoute('/calendar', '');
        breadcrumbService.addFriendlyNameForRoute('/taskboard', '');
        breadcrumbService.addFriendlyNameForRoute('/editor', 'Editor');
        breadcrumbService.addFriendlyNameForRoute('/video-player', 'Editor');
        breadcrumbService.addFriendlyNameForRoute('/editor/wysiwyg', 'Wysiwyg');
        breadcrumbService.addFriendlyNameForRoute('/editor/ckeditor', 'Ckeditor');
        breadcrumbService.addFriendlyNameForRoute('/icons', '');
        breadcrumbService.addFriendlyNameForRoute('/components', 'Components');
        breadcrumbService.addFriendlyNameForRoute('/components/buttons', 'Buttons');
        breadcrumbService.addFriendlyNameForRoute('/components/cards', 'Cards');
        breadcrumbService.addFriendlyNameForRoute('/components/grid', 'Grid');
        breadcrumbService.addFriendlyNameForRoute('/components/list', 'List');
        breadcrumbService.addFriendlyNameForRoute('/components/menu', 'Menu');
        breadcrumbService.addFriendlyNameForRoute('/components/slider', 'Slider');
        breadcrumbService.addFriendlyNameForRoute('/components/snackbar', 'Snackbar');
        breadcrumbService.addFriendlyNameForRoute('/components/dialog', 'Dialog');
        breadcrumbService.addFriendlyNameForRoute('/components/select', 'Select');
        breadcrumbService.addFriendlyNameForRoute('/components/input', 'Input');
        breadcrumbService.addFriendlyNameForRoute('/components/colorpicker', 'Colorpicker');
        breadcrumbService.addFriendlyNameForRoute('/checkbox', 'Checkbox');
        breadcrumbService.addFriendlyNameForRoute('/components/radio', 'Radio');
        breadcrumbService.addFriendlyNameForRoute('/components/toolbar', 'Toolbar');
        breadcrumbService.addFriendlyNameForRoute('/components/progress', 'Progress');
        breadcrumbService.addFriendlyNameForRoute('/components/tabs', 'Tabs');
        breadcrumbService.addFriendlyNameForRoute('/dragndrop', 'Drag and Drop');
        breadcrumbService.addFriendlyNameForRoute('/dragndrop/dragula', 'Dragula');
        breadcrumbService.addFriendlyNameForRoute('/dragndrop/sortable', 'SortableJS');
        breadcrumbService.addFriendlyNameForRoute('/chart', 'Charts');
        breadcrumbService.addFriendlyNameForRoute('/chart/ng2-charts', 'NG2 Charts');
        breadcrumbService.addFriendlyNameForRoute('/chart/easy-pie-chart', 'Easy Pie');
        breadcrumbService.addFriendlyNameForRoute('/tables', 'Table');
        breadcrumbService.addFriendlyNameForRoute('/tables/fullscreen', 'Full Screen');
        breadcrumbService.addFriendlyNameForRoute('/tables/selection', 'Selection');
        breadcrumbService.addFriendlyNameForRoute('/tables/pinning', 'Pinning');
        breadcrumbService.addFriendlyNameForRoute('/tables/sorting', 'Sorting');
        breadcrumbService.addFriendlyNameForRoute('/tables/Paging', 'Paging');
        breadcrumbService.addFriendlyNameForRoute('/tables/editing', 'Editing');
        breadcrumbService.addFriendlyNameForRoute('/tables/filter', 'Filter');
        breadcrumbService.addFriendlyNameForRoute('/tables/responsive', 'Responsive');
        breadcrumbService.addFriendlyNameForRoute('/forms', 'Forms');
        breadcrumbService.addFriendlyNameForRoute('/forms/form-wizard', 'Form Wizard');
        breadcrumbService.addFriendlyNameForRoute('/forms/form-validation', 'Form Validation');
        breadcrumbService.addFriendlyNameForRoute('/forms/form-upload', 'Form Upload');
        breadcrumbService.addFriendlyNameForRoute('/forms/form-tree', 'Tree');
        breadcrumbService.addFriendlyNameForRoute('/maps', 'Maps');
        breadcrumbService.addFriendlyNameForRoute('/maps/googlemap', 'Google Map');
        breadcrumbService.addFriendlyNameForRoute('/maps/leafletmap', 'Leaflet Map');
        breadcrumbService.addFriendlyNameForRoute('/pages', 'Pages');
        breadcrumbService.addFriendlyNameForRoute('/pages/media', 'Gallery');
        breadcrumbService.addFriendlyNameForRoute('/pages/pricing', 'Pricing');
        breadcrumbService.addFriendlyNameForRoute('/pages/blank', 'Blank');
        breadcrumbService.addFriendlyNameForRoute('/pages/mediaV2', 'Gallery V2');
        breadcrumbService.addFriendlyNameForRoute('/pages/pricing-1', 'Pricing-1');
        breadcrumbService.addFriendlyNameForRoute('/pages/timeline', 'Timeline');
        breadcrumbService.addFriendlyNameForRoute('/pages/faq', 'FAQ');
        breadcrumbService.addFriendlyNameForRoute('/pages/feedback', 'Feedback');
        breadcrumbService.addFriendlyNameForRoute('/pages/about', 'About');
        breadcrumbService.addFriendlyNameForRoute('/pages/contact', 'Contact');
        breadcrumbService.addFriendlyNameForRoute('/pages/search', 'Search');
        breadcrumbService.addFriendlyNameForRoute('/pages/comingsoon', 'Coming Soon');
        breadcrumbService.addFriendlyNameForRoute('/pages/maintenance', 'Maintenance');
        breadcrumbService.addFriendlyNameForRoute('/users', 'Users');
        breadcrumbService.addFriendlyNameForRoute('/users/userprofile', 'User Profile');
        breadcrumbService.addFriendlyNameForRoute('/users/userlist', 'User List');
        breadcrumbService.addFriendlyNameForRoute('/session', 'Session');
        breadcrumbService.addFriendlyNameForRoute('/session/login', 'Login');
        breadcrumbService.addFriendlyNameForRoute('/session/register', 'Register');
        breadcrumbService.addFriendlyNameForRoute('/session/forgot-password', 'Forgot');
        breadcrumbService.addFriendlyNameForRoute('/session/lockscreen', 'Lock Screen');
        breadcrumbService.addFriendlyNameForRoute('/courses', 'Courses');
        breadcrumbService.addFriendlyNameForRoute('/dashboard/courses', 'Courses');
        breadcrumbService.addFriendlyNameForRoute('/courses/courses-list', 'Courses List');
        breadcrumbService.addFriendlyNameForRoute('/courses/course-detail', 'Course Detail');
        breadcrumbService.addFriendlyNameForRoute('/courses/signin', 'Sign In');
        breadcrumbService.addFriendlyNameForRoute('/courses/payment', 'Payment');
        breadcrumbService.addFriendlyNameForRoute('/ecommerce', 'Ecommerce');
        breadcrumbService.addFriendlyNameForRoute('/ecommerce/shop', 'Shop');
        breadcrumbService.addFriendlyNameForRoute('/ecommerce/cart', 'Cart');
        breadcrumbService.addFriendlyNameForRoute('/ecommerce/checkout', 'Checkout');
        breadcrumbService.addFriendlyNameForRoute('/ecommerce/cards', 'Cards');
        breadcrumbService.addFriendlyNameForRoute('/ecommerce/invoice', 'Invoice');
        breadcrumbService.addFriendlyNameForRoute('/users/userprofilev2', 'User Profile V2');
        breadcrumbService.addFriendlyNameForRoute('/user-management', 'Management');
        breadcrumbService.addFriendlyNameForRoute('/user-management/usermanagelist', 'User List');
        breadcrumbService.addFriendlyNameForRoute('/user-management/usergridlist', 'User Grid');
        breadcrumbService.addFriendlyNameForRoute('/video-player', '');
        breadcrumbService.addFriendlyNameForRoute('/crypto', 'Crypto');
        breadcrumbService.addFriendlyNameForRoute('/dashboard/crypto', 'Crypto');
        breadcrumbService.addFriendlyNameForRoute('/crypto/marketcap', 'Market Cap');
        breadcrumbService.addFriendlyNameForRoute('/crypto/wallet', 'Wallet');
        breadcrumbService.addFriendlyNameForRoute('/crypto/trade', 'Trade');
        breadcrumbService.addFriendlyNameForRoute('/crm', 'CRM');
        breadcrumbService.addFriendlyNameForRoute('/crm/dasboard', 'Dasboard');
        breadcrumbService.addFriendlyNameForRoute('/crm/projects', 'Projects');
        breadcrumbService.addFriendlyNameForRoute('/crm/project-detail', 'Project Details');
        breadcrumbService.addFriendlyNameForRoute('/crm/clients', 'Clients');
        breadcrumbService.addFriendlyNameForRoute('/crm/reports', 'Reports');
        breadcrumbService.addFriendlyNameForRoute('/dashboard/crm', 'CRM');
        breadcrumbService.addFriendlyNameForRoute('/ecommerce/products', 'Products');
        breadcrumbService.addFriendlyNameForRoute('/ecommerce/edit-products', 'Edit Products');
        breadcrumbService.addFriendlyNameForRoute('/ecommerce/product-add', 'Product Add');
        breadcrumbService.addFriendlyNameForRoute('/crm/reports', 'Reports');
        breadcrumbService.addFriendlyNameForRoute('/crm/reports', 'Reports');
    }
    MainComponent.prototype.ngOnInit = function () {
        var _this = this;
        if ((this.router.url == '/dashboard/courses' || this.router.url === '/courses/courses-list' || this.router.url === '/courses/course-detail' || this.router.url === '/ecommerce/shop' || this.router.url === '/ecommerce/checkout' || this.router.url === '/ecommerce/invoice') && window.innerWidth < 1920) {
            this.coreService.sidenavOpen = false;
        }
        this.coreService.collapseSidebarStatus = this.coreService.collapseSidebar;
        this.pageTitleService.title.subscribe(function (val) {
            _this.header = val;
        });
        this._router = this.router.events.pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationEnd; })).subscribe(function (event) {
            _this.coreService.collapseSidebarStatus = _this.coreService.collapseSidebar;
            _this.url = event.url;
            _this.customizeSidebar();
        });
        this.url = this.router.url;
        this.customizeSidebar();
        setTimeout(function () {
            _this.windowSize = window.innerWidth;
            _this.resizeSideBar();
        }, 0);
        this._routerEventsSubscription = this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationEnd && _this.isMobile) {
                _this.sidenav.close();
            }
        });
        var user = this.authenticationService.getUser();
        if (!!user) {
            this.username = user['email'];
            this.role = user['role'];
            this.roleUpperCase = this.role.toUpperCase();
        }
        else {
            this.utilsService.translate('ADMIN').subscribe(function (res) {
                _this.username = res;
            });
        }
    };
    MainComponent.prototype.ngOnDestroy = function () {
        this._router.unsubscribe();
    };
    /**
      *As router outlet will emit an activate event any time a new component is being instantiated.
      */
    MainComponent.prototype.onActivate = function (e, scrollContainer) {
        scrollContainer.scrollTop = 0;
    };
    /**
      * toggleFullscreen method is used to show a template in fullscreen.
      */
    MainComponent.prototype.toggleFullscreen = function () {
        if (screenfull.enabled) {
            screenfull.toggle();
            this.isFullscreen = !this.isFullscreen;
        }
    };
    /**
      * customizerFunction is used to open and close the customizer.
      */
    MainComponent.prototype.customizerFunction = function () {
        this.customizerIn = !this.customizerIn;
    };
    /**
      * addClassOnBody method is used to add a add or remove class on body.
      */
    MainComponent.prototype.addClassOnBody = function (event) {
        var body = document.body;
        if (event.checked) {
            body.classList.add("dark-theme-active");
        }
        else {
            body.classList.remove('dark-theme-active');
        }
    };
    /**
      * changeRTL method is used to change the layout of template.
      */
    MainComponent.prototype.changeRTL = function (isChecked) {
        if (isChecked) {
            this.layout = "rtl";
        }
        else {
            this.layout = "ltr";
        }
    };
    /**
      * toggleSidebar method is used a toggle a side nav bar.
      */
    MainComponent.prototype.toggleSidebar = function () {
        this.coreService.sidenavOpen = !this.coreService.sidenavOpen;
    };
    /**
      * sidebarFilter function filter the color for sidebar section.
      */
    MainComponent.prototype.sidebarFilter = function (selectedFilter) {
        for (var i = 0; i < this.sideBarFilterClass.length; i++) {
            document.getElementById('main-app').classList.remove(this.sideBarFilterClass[i].colorSelect);
            if (this.sideBarFilterClass[i].colorSelect == selectedFilter.colorSelect) {
                document.getElementById('main-app').classList.add(this.sideBarFilterClass[i].colorSelect);
            }
        }
        document.querySelector('.radius-circle').classList.remove('radius-circle');
        document.getElementById(selectedFilter.sideBarSelect).classList.add('radius-circle');
    };
    /**
      * headerFilter function filter the color for header section.
      */
    MainComponent.prototype.headerFilter = function (selectedFilter) {
        for (var i = 0; i < this.headerFilterClass.length; i++) {
            document.getElementById('main-app').classList.remove(this.headerFilterClass[i].colorSelect);
            if (this.headerFilterClass[i].colorSelect == selectedFilter.colorSelect) {
                document.getElementById('main-app').classList.add(this.headerFilterClass[i].colorSelect);
            }
        }
        document.querySelector('.radius-active').classList.remove('radius-active');
        document.getElementById(selectedFilter.headerSelect).classList.add('radius-active');
    };
    /**
      *chatMenu method is used to toggle a chat menu list.
      */
    MainComponent.prototype.chatMenu = function () {
        document.getElementById("gene-chat").classList.toggle("show-chat-list");
    };
    /**
      * onChatOpen method is used to open a chat window.
      */
    MainComponent.prototype.onChatOpen = function () {
        document.getElementById('chat-open').classList.toggle('show-chat-window');
    };
    /**
      * onChatWindowClose method is used to close the chat window.
      */
    MainComponent.prototype.chatWindowClose = function () {
        document.getElementById("chat-open").classList.remove("show-chat-window");
    };
    MainComponent.prototype.collapseSidebar = function (event) {
        document.getElementById('main-app').classList.toggle('collapsed-sidebar');
    };
    //onResize method is used to set the side bar according to window width.
    MainComponent.prototype.onResize = function (event) {
        this.windowSize = event.target.innerWidth;
        this.resizeSideBar();
    };
    //customizeSidebar method is used to change the side bar behaviour.
    MainComponent.prototype.customizeSidebar = function () {
        if ((this.url === '/dashboard/courses' || this.url === '/courses/courses-list' || this.url === '/courses/course-detail' || this.url === '/ecommerce/shop' || this.url === '/ecommerce/checkout' || this.url === '/ecommerce/invoice') && this.windowSize < 1920) {
            this.coreService.sidenavMode = 'over';
            this.coreService.sidenavOpen = false;
            if (!(document.getElementById('main-app').classList.contains('sidebar-overlay'))) {
                document.getElementById('main-app').className += " sidebar-overlay";
            }
        }
        else if ((window.innerWidth > 1200) && (this.url == '/dashboard/crypto' || this.url == '/crypto/marketcap' || this.url == '/crypto/wallet' || this.url == '/crypto/trade')) {
            this.collapseSidebarStatus = this.coreService.collapseSidebar;
            if ((this.collapseSidebarStatus == false) && (window.innerWidth > 1200)) {
                document.getElementById('main-app').className += ' collapsed-sidebar';
                this.coreService.collapseSidebar = true;
                this.coreService.sidenavOpen = true;
                this.coreService.sidenavMode = 'side';
                document.getElementById('main-app').classList.remove('sidebar-overlay');
            }
        }
        else if ((window.innerWidth > 1200) && !(this.url === '/dashboard/courses' || this.url === '/courses/courses-list' || this.url === '/courses/course-detail' || this.url === '/ecommerce/shop' || this.url === '/ecommerce/checkout' || this.url === '/ecommerce/invoice')) {
            this.coreService.sidenavMode = 'side';
            this.coreService.sidenavOpen = true;
            //for responsive 
            var main_div = document.getElementsByClassName('app');
            for (var i = 0; i < main_div.length; i++) {
                if (main_div[i].classList.contains('sidebar-overlay')) {
                    document.getElementById('main-app').classList.remove('sidebar-overlay');
                }
            }
        }
        //for responsive
        else if (window.innerWidth < 1200) {
            this.coreService.sidenavMode = 'over';
            this.coreService.sidenavOpen = false;
            var main_div = document.getElementsByClassName('app');
            for (var i = 0; i < main_div.length; i++) {
                if (!(main_div[i].classList.contains('sidebar-overlay'))) {
                    document.getElementById('main-app').className += " sidebar-overlay";
                }
            }
        }
    };
    //To resize the side bar according to window width.
    MainComponent.prototype.resizeSideBar = function () {
        if (this.windowSize < 1200) {
            this.isMobileStatus = true;
            this.isMobile = this.isMobileStatus;
            this.coreService.sidenavMode = 'over';
            this.coreService.sidenavOpen = false;
            //for responsive
            var main_div = document.getElementsByClassName('app');
            for (var i = 0; i < main_div.length; i++) {
                if (!(main_div[i].classList.contains('sidebar-overlay'))) {
                    if (document.getElementById('main-app')) {
                        document.getElementById('main-app').className += " sidebar-overlay";
                    }
                }
            }
        }
        else if ((this.url === '/dashboard/courses' || this.url === '/courses/courses-list' || this.url === '/courses/course-detail' || this.url === '/ecommerce/shop' || this.url === '/ecommerce/checkout' || this.url === '/ecommerce/invoice') && this.windowSize < 1920) {
            this.customizeSidebar();
        }
        else {
            this.isMobileStatus = false;
            this.isMobile = this.isMobileStatus;
            this.coreService.sidenavMode = 'side';
            this.coreService.sidenavOpen = true;
            //for responsive
            var main_div = document.getElementsByClassName('app');
            for (var i = 0; i < main_div.length; i++) {
                if (main_div[i].classList.contains('sidebar-overlay')) {
                    document.getElementById('main-app').classList.remove('sidebar-overlay');
                }
            }
        }
    };
    return MainComponent;
}());
exports.MainComponent = MainComponent;
