import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageTitleService} from '../../../core/page-title/page-title.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User, UserService} from '../user.service';
import {UtilsService} from '../../../common/utils.service';
import { ConfigService } from 'app/common/config.service';
import { Observable } from 'rxjs';
import {DisposeBag} from '../../../classes/dispose-bag.class';
import {LoadingService} from '../../../common/loading.service';
import { AuthenticationService, CurrentUser } from 'app/authentication/authentication.service';

const regEx = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'

@Component({
  selector: 'ms-form-user',
  templateUrl: './form-user.component.html',
  styleUrls: ['./form-user.component.scss']
})
export class FormUserComponent implements OnInit, OnDestroy {
  loading = false;
  disposeBag = new DisposeBag();

  public form: FormGroup;

  public cardTitle: string
  public id: string
  user: User
  currentUser: CurrentUser

  page: number
  
  roles: any[]

  constructor(private fb: FormBuilder,
              private pageTitleService: PageTitleService,
              private route: ActivatedRoute,
              private userService: UserService,
              private router: Router,
              private utils: UtilsService,
              private config: ConfigService,
              private loadingService: LoadingService,
              private authService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.utils.translate('UPDATE USER').subscribe((res: string) => {
      this.cardTitle = res
    })
    this.utils.translate('USERS').subscribe((res: string) => {
      this.pageTitleService.setTitle(res);
    })

    this.disposeBag.add(this.loadingService.loading$.subscribe(res => { this.loading = res; }));
    this.roles = this.config.getRolesDict()

    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      surname: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])],
      role: [null, Validators.compose([Validators.required])],
      phone: [null],
      mobile: [null],
      enabled: [null, Validators.compose([Validators.required])],
      company: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.pattern(regEx)])],
      confirm_password: [null, Validators.compose([Validators.pattern(regEx)])]
    },
    {
      validator: this.checkPassword
    })

    this.route.params.subscribe(params => {
      this.id = String(params['id'])
      if (!this.isNew()) {
        this.utils.translate('MODIFY THE USER').subscribe((res: string) => {
          this.cardTitle = res
        })
        this.loadUserData()
      }
      else {
        this.form.controls.enabled.setValue(false)
      }
    })

    this.route.queryParams.subscribe(params => {
      this.page = params['page'] || 0
    });
    this.currentUser = this.authService.getUser()
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  loadUserData() {
    const id = this.id
    this.loadingService.updateLoading(true);
    this.userService.getOne(id).subscribe(
      res => {
        this.user = res as User
        this.objToForm(this.user)
      },
      err => console.log('Error occured : ' + err),
      () => { this.loadingService.updateLoading(false); }
    );
  }

  public objToForm(user: User) {
    this.form.controls.name.setValue(user.name)
    this.form.controls.surname.setValue(user.surname)
    this.form.controls.email.setValue(user.email)
    this.form.controls.phone.setValue(user.phone)
    this.form.controls.mobile.setValue(user.mobile)
    this.form.controls.role.setValue(user.role)
    this.form.controls.enabled.setValue(user.enabled)
    this.form.controls.company.setValue(user.company)
  }

  public formToObj(): any {
    let obj = {}
    if (this.user) {
      obj = this.user
    }
    obj['name'] = this.form.controls.name.value
    obj['surname'] = this.form.controls.surname.value
    obj['email'] = this.form.controls.email.value
    obj['phone'] = this.form.controls.phone.value
    obj['mobile'] = this.form.controls.mobile.value
    obj['role'] = this.form.controls.role.value
    obj['enabled'] = this.form.controls.enabled.value
    obj['company'] = this.form.controls.company.value
    obj['password'] = this.form.controls.password.value
    return obj as any
  }

  isFormValid() {
    const isValid = this.form.valid;

    if (this.isNew()) {
        return isValid && !!this.form.controls.password.value && !!this.form.controls.confirm_password.value
    }

    return isValid
  }

  public onSubmit() {
    if (!this.isFormValid()) { return; }
    const user = this.formToObj() as any;
    this.isNew() ? this.handleRequest(this.userService.insertUser(user)) : this.handleRequest(this.userService.updateUser(user))
  }

  public handleRequest(input: Observable<any>) {
    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(input).subscribe(() => this.goToListPage()));
  }

  public isNew(): Boolean {
    return this.id === 'undefined'
  }

  public goToListPage() {
    this.router.navigate(['users'], { queryParams: { page: this.page } });
  }

  setPassword() {
    this.utils.openPasswordDialog(this.id)
  }
  
//TODO da centralizzare
  checkPassword(form: FormGroup) {
    return form.controls.password.value === form.controls.confirm_password.value ? null : {'mismatch': true};
  }

  validatePassword() {
    return (this.form.controls.password.hasError('required') || this.form.controls.password.hasError('pattern'))
      && this.form.controls.password.touched
  }

  matchPassword() {
    return !this.form.controls.password.hasError('pattern') && this.form.controls.password.value !== this.form.controls.confirm_password.value
      && this.form.controls.confirm_password.touched
  }

  isMe(): boolean {
    if (!this.user || !this.currentUser) { return false; }
    return this.user.id === this.currentUser.id
  }
}