"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var crud_api_service_1 = require("app/common/crud-api.service");
var request_service_1 = require("app/common/request.service");
var api_service_1 = require("app/common/api.service");
var i0 = require("@angular/core");
var i1 = require("../../common/request.service");
var i2 = require("../../common/api.service");
var UserService = /** @class */ (function (_super) {
    __extends(UserService, _super);
    function UserService(requestService, apiService) {
        var _this = _super.call(this, requestService, apiService) || this;
        _this.requestService = requestService;
        _this.apiService = apiService;
        _this.target = 'user';
        return _this;
    }
    UserService.prototype.getList = function () {
        return _super.prototype.getList.call(this)
            .map(function (array) {
            return array
                .map(function (i) { return new User(i); })
                .sort(function (a, b) { return a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : 1; })
                .map(function (user) { return user.toObject(); });
        });
    };
    UserService.prototype.insertUser = function (user) {
        return this.requestService.post(this.apiService.createUrlFromBaseUrl(this.target), JSON.stringify(user));
    };
    UserService.prototype.updateUser = function (user) {
        return this.requestService.put(this.apiService.createUrlFromBaseUrl(this.target, String(user.id)), JSON.stringify(user));
    };
    UserService.prototype.updatePassword = function (password, id) {
        return this.requestService.put(this.apiService.createUrlFromBaseUrl(this.target, id, 'password'), JSON.stringify(password));
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.RequestService), i0.ɵɵinject(i2.ApiService)); }, token: UserService, providedIn: "root" });
    return UserService;
}(crud_api_service_1.CrudApiService));
exports.UserService = UserService;
var User = /** @class */ (function () {
    function User(userObj) {
        Object.assign(this, userObj);
        this.fullName = [this.name, this.surname].filter(function (i) { return !!i && i !== ''; }).join(' ');
    }
    User.prototype.toObject = function () {
        return JSON.parse(JSON.stringify(this));
    };
    return User;
}());
exports.User = User;
var RolesTypes;
(function (RolesTypes) {
    RolesTypes["ADMIN"] = "admin";
    RolesTypes["USER"] = "user";
})(RolesTypes = exports.RolesTypes || (exports.RolesTypes = {}));
