import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { Observable, zip } from 'rxjs';
import { Router } from '@angular/router';
import { ConfigService } from 'app/common/config.service';
import { LoadingService } from 'app/common/loading.service';
import { User, UserService } from '../user.service';
import { GridBaseComponent } from 'app/classes/grid-base.component';
import { UtilsService } from 'app/common/utils.service';

@Component({
  selector: 'ms-grid-user',
  templateUrl: './grid-user.component.html',
  styleUrls: ['./grid-user.component.scss']
})
export class GridUserComponent extends GridBaseComponent implements OnInit {

  @ViewChild(DxDataGridComponent, {static: true}) dataGrid: DxDataGridComponent;

  users: Observable<any[]>;
  
  constructor(private router: Router, public configService: ConfigService, 
    private utils: UtilsService, private loadingService: LoadingService, private userService: UserService) {
      super(configService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
    this.linkButtonClick = this.linkButtonClick.bind(this);
  }

  linkButtonClick(e) {
    this.router.navigate(['users', e.row.data.id]);
  }

  loadData() {
    this.users = this.loadingService.apiRequest$WithLoading(zip(this.userService.getList(), this.configService.translatedRoleDict())).map(array => {
      const users = array[0];
      const roles = array[1];
      return users.map(user => {
        user.role = user.role.toUpperCase();
        user.role = roles[user.role];
        return user;
      });
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => {
          this.loadData();
        }
      }
    }, {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          onClick: () => {
            this.router.navigate(['users/add'])
          }
        }
      }
    )
  }
}
