"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-loading-bar/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var dispose_bag_class_1 = require("../../classes/dispose-bag.class");
var MtLoadingComponent = /** @class */ (function () {
    function MtLoadingComponent(loadingBar) {
        this.loadingBar = loadingBar;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
        this.loading = new rxjs_1.Subject();
        this.loading$ = this.loading.asObservable().pipe(operators_1.delay(0));
    }
    MtLoadingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stop();
        this.disposeBag.add(this.loading$.pipe(operators_1.tap(function (ev) {
        })).subscribe(function (ev) {
            _this.handleShow(ev);
        }));
    };
    MtLoadingComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    MtLoadingComponent.prototype.ngOnChanges = function (changes) {
        if (!!changes) {
            var loading = changes.show.currentValue;
            this.loading.next(loading);
        }
    };
    MtLoadingComponent.prototype.handleShow = function (ev) {
        !!ev ? this.start() : this.stop();
    };
    MtLoadingComponent.prototype.start = function () {
        this.loadingBar.start();
    };
    MtLoadingComponent.prototype.stop = function () {
        this.loadingBar.complete();
    };
    return MtLoadingComponent;
}());
exports.MtLoadingComponent = MtLoadingComponent;
