import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DxFileUploaderComponent, DxFormComponent } from "devextreme-angular";
import { DisposeBag } from "app/classes/dispose-bag.class";
import { LoadingService } from "app/common/loading.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilsService } from "app/common/utils.service";
import { MagVersion, MagazineLocalizationService } from "../magazine-localization.service";
import { LogService } from "app/common/log.service";
import { Observable, of } from "rxjs";
import { AppApiService } from "app/common/app-api.service";
import { DxService } from "app/common/dx.service";

const maxFileSize = 150000000; // 150 MB

@Component({
  selector: 'ms-form-magazine-localization',
  templateUrl: './form-magazine-localization.component.html',
  styleUrls: ['./form-magazine-localization.component.scss'],
})
export class FormMagazineLocalizationComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  @ViewChild(DxFileUploaderComponent, { static: false }) uploader: DxFileUploaderComponent;
  magazineVersion: MagVersion;
  magazineId: number;
  languageFile: any;
  canUpdateFile: boolean;
  cardTitle: string;
  isFileUploaded: boolean;
  disposeBag: DisposeBag;
  isFormInvalid: boolean;
  isMaxSizeExceeded: boolean;
  languageEditorOptions$: Observable<any>;

  constructor(
    public loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private appApiService: AppApiService,
    private dxService: DxService,
    private magazineLocalizationService: MagazineLocalizationService,
    private logger: LogService,
  ) {
    this.disposeBag = new DisposeBag();
    this.canUpdateFile = false;
    this.isFileUploaded = false;
    this.isFormInvalid = true;
    this.isMaxSizeExceeded = false;
  }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('MAGAZINE LOCALIZATIONS');
    const languages$ = this.appApiService.getMagazineLanguages();
    this.languageEditorOptions$ = this.dxService.dxiItemEditorOptions(languages$, undefined, 'name', 'id');

    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.getMagVersion()).subscribe((magVersion: MagVersion) => {
      this.magazineVersion = magVersion;
      this.canUpdateFile = !magVersion.url;
      if (!!magVersion.id) { this.dxForm.instance.updateData(magVersion); }
    }));
    this.disposeBag.add(this.route.params.map(params => params['id']).subscribe((id: number) => this.magazineId = id));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  getMagVersion(): Observable<MagVersion> {
    return this.route.params.map(params => params['language_id']).switchMap((idFound, _) => {
      if (idFound) {
        this.cardTitle = 'MODIFY VERSION';
        return this.magazineLocalizationService.getOne(idFound);
      }
      this.cardTitle = 'ADD VERSION';
      return of(new MagVersion());
    });
  }

  onFormSubmit() {
    if (this.magazineVersion.id) {
      this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.updateLocalization()).subscribe(
        () => this.goToListPage(),
        (error) => this.showError(error)
      ));
    } else {
      if (!this.magazineId) { return; }
      this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(this.saveVersion()).subscribe(
        () => this.goToListPage(),
        (error) => this.showError(error)
      ));
    }
  }

  showError(error: any) {
    // if error is an instance of HttpErrorResponse, check error.status
    console.log(error);
    if (error.status === 409) {
      this.logger.duplicateLanguageForMagazineError();
      return;
    }
    this.logger.serverError();
  }

  fileChange(event: File[]) {
    this.languageFile = null;
    this.isMaxSizeExceeded = false;
    if (event.length > 0) {
      if (event[0].size > maxFileSize) {
        this.uploader.instance.reset();
        this.isMaxSizeExceeded = true;
      } else { this.languageFile = event[0]; }
    }
    this.isFileUploaded = !!this.languageFile;
    this.isFormInvalid = !this.isFormValid();
  }

  onFieldDataChanged() {
    this.isFormInvalid = !this.isFormValid();
  }

  validate() {
    if (!this.dxForm || !this.dxForm.instance) { return { isValid: false }; }
    return this.dxForm.instance.validate();
  }

  isFormValid() {
    const result = this.validate();
    const isFormValid = result.isValid;
    return this.magazineVersion.id ? isFormValid : isFormValid && this.isFileUploaded && !this.isMaxSizeExceeded;
  }

  public goToListPage() {
    if (!this.magazineId) { return; }
    this.router.navigate(['magazine', this.magazineId, 'language']);
  }

  private saveVersion(): Observable<any> {
    this.magazineVersion.magazine_id = this.magazineId;
    return this.magazineLocalizationService.save(this.magazineVersion, this.languageFile);
  }

  private updateLocalization(): Observable<any> {
    return this.magazineLocalizationService.updateLocalization(this.magazineVersion.id, this.magazineVersion.name, this.magazineVersion.language_id)
  }
}