"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("rxjs/Rx");
var http_1 = require("@angular/common/http");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var CoreService = /** @class */ (function () {
    function CoreService(http) {
        this.http = http;
        this.collapseSidebar = false;
        this.sidenavMode = "side";
        this.sidenavOpen = true;
        this.horizontalSideNavMode = "over";
        this.horizontalSideNavOpen = false;
    }
    CoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoreService_Factory() { return new CoreService(i0.ɵɵinject(i1.HttpClient)); }, token: CoreService, providedIn: "root" });
    return CoreService;
}());
exports.CoreService = CoreService;
