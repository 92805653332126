import { LoadingService } from '../../common/loading.service';
import { UtilsService } from '../../common/utils.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ms-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit {

  constructor(private utilsService: UtilsService, public loadingService: LoadingService) { }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('CATALOGUES');
  }
}
