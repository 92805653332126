import { FormProcessPaperCopyRequestComponent } from './components/paper-copy-request/form-process-paper-copy-request/form-process-paper-copy-request.component';
import { QuillModule } from 'ngx-quill';
import { LocationService } from './components/location/location.service';
import { AppApiService } from './common/app-api.service';
import { NewsService } from './components/news/news.service';
import { MtLoadingModule } from './common/mt-loading/mt-loading.module';
import { UserService } from './components/user/user.service';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector, APP_INITIALIZER } from '@angular/core';
import 'hammerjs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng5BreadcrumbModule, BreadcrumbService } from 'ng5-breadcrumb';
import { TourMatMenuModule } from 'ngx-tour-md-menu';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { MatSlideToggleModule, MatButtonModule, MatBadgeModule, MatCardModule, MatMenuModule, MatToolbarModule, MatIconModule,
	MatInputModule, MatDatepickerModule, MatNativeDateModule, MatProgressSpinnerModule, MatTableModule, MatExpansionModule,
	MatSelectModule, MatSnackBarModule, MatTooltipModule, MatChipsModule, MatListModule, MatSidenavModule, MatTabsModule,
	MatProgressBarModule, MatCheckboxModule, MatSliderModule, MatRadioModule, MatDialogModule, MatGridListModule, MatAutocompleteModule } from '@angular/material';
import { RoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AuthService } from './service/auth-service/auth.service';
import { PageTitleService } from './core/page-title/page-title.service';
import { GeneAppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { MenuToggleModule } from './core/menu/menu-toggle.module';
import { MenuItems } from './core/menu/menu-items/menu-items';
import { AuthGuard } from './core/guards/auth.guard';
import { WidgetComponentModule } from './widget-component/widget-component.module';
import { SideBarComponent } from './Shared/side-bar/side-bar.component';
import { FooterComponent } from './Shared/footer/footer.component';
import { UserComponent } from './components/user/user.component';
import { GridUserComponent } from './components/user/grid-user/grid-user.component';
import { DxDataGridModule, DxButtonModule, DxFormModule, DxFileUploaderModule, DxDateBoxModule } from 'devextreme-angular';
import { FormUserComponent } from './components/user/form-user/form-user.component';
import { UtilsService } from './common/utils.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { TokenInterceptor } from './authentication/token-interceptor';
import { ErrorInterceptor } from './authentication/error-Interceptor';
import { CatalogueComponent } from './components/catalogue/catalogue.component';
import { GridCatalogueComponent } from './components/catalogue/grid-catalogue/grid-catalogue.component';
import { FormCatalogueComponent } from './components/catalogue/form-catalogue/form-catalogue.component';
import { CatalogueService } from './components/catalogue/catalogue.service';
import { DxService } from './common/dx.service';
import { LoadingService } from './common/loading.service';
import { VersionComponent } from './components/version/version.component';
import { GridVersionComponent } from './components/version/grid-version/grid-version.component';
import { VersionService } from './components/version/version.service';
import { FormVersionComponent } from './components/version/form-version/form-version.component';
import { InfoComponent } from './components/info/info.component';
import { FormInfoComponent } from './components/info/form-info/form-info.component';
import { ViewInfoComponent } from './components/info/view-info/view-info.component';
import { BlobImageUrlPipe } from './common/blob-image-url.pipe';
import { FormPasswordComponent } from './components/user/form-password/form-password.component';
import { TestMapComponent } from './components/test-map/test-map.component';
import { LeafletMapComponent } from './common/leaflet-map/leaflet-map.component';
import { SessionModule } from './session/session.module';
import { GridBaseComponent } from './classes/grid-base.component';
import { NewsComponent } from './components/news/news.component';
import { GridNewsComponent } from './components/news/grid-news/grid-news.component';
import { FormNewsComponent } from './components/news/form-news/form-news.component';
import { LocationComponent } from './components/location/location.component';
import { GridLocationComponent } from './components/location/grid-location/grid-location.component';
import { FormLocationComponent } from './components/location/form-location/form-location.component';
import { TextBoxMultilanguageComponent } from './common/text-box-multilanguage/text-box-multilanguage.component';
import { TextAreaMultilanguageComponent } from './common/text-area-multilanguage/text-area-multilanguage.component';
import { PaperCopyRequestComponent } from './components/paper-copy-request/paper-copy-request.component';
import { GridPaperCopyRequestComponent } from './components/paper-copy-request/grid-paper-copy-request/grid-paper-copy-request.component';
import { HtmlEditorMultilanguageComponent } from './common/html-editor-multilanguage/html-editor-multilanguage.component';
import { NgxHeremapsGeocodingModule } from 'ngx-heremaps-geocoding';
import 'here-js-api/scripts/mapsjs-core';
import 'here-js-api/scripts/mapsjs-service';
import { environment } from 'environments/environment';
import { GeoSearchComponent } from './common/geo-search/geo-search.component';
import { MagazineComponent } from './components/magazine/magazine.component';
import { MagazineService } from './components/magazine/magazine.service';
import { GridMagazineComponent } from './components/magazine/grid-magazine/grid-magazine.component';
import { FormMagazineComponent } from './components/magazine/form-magazine/form-magazine.component';
import { MagazineLocalizationComponent } from './components/magazine/localizations/magazine-localization.component';
import { MagazineLocalizationService } from './components/magazine/localizations/magazine-localization.service';
import { GridMagazineLocalizationComponent } from './components/magazine/localizations/grid-magazine-localization/grid-magazine-localization.component';
import { FormMagazineLocalizationComponent } from './components/magazine/localizations/form-magazine-localization/form-magazine-localization.component';

export const firebaseConfig = {
	apiKey: 'AIzaSyCE0po6Q8jGuBEds-A903KEU4U6Cerojzo',
	authDomain: 'gene-eaeef.firebaseapp.com',
	databaseURL: 'https://gene-eaeef.firebaseio.com',
	projectId: 'gene-eaeef',
	storageBucket: 'gene-eaeef.appspot.com',
	messagingSenderId: '81833823583',
	appId: '1:81833823583:web:581d7ddd8cfa93a4'
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
	return () => new Promise<any>((resolve: any) => {
		const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
		locationInitialized.then(() => {
			translate.setDefaultLang('it');
			const browserLang: string = translate.getBrowserLang();
			const langToSet = browserLang.match(/en|it/) ? browserLang : 'it'
			translate.use(langToSet).subscribe(() => {
			}, () => {
				console.error(`Problem with '${langToSet}' language initialization.'`);
			}, () => {
				resolve(null);
			});
		});
	});
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		RoutingModule,
		FlexLayoutModule,
		NgbModalModule.forRoot(),
		Ng5BreadcrumbModule.forRoot(),
		TourMatMenuModule.forRoot(),
		PerfectScrollbarModule,
		MenuToggleModule,
		HttpClientModule,
		MatSlideToggleModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		AngularFireModule.initializeApp(firebaseConfig),
		AngularFireAuthModule,
		MatButtonModule,
		MatCardModule,
		MatMenuModule,
		MatToolbarModule,
		MatIconModule,
		MatBadgeModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatExpansionModule,
		MatSelectModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatChipsModule,
		MatListModule,
		MatSidenavModule,
		MatTabsModule,
		MatProgressBarModule,
		MatCheckboxModule,
		MatSliderModule,
		MatRadioModule,
		MatDialogModule,
		MatGridListModule,
		ToastrModule.forRoot(),
		WidgetComponentModule,
		LoadingBarRouterModule,
		LoadingBarRouterModule,
		DxDataGridModule,
		DxButtonModule,
		DxFormModule,
		DxFileUploaderModule,
		AuthenticationModule,
		MtLoadingModule,
		SessionModule,
		DxDateBoxModule,
		QuillModule.forRoot(),
		MatAutocompleteModule,
		NgxHeremapsGeocodingModule.forRoot({
      app_code: environment.hereMapsAppCode,
      app_id: environment.hereMapsAppId
    })
	],
	declarations: [
		GeneAppComponent,
		MainComponent,
		SideBarComponent, 
		FooterComponent,
		UserComponent,
		GridUserComponent,
		FormUserComponent,
		BlobImageUrlPipe,
		CatalogueComponent,
		GridCatalogueComponent,
		FormCatalogueComponent,
		MagazineComponent,
		GridMagazineComponent,
		FormMagazineComponent,
		MagazineLocalizationComponent,
		GridMagazineLocalizationComponent,
		FormMagazineLocalizationComponent,
		VersionComponent,
		GridVersionComponent,
		FormVersionComponent,
		InfoComponent,
		FormInfoComponent,
		ViewInfoComponent,
		FormPasswordComponent,
		TestMapComponent,
		LeafletMapComponent,
		GridBaseComponent,
		NewsComponent,
		GridNewsComponent,
		FormNewsComponent,
		LocationComponent,
		GridLocationComponent,
		FormLocationComponent,
		TextBoxMultilanguageComponent,
		TextAreaMultilanguageComponent,
		PaperCopyRequestComponent,
		GridPaperCopyRequestComponent,
		HtmlEditorMultilanguageComponent,
		FormProcessPaperCopyRequestComponent,
		GeoSearchComponent
	],
	bootstrap: [GeneAppComponent],
	entryComponents: [FormPasswordComponent],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true
		},
		MenuItems,
		BreadcrumbService,
		PageTitleService,
		AuthService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		},
		AuthGuard,
		UtilsService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		},
		UserService,
		CatalogueService,
		DxService,
		LoadingService,
		VersionService,
		MagazineService,
		MagazineLocalizationService,
		NewsService,
		AppApiService,
		LocationService
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GeneAppModule { }
