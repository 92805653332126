import { Injectable } from "@angular/core";
import { ApiService } from "app/common/api.service";
import { CrudApiService } from "app/common/crud-api.service";
import { RequestService } from "app/common/request.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MagazineService extends CrudApiService {
  constructor(protected requestService: RequestService, protected apiService: ApiService) {
    super(requestService, apiService)
    this.target = 'magazine'
  }

  getLanguageList(id: number): Observable<any[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target, String(id), 'language'));
  }

  // insert(magazine: any) {
  //   // TODO: implement
  //   // catalogue.pdf_link_id = !!catalogue.pdf_link_id ? catalogue.pdf_link_id.toLowerCase() : catalogue.pdf_link_id;
  //   // return this.requestService.post(this.apiService.createUrlFromBaseUrl(this.target), JSON.stringify(catalogue));
  // }

  // update(magazine: any, id: string) {
  //   // TODO: implement
  //   // catalogue.pdf_link_id = !!catalogue.pdf_link_id ? catalogue.pdf_link_id.toLowerCase() : catalogue.pdf_link_id;
  //   // return this.requestService.put(this.apiService.createUrlFromBaseUrl(this.target, id), JSON.stringify(catalogue));
  // }


}
