import { UtilsService } from './../../common/utils.service';
import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'app/common/loading.service';

@Component({
  selector: 'ms-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  constructor(private utilsService: UtilsService,
    public loadingService: LoadingService
    ) { }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('INFO')
  }

}
