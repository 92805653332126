import { UtilsService } from 'app/common/utils.service';
import { Info, InfoService } from './../info.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { LoadingService } from 'app/common/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ms-view-info',
  templateUrl: './view-info.component.html',
  styleUrls: ['./view-info.component.scss']
})
export class ViewInfoComponent implements OnInit {

  info$: Observable<Info>;

  constructor (
    private router: Router,
    private infoService: InfoService,
    private loadingService: LoadingService,
    private utils: UtilsService) {
  }

  ngOnInit() {
    this.info$ = this.loadingService.apiRequest$WithLoading(this.infoService.get()).map(info => {
      info.name = this.utils.setViewOfMultiLanguageField(info.name);
      if (!!info.description) { info.description = this.utils.setViewOfMultiLanguageField(info.description); }
      return info;
    });
  }

  goToEdit() {
    this.router.navigate(['info', 'edit']);
  }

}
