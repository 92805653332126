"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".gene-user-section .login-logo img {\n  margin-bottom: 1.5rem;\n}\n.gene-user-section button {\n  margin-bottom: 1rem;\n}\n.gene-user-section .mat-card {\n  padding-bottom: 2rem;\n  padding-top: 2rem;\n}\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbGliL2plbmtpbnMvd29ya3NwYWNlL2Zhc3NpLWNtcy1kZXYvc3JjL2FwcC9zZXNzaW9uL2xvY2tzY3JlZW5WMi9sb2Nrc2NyZWVuVjItY29tcG9uZW50LnNjc3MiLCJzcmMvYXBwL3Nlc3Npb24vbG9ja3NjcmVlblYyL2xvY2tzY3JlZW5WMi1jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFUTtFQUNJLHFCQUFBO0FDRFo7QURJSTtFQUNJLG1CQUFBO0FDRlI7QURJSTtFQUNJLG9CQUFBO0VBQ0EsaUJBQUE7QUNGUiIsImZpbGUiOiJzcmMvYXBwL3Nlc3Npb24vbG9ja3NjcmVlblYyL2xvY2tzY3JlZW5WMi1jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5nZW5lLXVzZXItc2VjdGlvbntcbiAgICAubG9naW4tbG9nb3tcbiAgICAgICAgaW1ne1xuICAgICAgICAgICAgbWFyZ2luLWJvdHRvbTogMS41cmVtO1xuICAgICAgICB9XG4gICAgfVxuICAgIGJ1dHRvbntcbiAgICAgICAgbWFyZ2luLWJvdHRvbTogMXJlbTtcbiAgICB9XG4gICAgLm1hdC1jYXJke1xuICAgICAgICBwYWRkaW5nLWJvdHRvbTogMnJlbTtcbiAgICAgICAgcGFkZGluZy10b3A6IDJyZW07XG4gICAgfVxufSIsIi5nZW5lLXVzZXItc2VjdGlvbiAubG9naW4tbG9nbyBpbWcge1xuICBtYXJnaW4tYm90dG9tOiAxLjVyZW07XG59XG4uZ2VuZS11c2VyLXNlY3Rpb24gYnV0dG9uIHtcbiAgbWFyZ2luLWJvdHRvbTogMXJlbTtcbn1cbi5nZW5lLXVzZXItc2VjdGlvbiAubWF0LWNhcmQge1xuICBwYWRkaW5nLWJvdHRvbTogMnJlbTtcbiAgcGFkZGluZy10b3A6IDJyZW07XG59Il19 */"];
exports.styles = styles;
