import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'ms-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent {
  map?: L.Map;
  marker?: L.Marker
  lat: number;
  lng: number;
  @Output() latitudeChange = new EventEmitter<number>();
  @Output() longitudeChange = new EventEmitter<number>();

  constructor() {
    this.lat = null;
    this.lng = null;
    this.initMap();
  }

  initMap() {
    try {
      if (!this.lat || !this.lng) { return; }
      const curLocation: L.LatLng = new L.LatLng(this.lat, this.lng);
      if (this.map == null) {
        this.map = L.map('MapLocation').setView(curLocation, 13);
        this.loadMap();
      }
      if (this.marker != null) {
        this.marker.setLatLng(curLocation);
        this.map.panTo(curLocation);
      } else {
        this.addMarker(curLocation);
      }
    } catch (e) { }
  }

  async loadMap() {
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    this.map.attributionControl.setPrefix(false);
  }

  addMarker(inputMarker: L.LatLng) {
    const icon = L.icon({
      iconUrl: '/assets/img/leaflet/marker-icon.png',
      shadowUrl: '/assets/img/leaflet/marker-shadow.png',
      iconSize: [24, 41],
      iconAnchor: [12, 41]
    });
    this.marker = L.marker(inputMarker, { draggable: true, icon: icon });
    this.marker.on('dragend', () => {
      const position = this.marker.getLatLng();
      this.setLatitude(position.lat);
      this.setLongitude(position.lng);
    });
    this.map.addLayer(this.marker);
  }

  @Input()
  get latitude() {
    return this.lat;
  }

  set latitude(val: number) {
    this.setLatitude(val);
    this.initMap();
  }

  setLatitude(val: number) {
    this.lat = val;
    this.latitudeChange.emit(this.lat);
  }

  @Input()
  get longitude() {
    return this.lng;
  }

  set longitude(val: number) {
    this.setLongitude(val);
    this.initMap();
  }

  setLongitude(val: number) {
    this.lng = val;
    this.longitudeChange.emit(this.lng);
  }
}
