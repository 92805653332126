"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var devextreme_angular_1 = require("devextreme-angular");
var rxjs_1 = require("rxjs");
var router_1 = require("@angular/router");
var config_service_1 = require("app/common/config.service");
var loading_service_1 = require("app/common/loading.service");
var user_service_1 = require("../user.service");
var grid_base_component_1 = require("app/classes/grid-base.component");
var utils_service_1 = require("app/common/utils.service");
var GridUserComponent = /** @class */ (function (_super) {
    __extends(GridUserComponent, _super);
    function GridUserComponent(router, configService, utils, loadingService, userService) {
        var _this = _super.call(this, configService) || this;
        _this.router = router;
        _this.configService = configService;
        _this.utils = utils;
        _this.loadingService = loadingService;
        _this.userService = userService;
        return _this;
    }
    GridUserComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadData();
        this.linkButtonClick = this.linkButtonClick.bind(this);
    };
    GridUserComponent.prototype.linkButtonClick = function (e) {
        this.router.navigate(['users', e.row.data.id]);
    };
    GridUserComponent.prototype.loadData = function () {
        this.users = this.loadingService.apiRequest$WithLoading(rxjs_1.zip(this.userService.getList(), this.configService.translatedRoleDict())).map(function (array) {
            var users = array[0];
            var roles = array[1];
            return users.map(function (user) {
                user.role = user.role.toUpperCase();
                user.role = roles[user.role];
                return user;
            });
        });
    };
    GridUserComponent.prototype.onToolbarPreparing = function (e) {
        var _this = this;
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: function () {
                    _this.loadData();
                }
            }
        }, {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'add',
                onClick: function () {
                    _this.router.navigate(['users/add']);
                }
            }
        });
    };
    return GridUserComponent;
}(grid_base_component_1.GridBaseComponent));
exports.GridUserComponent = GridUserComponent;
