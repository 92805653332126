"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./mt-loading.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../node_modules/@ngx-loading-bar/core/ngx-loading-bar-core.ngfactory");
var i3 = require("@ngx-loading-bar/core");
var i4 = require("./mt-loading.component");
var styles_MtLoadingComponent = [i0.styles];
var RenderType_MtLoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MtLoadingComponent, data: {} });
exports.RenderType_MtLoadingComponent = RenderType_MtLoadingComponent;
function View_MtLoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-loading-bar", [], [[2, "loading-bar-fixed", null]], null, null, i2.View_LoadingBarComponent_0, i2.RenderType_LoadingBarComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoadingBarComponent, [i3.LoadingBarService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).fixed; _ck(_v, 0, 0, currVal_0); }); }
exports.View_MtLoadingComponent_0 = View_MtLoadingComponent_0;
function View_MtLoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ms-mt-loading", [], null, null, null, View_MtLoadingComponent_0, RenderType_MtLoadingComponent)), i1.ɵdid(1, 770048, null, 0, i4.MtLoadingComponent, [i3.LoadingBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MtLoadingComponent_Host_0 = View_MtLoadingComponent_Host_0;
var MtLoadingComponentNgFactory = i1.ɵccf("ms-mt-loading", i4.MtLoadingComponent, View_MtLoadingComponent_Host_0, { show: "show" }, {}, []);
exports.MtLoadingComponentNgFactory = MtLoadingComponentNgFactory;
