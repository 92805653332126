import {Injectable} from '@angular/core';
import {Http, Headers, Response, RequestOptions} from '@angular/http';
import {Observable, of} from 'rxjs';
import 'rxjs/add/operator/map'
import {environment} from '../../environments/environment';
import {switchMap, tap} from 'rxjs/operators';

const headers = new Headers({'Content-Type': 'application/json'});
const options = new RequestOptions({headers: headers});

export interface CurrentUser {
  id: number;
  name: string;
  token: string;
  accessToken: string;
}

@Injectable()
export class AuthenticationService {

  constructor(private http: Http) {
  }

  login(email: string, password: string): Observable<boolean> {
    return this.apiLogin(email, password)
      .pipe(
        switchMap(
          res => {
            const token = res.token;
            return !token ? of(null) : this.apiMe(res);
          }
        )
      )
      .map(res => {
        console.log(`token : ` + res.token);
        console.log(res);
        localStorage.setItem('currentUser', JSON.stringify(res));
        return !!res
      })
  }

  apiLogin(email: string, password: string): Observable<any> {
    return this.http.post(environment.baseUrl + '/auth/login', JSON.stringify({email: email, password: password}), options)
      .map((response: Response) => response.json());
  }

  apiRefresh(): Observable<any> {
    const user: any = this.getUser();
    const refreshToken = user.refreshToken;
    return this.http.post(environment.baseUrl + '/auth/refresh', JSON.stringify({refreshToken: refreshToken}), options)
      .map((response: Response) => response.json()).pipe(tap(res => {
        const currentUser = this.getUser();
        const newCurrentUser = Object.assign(currentUser, res);
        localStorage.setItem('currentUser', JSON.stringify(newCurrentUser));
      }));
  }

  apiMe(res: any): Observable<any> {
    const {token, refreshToken} = res;
    const headers = new Headers({'Authorization': `Bearer ${token}`});
    const options = new RequestOptions({headers: headers});
    return this.http.get(environment.baseUrl + '/user/me', options)
      .map((response: Response) => Object.assign(res, response.json()));
  }

  logout(): void {
    localStorage.removeItem('currentUser');
  }

  getUser(): CurrentUser {
    const user = localStorage.getItem('currentUser');
    if (!user) {
      return null
    }
    return JSON.parse(user)
  }

  getRole(): string {
    const currentUser = this.getUser() as any
    return currentUser.role || 'USER'
  }

  isAdmin(): boolean {
    return this.getRole().toUpperCase() === 'ADMIN'
  }
}
