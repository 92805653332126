"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var ApiService = /** @class */ (function () {
    function ApiService() {
        this.baseUrl = environment_1.environment.baseUrl;
    }
    ApiService.prototype.createUrlFromBaseUrl = function () {
        var elements = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            elements[_i] = arguments[_i];
        }
        return this.createUrlFromUrl(this.baseUrl, elements);
    };
    ApiService.prototype.createUrlFromUrl = function (url, elements) {
        elements.unshift(url);
        return elements.join('/');
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
exports.ApiService = ApiService;
