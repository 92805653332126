"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var utils_service_1 = require("app/common/utils.service");
var loading_service_1 = require("app/common/loading.service");
var NewsComponent = /** @class */ (function () {
    function NewsComponent(utils, loadingService) {
        this.utils = utils;
        this.loadingService = loadingService;
    }
    NewsComponent.prototype.ngOnInit = function () {
        this.utils.setPageTitleByTranslateKey('NEWS');
    };
    return NewsComponent;
}());
exports.NewsComponent = NewsComponent;
