"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var version_service_1 = require("../version.service");
var dispose_bag_class_1 = require("../../../classes/dispose-bag.class");
var router_1 = require("@angular/router");
var dx_service_1 = require("../../../common/dx.service");
var loading_service_1 = require("../../../common/loading.service");
var catalogue_service_1 = require("app/components/catalogue/catalogue.service");
var log_service_1 = require("app/common/log.service");
var config_service_1 = require("app/common/config.service");
var grid_base_component_1 = require("app/classes/grid-base.component");
var GridVersionComponent = /** @class */ (function (_super) {
    __extends(GridVersionComponent, _super);
    function GridVersionComponent(configService, router, versionService, catalogueService, dxService, loadingService, logger) {
        var _this = _super.call(this, configService) || this;
        _this.configService = configService;
        _this.router = router;
        _this.versionService = versionService;
        _this.catalogueService = catalogueService;
        _this.dxService = dxService;
        _this.loadingService = loadingService;
        _this.logger = logger;
        _this.disposeBag = new dispose_bag_class_1.DisposeBag();
        _this.editButtonClick = _this.editButtonClick.bind(_this);
        _this.removeButtonClick = _this.removeButtonClick.bind(_this);
        _this.setCurrentButtonClick = _this.setCurrentButtonClick.bind(_this);
        _this._catalogueId = null;
        return _this;
    }
    GridVersionComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    GridVersionComponent.prototype.ngOnChanges = function (changes) {
        if (!changes || !changes.catalogueId || !changes.catalogueId.currentValue) {
            return;
        }
        var catalogueId = changes.catalogueId.currentValue;
        if (catalogueId === 0) {
            return;
        }
        this._catalogueId = catalogueId;
        this.loadData();
    };
    GridVersionComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    GridVersionComponent.prototype.onToolbarPreparing = function (e) {
        var _this = this;
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'back',
                onClick: function () { return _this.router.navigate(['catalogue']); }
            }
        }, {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: function () { return _this.loadData(); }
            }
        }, {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'add',
                onClick: function () {
                    if (!_this._catalogueId) {
                        return;
                    }
                    _this.router.navigate(['catalogue', _this._catalogueId, 'version', 'add']);
                }
            }
        });
    };
    GridVersionComponent.prototype.editButtonClick = function (e) {
        this.router.navigate(['catalogue', this._catalogueId, 'version', e.row.data.id]);
    };
    GridVersionComponent.prototype.removeButtonClick = function (e) {
        var _this = this;
        if (!this.isNotCurrent(e)) {
            return;
        }
        this.disposeBag.add(this.askConfirmation(this.versionService.delete(e.row.data.id)).subscribe(function () { return _this.loadData(); }, function () { return _this.logger.serverError(); }));
    };
    GridVersionComponent.prototype.isNotCurrent = function (e) {
        return !e.row.data.current;
    };
    GridVersionComponent.prototype.setCurrentButtonClick = function (e) {
        var _this = this;
        return this.disposeBag.add(this.askConfirmation(this.versionService.setAsCurrent(e.row.data.id)).subscribe(function () { return _this.loadData(); }, function () { return _this.logger.serverError(); }));
    };
    GridVersionComponent.prototype.downloadFile = function (url, name) {
        this.versionService.dowloadFile(url, name + ".pdf");
    };
    GridVersionComponent.prototype.askConfirmation = function (observable$) {
        return this.loadingService.apiRequest$WithLoading(this.dxService.dxConfirmWithSuccessfulObservable('WARNING', 'ARE YOU SURE', observable$));
    };
    GridVersionComponent.prototype.loadData = function () {
        if (!this._catalogueId) {
            return;
        }
        this.versions$ = this.loadingService.apiRequest$WithLoading(this.catalogueService.getVersionList(this._catalogueId));
    };
    return GridVersionComponent;
}(grid_base_component_1.GridBaseComponent));
exports.GridVersionComponent = GridVersionComponent;
