"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var crud_api_service_1 = require("../../common/crud-api.service");
var request_service_1 = require("../../common/request.service");
var api_service_1 = require("../../common/api.service");
var i0 = require("@angular/core");
var i1 = require("../../common/request.service");
var i2 = require("../../common/api.service");
var VersionService = /** @class */ (function (_super) {
    __extends(VersionService, _super);
    function VersionService(requestService, apiService) {
        var _this = _super.call(this, requestService, apiService) || this;
        _this.requestService = requestService;
        _this.apiService = apiService;
        _this.target = 'version';
        return _this;
    }
    VersionService.prototype.save = function (version, file) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(version));
        formData.append('files', file);
        return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
    };
    VersionService.prototype.dowloadFile = function (url, name) {
        this.requestService.downloadFile(url, {}, '*/pdf', name);
    };
    VersionService.prototype.setAsCurrent = function (id) {
        var url = this.apiService.createUrlFromBaseUrl(this.target, "" + id, 'current');
        return this.requestService.put(url, {});
    };
    VersionService.prototype.updateName = function (id, name) {
        var url = this.apiService.createUrlFromBaseUrl(this.target, "" + id, 'name');
        return this.requestService.put(url, { name: name });
    };
    VersionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VersionService_Factory() { return new VersionService(i0.ɵɵinject(i1.RequestService), i0.ɵɵinject(i2.ApiService)); }, token: VersionService, providedIn: "root" });
    return VersionService;
}(crud_api_service_1.CrudApiService));
exports.VersionService = VersionService;
var Version = /** @class */ (function () {
    function Version() {
    }
    return Version;
}());
exports.Version = Version;
