"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var devextreme_angular_1 = require("devextreme-angular");
var TextBoxMultilanguageComponent = /** @class */ (function () {
    function TextBoxMultilanguageComponent() {
        this.modelChange = new core_1.EventEmitter();
        this.isValidChange = new core_1.EventEmitter();
        this.formData = {};
        this._isValid = false;
    }
    TextBoxMultilanguageComponent.prototype.ngOnInit = function () {
    };
    TextBoxMultilanguageComponent.prototype.ngOnChanges = function (changes) {
        if (!changes || !changes.model) {
            return;
        }
        var model = changes.model;
        if (model.currentValue !== '{}') {
            this.formData = JSON.parse(model.currentValue);
        }
        this.setIsValid(this.isFormValid());
    };
    TextBoxMultilanguageComponent.prototype.onFieldDataChanged = function (e) {
        this.setModel(this.formData);
    };
    Object.defineProperty(TextBoxMultilanguageComponent.prototype, "model", {
        get: function () {
            return this._model;
        },
        set: function (val) {
            this.setModel(val);
        },
        enumerable: true,
        configurable: true
    });
    TextBoxMultilanguageComponent.prototype.setModel = function (val) {
        this._model = val;
        this.modelChange.emit(JSON.stringify(this.formData));
        this.setIsValid(this.isFormValid());
    };
    TextBoxMultilanguageComponent.prototype.validate = function () {
        if (!this.dxForm || !this.dxForm.instance) {
            return { isValid: false };
        }
        return this.dxForm.instance.validate();
    };
    TextBoxMultilanguageComponent.prototype.isFormValid = function () {
        if (!this.isRequired) {
            return true;
        }
        var result = this.validate();
        var isFormValid = result.isValid;
        return isFormValid;
    };
    Object.defineProperty(TextBoxMultilanguageComponent.prototype, "isValid", {
        get: function () {
            return this._isValid;
        },
        set: function (val) {
            this.setIsValid(val);
        },
        enumerable: true,
        configurable: true
    });
    TextBoxMultilanguageComponent.prototype.setIsValid = function (val) {
        this._isValid = val;
        this.isValidChange.emit(this._isValid);
    };
    return TextBoxMultilanguageComponent;
}());
exports.TextBoxMultilanguageComponent = TextBoxMultilanguageComponent;
