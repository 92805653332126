"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var i0 = require("@angular/core");
var LoadingService = /** @class */ (function () {
    function LoadingService() {
        this.loading = new rxjs_1.Subject();
        this.loading$ = this.loading.asObservable().pipe(operators_1.delay(0));
        this.progress = new rxjs_1.Subject();
        this.progress$ = this.progress.asObservable().pipe(operators_1.delay(0));
    }
    LoadingService.prototype.updateLoading = function (loading) {
        this.loading.next(loading);
    };
    LoadingService.prototype.apiRequest$WithLoading = function (apiRequest$) {
        var _this = this;
        this.updateLoading(true);
        return apiRequest$.pipe(operators_1.finalize(function () { _this.updateLoading(false); }));
    };
    LoadingService.prototype.apiRequest$WithLoadingAndProgress = function (apiRequest$) {
        var _this = this;
        this.updateLoading(true);
        return apiRequest$
            .pipe(operators_1.tap(function (ev) { return _this.handleProgress(ev); }), operators_1.finalize(function () { _this.updateLoading(false); }))
            .filter(function (event) { return event instanceof http_1.HttpResponse; });
    };
    LoadingService.prototype.handleProgress = function (event) {
        if (event.loaded < event.total) {
            var percentDone = Math.round(100 * event.loaded / event.total);
            this.updateProgress(percentDone);
        }
        else {
            this.updateProgress(0);
        }
    };
    LoadingService.prototype.updateProgress = function (progress) {
        this.progress.next(progress);
    };
    LoadingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadingService_Factory() { return new LoadingService(); }, token: LoadingService, providedIn: "root" });
    return LoadingService;
}());
exports.LoadingService = LoadingService;
