"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/http");
var rxjs_1 = require("rxjs");
require("rxjs/add/operator/map");
var environment_1 = require("../../environments/environment");
var operators_1 = require("rxjs/operators");
var headers = new http_1.Headers({ 'Content-Type': 'application/json' });
var options = new http_1.RequestOptions({ headers: headers });
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http) {
        this.http = http;
    }
    AuthenticationService.prototype.login = function (email, password) {
        var _this = this;
        return this.apiLogin(email, password)
            .pipe(operators_1.switchMap(function (res) {
            var token = res.token;
            return !token ? rxjs_1.of(null) : _this.apiMe(res);
        }))
            .map(function (res) {
            console.log("token : " + res.token);
            console.log(res);
            localStorage.setItem('currentUser', JSON.stringify(res));
            return !!res;
        });
    };
    AuthenticationService.prototype.apiLogin = function (email, password) {
        return this.http.post(environment_1.environment.baseUrl + '/auth/login', JSON.stringify({ email: email, password: password }), options)
            .map(function (response) { return response.json(); });
    };
    AuthenticationService.prototype.apiRefresh = function () {
        var _this = this;
        var user = this.getUser();
        var refreshToken = user.refreshToken;
        return this.http.post(environment_1.environment.baseUrl + '/auth/refresh', JSON.stringify({ refreshToken: refreshToken }), options)
            .map(function (response) { return response.json(); }).pipe(operators_1.tap(function (res) {
            var currentUser = _this.getUser();
            var newCurrentUser = Object.assign(currentUser, res);
            localStorage.setItem('currentUser', JSON.stringify(newCurrentUser));
        }));
    };
    AuthenticationService.prototype.apiMe = function (res) {
        var token = res.token, refreshToken = res.refreshToken;
        var headers = new http_1.Headers({ 'Authorization': "Bearer " + token });
        var options = new http_1.RequestOptions({ headers: headers });
        return this.http.get(environment_1.environment.baseUrl + '/user/me', options)
            .map(function (response) { return Object.assign(res, response.json()); });
    };
    AuthenticationService.prototype.logout = function () {
        localStorage.removeItem('currentUser');
    };
    AuthenticationService.prototype.getUser = function () {
        var user = localStorage.getItem('currentUser');
        if (!user) {
            return null;
        }
        return JSON.parse(user);
    };
    AuthenticationService.prototype.getRole = function () {
        var currentUser = this.getUser();
        return currentUser.role || 'USER';
    };
    AuthenticationService.prototype.isAdmin = function () {
        return this.getRole().toUpperCase() === 'ADMIN';
    };
    return AuthenticationService;
}());
exports.AuthenticationService = AuthenticationService;
