"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MENUITEMS = [
    {
        state: 'users',
        name: 'USERS',
        type: 'link',
        icon: 'group'
    },
    {
        state: 'catalogue',
        name: 'CATALOGUES',
        type: 'link',
        icon: 'note'
    },
    {
        state: 'magazine',
        name: 'MAGAZINES',
        type: 'link',
        icon: 'newspaper'
    },
    {
        state: 'paper_copy_request',
        name: 'COPY REQUEST',
        type: 'link',
        icon: 'receipt'
    },
    {
        state: 'location',
        name: 'LOCATIONS',
        type: 'link',
        icon: 'place'
    },
    {
        state: 'news',
        name: 'NEWS',
        type: 'link',
        icon: 'featured_play_list'
    },
    {
        state: 'info',
        name: 'INFO',
        type: 'link',
        icon: 'info'
    }
];
var MenuItems = /** @class */ (function () {
    function MenuItems() {
    }
    MenuItems.prototype.getAll = function () {
        return MENUITEMS;
    };
    MenuItems.prototype.add = function (menu) {
        MENUITEMS.push(menu);
    };
    return MenuItems;
}());
exports.MenuItems = MenuItems;
