"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["#form_actions[_ngcontent-%COMP%] {\n  margin-top: 20px;\n}\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbGliL2plbmtpbnMvd29ya3NwYWNlL2Zhc3NpLWNtcy1kZXYvc3JjL2FwcC9jb21wb25lbnRzL2NhdGFsb2d1ZS9mb3JtLWNhdGFsb2d1ZS9mb3JtLWNhdGFsb2d1ZS5jb21wb25lbnQuc2NzcyIsInNyYy9hcHAvY29tcG9uZW50cy9jYXRhbG9ndWUvZm9ybS1jYXRhbG9ndWUvZm9ybS1jYXRhbG9ndWUuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxnQkFBQTtBQ0NGIiwiZmlsZSI6InNyYy9hcHAvY29tcG9uZW50cy9jYXRhbG9ndWUvZm9ybS1jYXRhbG9ndWUvZm9ybS1jYXRhbG9ndWUuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIjZm9ybV9hY3Rpb25zIHtcbiAgbWFyZ2luLXRvcDogMjBweDtcbn0iLCIjZm9ybV9hY3Rpb25zIHtcbiAgbWFyZ2luLXRvcDogMjBweDtcbn0iXX0= */"];
exports.styles = styles;
