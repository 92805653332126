import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GeocodingResult, NgxHeremapsGeocodingService } from 'ngx-heremaps-geocoding';
import { Observable } from 'rxjs';
import { debounceTime, flatMap } from 'rxjs/operators';

@Component({
  selector: 'ms-geo-search',
  templateUrl: './geo-search.component.html',
  styleUrls: ['./geo-search.component.scss']
})
export class GeoSearchComponent implements OnInit {
  @Output() selection: EventEmitter<any> = new EventEmitter<any>();
  searchBox: FormControl = new FormControl();
  options: Observable<GeocodingResult[]>;

  constructor(private geocoder: NgxHeremapsGeocodingService) { }

  ngOnInit() {
    this.options = this.searchBox.valueChanges.pipe(
      debounceTime(200),
      flatMap(address => this.geocoder.search(address))
    );
  }

  clearSearch() {
    this.searchBox.setValue('');
  }

  onAddressSelected(event?: any): string {
    return !!event ? event.formattedAddress : undefined;
  }

  updateSelection(event: any) {
    this.selection.emit(event);
  }
}
