"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".field-required-error[_ngcontent-%COMP%] {\n  color: red;\n  margin-top: 4px;\n}\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbGliL2plbmtpbnMvd29ya3NwYWNlL2Zhc3NpLWNtcy1kZXYvc3JjL2FwcC9jb21wb25lbnRzL2luZm8vZm9ybS1pbmZvL2Zvcm0taW5mby5jb21wb25lbnQuc2NzcyIsInNyYy9hcHAvY29tcG9uZW50cy9pbmZvL2Zvcm0taW5mby9mb3JtLWluZm8uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxVQUFBO0VBQ0EsZUFBQTtBQ0NGIiwiZmlsZSI6InNyYy9hcHAvY29tcG9uZW50cy9pbmZvL2Zvcm0taW5mby9mb3JtLWluZm8uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZmllbGQtcmVxdWlyZWQtZXJyb3Ige1xuICBjb2xvcjogcmVkO1xuICBtYXJnaW4tdG9wOiA0cHhcbn0iLCIuZmllbGQtcmVxdWlyZWQtZXJyb3Ige1xuICBjb2xvcjogcmVkO1xuICBtYXJnaW4tdG9wOiA0cHg7XG59Il19 */"];
exports.styles = styles;
