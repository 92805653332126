"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var ngx_heremaps_geocoding_1 = require("ngx-heremaps-geocoding");
var operators_1 = require("rxjs/operators");
var GeoSearchComponent = /** @class */ (function () {
    function GeoSearchComponent(geocoder) {
        this.geocoder = geocoder;
        this.selection = new core_1.EventEmitter();
        this.searchBox = new forms_1.FormControl();
    }
    GeoSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.options = this.searchBox.valueChanges.pipe(operators_1.debounceTime(200), operators_1.flatMap(function (address) { return _this.geocoder.search(address); }));
    };
    GeoSearchComponent.prototype.clearSearch = function () {
        this.searchBox.setValue('');
    };
    GeoSearchComponent.prototype.onAddressSelected = function (event) {
        return !!event ? event.formattedAddress : undefined;
    };
    GeoSearchComponent.prototype.updateSelection = function (event) {
        this.selection.emit(event);
    };
    return GeoSearchComponent;
}());
exports.GeoSearchComponent = GeoSearchComponent;
