"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".gene-forget-pass .login-logo img {\n  margin-bottom: 1.5rem;\n}\n.gene-forget-pass button {\n  margin-bottom: 1rem;\n}\n.gene-forget-pass .mat-card {\n  padding-bottom: 2rem;\n  padding-top: 2rem;\n}\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbGliL2plbmtpbnMvd29ya3NwYWNlL2Zhc3NpLWNtcy1kZXYvc3JjL2FwcC9zZXNzaW9uL2ZvcmdvdC1wYXNzd29yZC9mb3Jnb3QtcGFzc3dvcmQtY29tcG9uZW50LnNjc3MiLCJzcmMvYXBwL3Nlc3Npb24vZm9yZ290LXBhc3N3b3JkL2ZvcmdvdC1wYXNzd29yZC1jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFUTtFQUNJLHFCQUFBO0FDRFo7QURJSTtFQUNJLG1CQUFBO0FDRlI7QURJSTtFQUNJLG9CQUFBO0VBQ0EsaUJBQUE7QUNGUiIsImZpbGUiOiJzcmMvYXBwL3Nlc3Npb24vZm9yZ290LXBhc3N3b3JkL2ZvcmdvdC1wYXNzd29yZC1jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5nZW5lLWZvcmdldC1wYXNze1xuICAgIC5sb2dpbi1sb2dve1xuICAgICAgICBpbWd7XG4gICAgICAgICAgICBtYXJnaW4tYm90dG9tOiAxLjVyZW07XG4gICAgICAgIH1cbiAgICB9XG4gICAgYnV0dG9ue1xuICAgICAgICBtYXJnaW4tYm90dG9tOiAxcmVtO1xuICAgIH1cbiAgICAubWF0LWNhcmR7XG4gICAgICAgIHBhZGRpbmctYm90dG9tOiAycmVtO1xuICAgICAgICBwYWRkaW5nLXRvcDogMnJlbTtcbiAgICB9XG59IiwiLmdlbmUtZm9yZ2V0LXBhc3MgLmxvZ2luLWxvZ28gaW1nIHtcbiAgbWFyZ2luLWJvdHRvbTogMS41cmVtO1xufVxuLmdlbmUtZm9yZ2V0LXBhc3MgYnV0dG9uIHtcbiAgbWFyZ2luLWJvdHRvbTogMXJlbTtcbn1cbi5nZW5lLWZvcmdldC1wYXNzIC5tYXQtY2FyZCB7XG4gIHBhZGRpbmctYm90dG9tOiAycmVtO1xuICBwYWRkaW5nLXRvcDogMnJlbTtcbn0iXX0= */"];
exports.styles = styles;
