"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var auth_service_1 = require("../../service/auth-service/auth.service");
var core_1 = require("@ngx-translate/core");
var ForgotPasswordV2Component = /** @class */ (function () {
    function ForgotPasswordV2Component(authService, router, translate) {
        this.authService = authService;
        this.router = router;
        this.translate = translate;
    }
    /**
      * send method is used to send a reset password link into your email.
      */
    ForgotPasswordV2Component.prototype.send = function (value) {
        this.authService.resetPasswordV2(value);
    };
    return ForgotPasswordV2Component;
}());
exports.ForgotPasswordV2Component = ForgotPasswordV2Component;
