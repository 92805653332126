import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { DisposeBag } from "app/classes/dispose-bag.class";
import { GridBaseComponent } from "app/classes/grid-base.component";
import { ConfigService } from "app/common/config.service";
import { Observable } from "rxjs";
import { MagazineService } from "../../magazine.service";
import { DxService } from "app/common/dx.service";
import { LoadingService } from "app/common/loading.service";
import { LogService } from "app/common/log.service";
import { MagVersion, MagazineLocalizationService } from "../magazine-localization.service";

@Component({
  selector: 'ms-grid-magazine-localization',
  templateUrl: './grid-magazine-localization.component.html',
  styleUrls: ['./grid-magazine-localization.component.scss']
})
export class GridMagazineLocalizationComponent extends GridBaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() magazineId: number;
  _magazineId: number;
  languages$: Observable<MagVersion[]>;
  disposeBag = new DisposeBag();

  constructor(
    public configService: ConfigService,
    private router: Router,
    private magazineLanguageService: MagazineLocalizationService,
    private magazineService: MagazineService,
    private dxService: DxService,
    private loadingService: LoadingService,
    private logger: LogService) {
    super(configService);
    this.editButtonClick = this.editButtonClick.bind(this);
    this.removeButtonClick = this.removeButtonClick.bind(this);
    this._magazineId = null;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes || !changes.magazineId || !changes.magazineId.currentValue) { return; }
    const magazineId = changes.magazineId.currentValue
    if (magazineId === 0) { return; }
    this._magazineId = magazineId;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'back',
        onClick: () => this.router.navigate(['magazine'])
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: () => this.loadData()
      }
    }, {
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: () => {
          if (!this._magazineId) { return; }
          this.router.navigate(['magazine', this._magazineId, 'language', 'add']);
        }
      }
    }
    )
  }

  editButtonClick(e) {
    this.router.navigate(['magazine', this._magazineId, 'language', e.row.data.id]);
  }

  removeButtonClick(e) {
    if (!this.isNotCurrent(e)) { return; }
    this.disposeBag.add(this.askConfirmation(this.magazineLanguageService.delete(e.row.data.id)).subscribe(
      () => this.loadData(),
      () => this.logger.serverError()
    ));
  }

  isNotCurrent(e) {
    return !e.row.data.current;
  }

  downloadFile(url, name) {
    this.magazineLanguageService.downloadFile(url, `${name}.pdf`);
  }

  private askConfirmation(observable$: Observable<any>) {
    return this.loadingService.apiRequest$WithLoading(
      this.dxService.dxConfirmWithSuccessfulObservable('WARNING', 'ARE YOU SURE', observable$)
    );
  }

  private loadData() {
    if (!this._magazineId) { return; }
    this.languages$ = this.loadingService.apiRequest$WithLoading(this.magazineService.getLanguageList(this._magazineId));
  }
}
