import { LoadingService } from '../../common/loading.service';
import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'app/common/utils.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'ms-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {
  catalogueId$: Observable<number>;

  constructor(
    private utilsService: UtilsService,
    public loadingService: LoadingService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('CATALOGUE VERSIONS');
    this.catalogueId$ = this.route.params.map(params => +params['id']);
  }
}
