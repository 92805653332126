import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DxFormComponent } from 'devextreme-angular';

@Component({
  selector: 'ms-text-box-multilanguage',
  templateUrl: './text-box-multilanguage.component.html',
  styleUrls: ['./text-box-multilanguage.component.scss']
})
export class TextBoxMultilanguageComponent implements OnInit, OnChanges {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  _model: any;
  @Output() modelChange = new EventEmitter<string>();
  _isValid: boolean;
  @Output() isValidChange = new EventEmitter();
  formData: any;
  @Input() labelText: string;
  @Input() languages: any[];
  @Input() isRequired: boolean;

  constructor() {
    this.formData = {};
    this._isValid = false;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes || !changes.model) { return; }
    const model = changes.model;
    if (model.currentValue !== '{}') {
      this.formData = JSON.parse(model.currentValue);
    }
    this.setIsValid(this.isFormValid());
  }

  onFieldDataChanged(e) {
    this.setModel(this.formData);
  }

  @Input()
  get model() {
    return this._model;
  }

  set model(val) {
    this.setModel(val)
  }

  setModel(val) {
    this._model = val;
    this.modelChange.emit(JSON.stringify(this.formData));
    this.setIsValid(this.isFormValid());
  }

  validate() {
    if (!this.dxForm || !this.dxForm.instance) { return { isValid: false }; }
    return this.dxForm.instance.validate();
  }

  isFormValid() {
    if (!this.isRequired) { return true; }
    const result = this.validate();
    const isFormValid = result.isValid;
    return isFormValid;
  }

  @Input()
  get isValid() {
    return this._isValid;
  }

  set isValid(val) {
    this.setIsValid(val)
  }

  setIsValid(val) {
    this._isValid = val;
    this.isValidChange.emit(this._isValid)
  }

}
