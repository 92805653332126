"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config_service_1 = require("app/common/config.service");
var app_api_service_1 = require("./../../../common/app-api.service");
var dx_service_1 = require("./../../../common/dx.service");
var devextreme_angular_1 = require("devextreme-angular");
var router_1 = require("@angular/router");
var loading_service_1 = require("../../../common/loading.service");
var core_1 = require("@angular/core");
var catalogue_service_1 = require("../catalogue.service");
var utils_service_1 = require("../../../common/utils.service");
var rxjs_1 = require("rxjs");
var router_2 = require("@angular/router");
var dispose_bag_class_1 = require("../../../classes/dispose-bag.class");
var operators_1 = require("rxjs/operators");
var englishCode = 'EN';
var FormCatalogueComponent = /** @class */ (function () {
    function FormCatalogueComponent(router, route, utilsService, dxService, appApiService, catalogueService, loadingService, configService) {
        this.router = router;
        this.route = route;
        this.utilsService = utilsService;
        this.dxService = dxService;
        this.appApiService = appApiService;
        this.catalogueService = catalogueService;
        this.loadingService = loadingService;
        this.configService = configService;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
        this.patternRules = '^[a-zA-Z0-9]*$';
        this.isFormInvalid = true;
    }
    FormCatalogueComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.utilsService.setPageTitleByTranslateKey('CATALOGUES');
        this.setEmptyData();
        var categories$ = this.appApiService.getCategories().map(function (categories) { return categories.map(function (category) {
            for (var _i = 0, _a = category.extended_name.split('/'); _i < _a.length; _i++) {
                var name_1 = _a[_i];
                category.extendedName = !category.extendedName ? _this.utilsService.setViewOfMultiLanguageField(name_1) :
                    category.extendedName + "/" + _this.utilsService.setViewOfMultiLanguageField(name_1);
            }
            return category;
        }); });
        this.categoryEditorOptions$ = this.dxService.dxiItemEditorOptions(categories$, this.catalogue.category_id, 'extendedName', 'id');
        this.umEditorOptions$ = this.dxService.dxiItemEditorOptions(rxjs_1.Observable.of(this.configService.getUmDict()), this.catalogue.um, 'text', 'id');
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(rxjs_1.zip(this.getCatalogue(), this.appApiService.getLanguages())).subscribe(function (array) {
            var catalogue = array[0];
            var languages = array[1];
            _this.catalogue = catalogue;
            if (_this.catalogue.public == null) {
                _this.catalogue.public = false;
            }
            languages.map(function (langauge) {
                if (langauge.code === englishCode) {
                    _this.catalogue.language_id = langauge.id;
                }
            });
            if (!!catalogue.id) {
                _this.dxForm.instance.updateData(catalogue);
            }
        }));
    };
    FormCatalogueComponent.prototype.setEmptyData = function () {
        this.catalogue = {};
    };
    FormCatalogueComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    FormCatalogueComponent.prototype.getCatalogue = function () {
        var _this = this;
        return this.route.params.map(function (params) { return params['id']; }).pipe(operators_1.tap(function (id) { return _this.id = String(id); })).switchMap(function (idFound, _) {
            if (idFound) {
                _this.cardTitle = 'MODIFY CATALOGUE';
                return _this.catalogueService.getOne(idFound);
            }
            _this.cardTitle = 'ADD CATALOGUE';
            return rxjs_1.of({});
        });
    };
    FormCatalogueComponent.prototype.getLanguage = function () {
        var _this = this;
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.appApiService.getLanguages()).subscribe(function (languages) {
            languages.map(function (language) {
                if (language.code === englishCode) {
                    _this.catalogue.language_id = language.id;
                }
            });
        }));
    };
    FormCatalogueComponent.prototype.onFieldDataChanged = function () {
        this.isFormInvalid = !this.isFormValid();
    };
    FormCatalogueComponent.prototype.onFormSubmit = function () {
        var observable$ = this.isNew() ? this.catalogueService.insert(this.catalogue) : this.catalogueService.update(this.catalogue, this.id);
        this.handleRequest(observable$);
    };
    FormCatalogueComponent.prototype.handleRequest = function (input) {
        var _this = this;
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(input).subscribe(function () { return _this.goToListPage(); }));
    };
    FormCatalogueComponent.prototype.isNew = function () {
        return this.id === 'undefined';
    };
    FormCatalogueComponent.prototype.validate = function () {
        if (!this.dxForm || !this.dxForm.instance) {
            return { isValid: false };
        }
        return this.dxForm.instance.validate();
    };
    FormCatalogueComponent.prototype.isFormValid = function () {
        var result = this.validate();
        return result.isValid;
    };
    FormCatalogueComponent.prototype.goToListPage = function () {
        this.router.navigate(['catalogue']);
    };
    return FormCatalogueComponent;
}());
exports.FormCatalogueComponent = FormCatalogueComponent;
