"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var page_title_service_1 = require("../../../core/page-title/page-title.service");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var user_service_1 = require("../user.service");
var utils_service_1 = require("../../../common/utils.service");
var config_service_1 = require("app/common/config.service");
var dispose_bag_class_1 = require("../../../classes/dispose-bag.class");
var loading_service_1 = require("../../../common/loading.service");
var authentication_service_1 = require("app/authentication/authentication.service");
var regEx = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$';
var FormUserComponent = /** @class */ (function () {
    function FormUserComponent(fb, pageTitleService, route, userService, router, utils, config, loadingService, authService) {
        this.fb = fb;
        this.pageTitleService = pageTitleService;
        this.route = route;
        this.userService = userService;
        this.router = router;
        this.utils = utils;
        this.config = config;
        this.loadingService = loadingService;
        this.authService = authService;
        this.loading = false;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
    }
    FormUserComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.utils.translate('UPDATE USER').subscribe(function (res) {
            _this.cardTitle = res;
        });
        this.utils.translate('USERS').subscribe(function (res) {
            _this.pageTitleService.setTitle(res);
        });
        this.disposeBag.add(this.loadingService.loading$.subscribe(function (res) { _this.loading = res; }));
        this.roles = this.config.getRolesDict();
        this.form = this.fb.group({
            name: [null, forms_1.Validators.compose([forms_1.Validators.required])],
            surname: [null, forms_1.Validators.compose([forms_1.Validators.required])],
            email: [null, forms_1.Validators.compose([forms_1.Validators.required])],
            role: [null, forms_1.Validators.compose([forms_1.Validators.required])],
            phone: [null],
            mobile: [null],
            enabled: [null, forms_1.Validators.compose([forms_1.Validators.required])],
            company: [null, forms_1.Validators.compose([forms_1.Validators.required])],
            password: [null, forms_1.Validators.compose([forms_1.Validators.pattern(regEx)])],
            confirm_password: [null, forms_1.Validators.compose([forms_1.Validators.pattern(regEx)])]
        }, {
            validator: this.checkPassword
        });
        this.route.params.subscribe(function (params) {
            _this.id = String(params['id']);
            if (!_this.isNew()) {
                _this.utils.translate('MODIFY THE USER').subscribe(function (res) {
                    _this.cardTitle = res;
                });
                _this.loadUserData();
            }
            else {
                _this.form.controls.enabled.setValue(false);
            }
        });
        this.route.queryParams.subscribe(function (params) {
            _this.page = params['page'] || 0;
        });
        this.currentUser = this.authService.getUser();
    };
    FormUserComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    FormUserComponent.prototype.loadUserData = function () {
        var _this = this;
        var id = this.id;
        this.loadingService.updateLoading(true);
        this.userService.getOne(id).subscribe(function (res) {
            _this.user = res;
            _this.objToForm(_this.user);
        }, function (err) { return console.log('Error occured : ' + err); }, function () { _this.loadingService.updateLoading(false); });
    };
    FormUserComponent.prototype.objToForm = function (user) {
        this.form.controls.name.setValue(user.name);
        this.form.controls.surname.setValue(user.surname);
        this.form.controls.email.setValue(user.email);
        this.form.controls.phone.setValue(user.phone);
        this.form.controls.mobile.setValue(user.mobile);
        this.form.controls.role.setValue(user.role);
        this.form.controls.enabled.setValue(user.enabled);
        this.form.controls.company.setValue(user.company);
    };
    FormUserComponent.prototype.formToObj = function () {
        var obj = {};
        if (this.user) {
            obj = this.user;
        }
        obj['name'] = this.form.controls.name.value;
        obj['surname'] = this.form.controls.surname.value;
        obj['email'] = this.form.controls.email.value;
        obj['phone'] = this.form.controls.phone.value;
        obj['mobile'] = this.form.controls.mobile.value;
        obj['role'] = this.form.controls.role.value;
        obj['enabled'] = this.form.controls.enabled.value;
        obj['company'] = this.form.controls.company.value;
        obj['password'] = this.form.controls.password.value;
        return obj;
    };
    FormUserComponent.prototype.isFormValid = function () {
        var isValid = this.form.valid;
        if (this.isNew()) {
            return isValid && !!this.form.controls.password.value && !!this.form.controls.confirm_password.value;
        }
        return isValid;
    };
    FormUserComponent.prototype.onSubmit = function () {
        if (!this.isFormValid()) {
            return;
        }
        var user = this.formToObj();
        this.isNew() ? this.handleRequest(this.userService.insertUser(user)) : this.handleRequest(this.userService.updateUser(user));
    };
    FormUserComponent.prototype.handleRequest = function (input) {
        var _this = this;
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(input).subscribe(function () { return _this.goToListPage(); }));
    };
    FormUserComponent.prototype.isNew = function () {
        return this.id === 'undefined';
    };
    FormUserComponent.prototype.goToListPage = function () {
        this.router.navigate(['users'], { queryParams: { page: this.page } });
    };
    FormUserComponent.prototype.setPassword = function () {
        this.utils.openPasswordDialog(this.id);
    };
    //TODO da centralizzare
    FormUserComponent.prototype.checkPassword = function (form) {
        return form.controls.password.value === form.controls.confirm_password.value ? null : { 'mismatch': true };
    };
    FormUserComponent.prototype.validatePassword = function () {
        return (this.form.controls.password.hasError('required') || this.form.controls.password.hasError('pattern'))
            && this.form.controls.password.touched;
    };
    FormUserComponent.prototype.matchPassword = function () {
        return !this.form.controls.password.hasError('pattern') && this.form.controls.password.value !== this.form.controls.confirm_password.value
            && this.form.controls.confirm_password.touched;
    };
    FormUserComponent.prototype.isMe = function () {
        if (!this.user || !this.currentUser) {
            return false;
        }
        return this.user.id === this.currentUser.id;
    };
    return FormUserComponent;
}());
exports.FormUserComponent = FormUserComponent;
