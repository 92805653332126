"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var menu_toggle_link_directive_1 = require("./menu-toggle-link.directive");
var MenuToggleAnchorDirective = /** @class */ (function () {
    function MenuToggleAnchorDirective(navlink) {
        this.navlink = navlink;
    }
    MenuToggleAnchorDirective.prototype.onClick = function (e) {
        this.navlink.toggle();
    };
    return MenuToggleAnchorDirective;
}());
exports.MenuToggleAnchorDirective = MenuToggleAnchorDirective;
