import { AppApiService } from './../../../common/app-api.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageTitleService } from '../../../core/page-title/page-title.service';
import { UtilsService } from '../../../common/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NewsService } from '../news.service';
import { LoadingService } from 'app/common/loading.service';
import { Observable, zip } from 'rxjs';
import { DisposeBag } from 'app/classes/dispose-bag.class';
import { DxFileUploaderComponent, DxFormComponent } from 'devextreme-angular';
import { LogService } from 'app/common/log.service';

@Component({
  selector: 'ms-form-news',
  templateUrl: './form-news.component.html',
  styleUrls: ['./form-news.component.scss']
})
export class FormNewsComponent implements OnInit, OnDestroy {
  @ViewChild(DxFileUploaderComponent, { static: true }) uploader: DxFileUploaderComponent;
  @ViewChild(DxFormComponent, { static: false }) dxForm: DxFormComponent;
  disposeBag = new DisposeBag();
  public form: FormGroup;
  news: any;
  public cardTitle: string
  public id: string
  photoToUpload: any;
  languages: any[];
  isTitleFormValid: boolean;
  isDescriptionFormValid: boolean;
  queryParams: any;

  constructor(
    private fb: FormBuilder,
    private pageTitleService: PageTitleService,
    private newsService: NewsService,
    private utils: UtilsService,
    public loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LogService,
    private appApiService: AppApiService) {
      this.isTitleFormValid = false;
      this.isDescriptionFormValid = false;
  }

  ngOnInit() {
    this.disposeBag.add(zip(this.utils.translate('UPLOAD NEWS'), this.utils.translate('NEWS')).subscribe(array => {
      this.cardTitle = array[0];
      this.pageTitleService.setTitle(array[1]);
    }));
    this.form = this.fb.group({
      link: [null]
    });
    this.disposeBag.add(zip(this.route.params, this.route.queryParams).subscribe(array => {
      this.id = String(array[0].id);
      this.queryParams = array[1];
      if (!this.isNew()) {
        if (!this.isCloned()) {
          this.utils.translate('EDIT NEWS').subscribe((res: string) => this.cardTitle = res);
        }
        this.loadNewsData();
      } else {
        this.setEmptyData();
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.appApiService.getLanguages()).subscribe(
          res => {
            this.languages = res.map(language => {
              language.key = language.code.toLowerCase();
              return language;
            });
          },
          () => this.logger.serverError()
        ));
      }
    }));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  setEmptyData() {
    this.news = {
      title: '',
      description: ''
    };
  }

  loadNewsData() {
    this.disposeBag.add(zip(this.loadingService.apiRequest$WithLoading(this.newsService.getOne(this.id)), this.appApiService.getLanguages()).subscribe(
      array => {
        const news = array[0];
        this.news = news;
        this.objToForm(this.news);
        if (this.isCloned()) {
          this.id = 'undefined';
          delete this.news.photo;
        }
        this.languages = array[1];
        this.languages = this.languages.map(language => {
          language.key = language.code.toLowerCase();
          return language;
        });
      },
      () => this.logger.serverError()
    ));
  }

  public objToForm(news: any) {
    this.form.controls.link.setValue(news.link);
  }

  public formToObj(): any {
    const obj = {};
    obj['title'] = this.news.title;
    obj['description'] = this.news.description;
    obj['link'] = this.form.controls.link.value;
    return obj;
  }

  isFormValid() {
    return this.form.valid && this.isTitleFormValid && this.isDescriptionFormValid;
  }

  fileChange(event) {
    const files = event;
    this.photoToUpload = null;
    if (files.length > 0) {
      this.photoToUpload = files[0];
    }
  }

  public onSubmit() {
    if (!this.isFormValid()) { return; }
    const observable$ = this.isNew() ? this.saveNews() : this.updateNews();
    const progress = !!this.photoToUpload;
    this.handleRequest(observable$, progress);
  }

  public handleRequest(observable$: Observable<any>, progress: boolean) {
    if (!progress) {
      this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(
        () => this.goToListPage(),
        () => this.logger.serverError()
      ));
      return;
    }

    this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(
      () => this.goToListPage(),
      () => this.logger.serverError()
    ));
  }

  private updateNews(): Observable<any> {
    const input = this.formToObj() as any;
    return this.newsService.update(this.id, input, this.photoToUpload);
  }

  private saveNews(): Observable<any> {
    const input = this.formToObj() as any;
    return this.newsService.create(input, this.photoToUpload);
  }

  public goToListPage() {
    this.router.navigate(['news']);
  }

  private isNew(): boolean {
    return this.id === 'undefined';
  }

  private isCloned(): boolean {
    if (!this.queryParams || !this.queryParams.clone) { return false; }
    return this.queryParams.clone;
  }
}
