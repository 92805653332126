"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var request_service_1 = require("./request.service");
var api_service_1 = require("./api.service");
var i0 = require("@angular/core");
var i1 = require("./request.service");
var i2 = require("./api.service");
var AppApiService = /** @class */ (function () {
    function AppApiService(requestService, apiService) {
        this.requestService = requestService;
        this.apiService = apiService;
    }
    AppApiService.prototype.getLanguages = function () {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl('settings', 'language')).map(function (res) { return res; });
    };
    AppApiService.prototype.getMagazineLanguages = function () {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl('settings', 'magazine_language')).map(function (res) { return res; });
    };
    AppApiService.prototype.getCategories = function () {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl('settings', 'category')).map(function (res) { return res; });
    };
    AppApiService.prototype.getCountries = function () {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl('settings', 'country')).map(function (res) { return res; });
    };
    AppApiService.prototype.getPaperCopiesRequired = function () {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl('paper_copy_request')).map(function (res) { return res; });
    };
    AppApiService.prototype.getPaperCopyRequest = function (id) {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl('paper_copy_request', id)).map(function (res) { return res; });
    };
    AppApiService.prototype.processPaperCopyRequest = function (obj, id) {
        return this.requestService.put(this.apiService.createUrlFromBaseUrl('paper_copy_request', id, 'process'), JSON.stringify(obj));
    };
    AppApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppApiService_Factory() { return new AppApiService(i0.ɵɵinject(i1.RequestService), i0.ɵɵinject(i2.ApiService)); }, token: AppApiService, providedIn: "root" });
    return AppApiService;
}());
exports.AppApiService = AppApiService;
