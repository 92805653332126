"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
require("devextreme-intl");
var itMessages = require('devextreme/localization/messages/it.json');
var localization_1 = require("devextreme/localization");
var GeneAppComponent = /** @class */ (function () {
    function GeneAppComponent(translate) {
        translate.addLangs(['en', 'fr', 'he', 'ru', 'ar', 'zh', 'de', 'es', 'ja', 'ko', 'it', 'hu']);
        translate.setDefaultLang('it');
        var browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|it/) ? browserLang : 'it');
        localization_1.loadMessages(itMessages);
        localization_1.locale(navigator.language || browserLang);
    }
    return GeneAppComponent;
}());
exports.GeneAppComponent = GeneAppComponent;
