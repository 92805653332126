import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "app/common/api.service";
import { CrudApiService } from "app/common/crud-api.service";
import { RequestService } from "app/common/request.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MagazineLocalizationService extends CrudApiService {
  constructor(
    protected requestService: RequestService, 
    protected apiService: ApiService,
    private httpClient: HttpClient,  
  ) {
    super(requestService, apiService)
    this.target = 'magazine-localization'
  }

  save(magazineVersion: MagVersion, file: any): Observable<any> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(magazineVersion));
    formData.append('files', file);
    return this.requestService.multipartRequestWithFormData(
      'POST', 
      this.apiService.createUrlFromBaseUrl(this.target), 
      formData, 
      true,
      false);
  }

  updateLocalization(magazineLocalizationId: number, name: string, language_id: number): Observable<any> {
    return this.httpClient.put(
      this.apiService.createUrlFromBaseUrl(this.target, magazineLocalizationId.toString()),
      JSON.stringify({ name, language_id })
    );
  }

  downloadFile(url: string, name: string) {
    this.requestService.downloadFile(url, {}, '*/pdf', name);
  }
}

export class MagVersion {
  id: number;
  url: string;
  name: string;
  magazine_id: number;
  language_id: number;
}
