"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IiIsImZpbGUiOiJzcmMvYXBwL2NvbXBvbmVudHMvbWFnYXppbmUvbG9jYWxpemF0aW9ucy9ncmlkLW1hZ2F6aW5lLWxvY2FsaXphdGlvbi9ncmlkLW1hZ2F6aW5lLWxvY2FsaXphdGlvbi5jb21wb25lbnQuc2NzcyJ9 */"];
exports.styles = styles;
