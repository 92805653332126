"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./info.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../common/mt-loading/mt-loading.component.ngfactory");
var i3 = require("../../common/mt-loading/mt-loading.component");
var i4 = require("@ngx-loading-bar/core");
var i5 = require("@angular/common");
var i6 = require("./view-info/view-info.component.ngfactory");
var i7 = require("./view-info/view-info.component");
var i8 = require("@angular/router");
var i9 = require("./info.service");
var i10 = require("../../common/loading.service");
var i11 = require("../../common/utils.service");
var i12 = require("./info.component");
var styles_InfoComponent = [i0.styles];
var RenderType_InfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoComponent, data: {} });
exports.RenderType_InfoComponent = RenderType_InfoComponent;
function View_InfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ms-mt-loading", [], null, null, null, i2.View_MtLoadingComponent_0, i2.RenderType_MtLoadingComponent)), i1.ɵdid(1, 770048, null, 0, i3.MtLoadingComponent, [i4.LoadingBarService], { show: [0, "show"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ms-view-info", [], null, null, null, i6.View_ViewInfoComponent_0, i6.RenderType_ViewInfoComponent)), i1.ɵdid(4, 114688, null, 0, i7.ViewInfoComponent, [i8.Router, i9.InfoService, i10.LoadingService, i11.UtilsService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.loadingService.loading$)); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, null); }
exports.View_InfoComponent_0 = View_InfoComponent_0;
function View_InfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ms-info", [], null, null, null, View_InfoComponent_0, RenderType_InfoComponent)), i1.ɵdid(1, 114688, null, 0, i12.InfoComponent, [i11.UtilsService, i10.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_InfoComponent_Host_0 = View_InfoComponent_Host_0;
var InfoComponentNgFactory = i1.ɵccf("ms-info", i12.InfoComponent, View_InfoComponent_Host_0, {}, {}, []);
exports.InfoComponentNgFactory = InfoComponentNgFactory;
