"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dispose_bag_class_1 = require("app/classes/dispose-bag.class");
var router_1 = require("@angular/router");
var authentication_service_1 = require("app/authentication/authentication.service");
var loading_service_1 = require("app/common/loading.service");
var LoginComponent = /** @class */ (function () {
    function LoginComponent(route, router, authService, loadingService) {
        this.route = route;
        this.router = router;
        this.authService = authService;
        this.loadingService = loadingService;
        this.loading = false;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
        this.isErrorShow = false;
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.logout();
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
        this.disposeBag.add(this.loadingService.loading$.subscribe(function (res) { _this.loading = res; }));
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    // when email and password is correct, user logged in.
    LoginComponent.prototype.login = function () {
        var _this = this;
        this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.authService.login(this.email, this.password)).subscribe(function () {
            if (!!_this.returnUrl && _this.returnUrl !== '/') {
                // login successful so redirect to return url
                _this.router.navigateByUrl(_this.returnUrl);
                return;
            }
            _this.router.navigate(['/users']);
        }, function () {
            _this.isErrorShow = true;
        }));
    };
    return LoginComponent;
}());
exports.LoginComponent = LoginComponent;
