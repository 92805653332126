"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var i2 = require("./app.component");
var i3 = require("@ngx-translate/core");
var styles_GeneAppComponent = [];
var RenderType_GeneAppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GeneAppComponent, data: {} });
exports.RenderType_GeneAppComponent = RenderType_GeneAppComponent;
function View_GeneAppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_GeneAppComponent_0 = View_GeneAppComponent_0;
function View_GeneAppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "gene-app", [], null, null, null, View_GeneAppComponent_0, RenderType_GeneAppComponent)), i0.ɵdid(1, 49152, null, 0, i2.GeneAppComponent, [i3.TranslateService], null, null)], null, null); }
exports.View_GeneAppComponent_Host_0 = View_GeneAppComponent_Host_0;
var GeneAppComponentNgFactory = i0.ɵccf("gene-app", i2.GeneAppComponent, View_GeneAppComponent_Host_0, {}, {}, []);
exports.GeneAppComponentNgFactory = GeneAppComponentNgFactory;
