<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
    <mat-card fxFlex="100%">
        <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column">
                    <h4>{{ cardTitle | translate }}</h4>
                </div> <span fxFlex></span>
            </div>
            <mat-divider></mat-divider>
        </div>
        <form (submit)="onFormSubmit()">
            <dx-form id="dxForm" [formData]="magazine" (onFieldDataChanged)="onFieldDataChanged()"
                [colCount]="2">
                <dxi-item dataField="name" [label]="{text: 'NAME' | translate}" [colSpan]="2">
                    <dxi-validation-rule type="required" [message]="'MSG NAME' | translate"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="search_keys" [label]="{text: 'KEYWORDS' | translate}"></dxi-item>
                <dxi-item dataField="publishing_year" editorType="dxSelectBox" 
                    [editorOptions]="{ dataSource: publishingYears }"
                    [label]="{ text: 'PUBLISHING YEAR' | translate }">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <!-- date field -->
                <dxi-item dataField="publication_date" editorType="dxDateBox" 
                    [editorOptions]="{ type: 'date', displayFormat: 'dd/MM/yyyy' }"
                    [label]="{ text: 'PUBLISHING DATE' | translate }">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="issue_number" editorType="dxTextBox" 
                    [editorOptions]="{ min: 1 }"
                    [label]="{text: 'ISSUE NUMBER' | translate}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
            </dx-form>
            <div class="pad-wrap" id="form_actions">
                <mat-card-actions>
                    <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
                        [disabled]="isFormInvalid">
                    </dx-button>
                    <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()" type="normal">
                    </dx-button>
                </mat-card-actions>
            </div>
        </form>
    </mat-card>
</div>