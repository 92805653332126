"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./magazine-localization.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../common/mt-loading/mt-loading.component.ngfactory");
var i3 = require("../../../common/mt-loading/mt-loading.component");
var i4 = require("@ngx-loading-bar/core");
var i5 = require("@angular/common");
var i6 = require("./grid-magazine-localization/grid-magazine-localization.component.ngfactory");
var i7 = require("./grid-magazine-localization/grid-magazine-localization.component");
var i8 = require("../../../common/config.service");
var i9 = require("@angular/router");
var i10 = require("./magazine-localization.service");
var i11 = require("../magazine.service");
var i12 = require("../../../common/dx.service");
var i13 = require("../../../common/loading.service");
var i14 = require("../../../common/log.service");
var i15 = require("./magazine-localization.component");
var i16 = require("../../../common/utils.service");
var styles_MagazineLocalizationComponent = [i0.styles];
var RenderType_MagazineLocalizationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MagazineLocalizationComponent, data: {} });
exports.RenderType_MagazineLocalizationComponent = RenderType_MagazineLocalizationComponent;
function View_MagazineLocalizationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ms-mt-loading", [], null, null, null, i2.View_MtLoadingComponent_0, i2.RenderType_MtLoadingComponent)), i1.ɵdid(1, 770048, null, 0, i3.MtLoadingComponent, [i4.LoadingBarService], { show: [0, "show"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ms-grid-magazine-localization", [], null, null, null, i6.View_GridMagazineLocalizationComponent_0, i6.RenderType_GridMagazineLocalizationComponent)), i1.ɵdid(4, 770048, null, 0, i7.GridMagazineLocalizationComponent, [i8.ConfigService, i9.Router, i10.MagazineLocalizationService, i11.MagazineService, i12.DxService, i13.LoadingService, i14.LogService], { magazineId: [0, "magazineId"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.loadingService.loading$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.magazineId$)); _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_MagazineLocalizationComponent_0 = View_MagazineLocalizationComponent_0;
function View_MagazineLocalizationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ms-magazine-localization", [], null, null, null, View_MagazineLocalizationComponent_0, RenderType_MagazineLocalizationComponent)), i1.ɵdid(1, 114688, null, 0, i15.MagazineLocalizationComponent, [i16.UtilsService, i13.LoadingService, i9.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_MagazineLocalizationComponent_Host_0 = View_MagazineLocalizationComponent_Host_0;
var MagazineLocalizationComponentNgFactory = i1.ɵccf("ms-magazine-localization", i15.MagazineLocalizationComponent, View_MagazineLocalizationComponent_Host_0, {}, {}, []);
exports.MagazineLocalizationComponentNgFactory = MagazineLocalizationComponentNgFactory;
