"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var api_service_1 = require("app/common/api.service");
var crud_api_service_1 = require("app/common/crud-api.service");
var request_service_1 = require("app/common/request.service");
var i0 = require("@angular/core");
var i1 = require("../../../common/request.service");
var i2 = require("../../../common/api.service");
var i3 = require("@angular/common/http");
var MagazineLocalizationService = /** @class */ (function (_super) {
    __extends(MagazineLocalizationService, _super);
    function MagazineLocalizationService(requestService, apiService, httpClient) {
        var _this = _super.call(this, requestService, apiService) || this;
        _this.requestService = requestService;
        _this.apiService = apiService;
        _this.httpClient = httpClient;
        _this.target = 'magazine-localization';
        return _this;
    }
    MagazineLocalizationService.prototype.save = function (magazineVersion, file) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(magazineVersion));
        formData.append('files', file);
        return this.requestService.multipartRequestWithFormData('POST', this.apiService.createUrlFromBaseUrl(this.target), formData, true, false);
    };
    MagazineLocalizationService.prototype.updateLocalization = function (magazineLocalizationId, name, language_id) {
        return this.httpClient.put(this.apiService.createUrlFromBaseUrl(this.target, magazineLocalizationId.toString()), JSON.stringify({ name: name, language_id: language_id }));
    };
    MagazineLocalizationService.prototype.downloadFile = function (url, name) {
        this.requestService.downloadFile(url, {}, '*/pdf', name);
    };
    MagazineLocalizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MagazineLocalizationService_Factory() { return new MagazineLocalizationService(i0.ɵɵinject(i1.RequestService), i0.ɵɵinject(i2.ApiService), i0.ɵɵinject(i3.HttpClient)); }, token: MagazineLocalizationService, providedIn: "root" });
    return MagazineLocalizationService;
}(crud_api_service_1.CrudApiService));
exports.MagazineLocalizationService = MagazineLocalizationService;
var MagVersion = /** @class */ (function () {
    function MagVersion() {
    }
    return MagVersion;
}());
exports.MagVersion = MagVersion;
