<dx-data-grid id="gridContainer" [dataSource]="magazines$ | async" [allowColumnReordering]="true"
    [rowAlternationEnabled]="true" [allowColumnResizing]="true" [showBorders]="true"
    (onToolbarPreparing)="onToolbarPreparing($event)">
    <dxo-export [enabled]="true" fileName="Magazines"></dxo-export>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]">
    </dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxi-column type="buttons" [width]="buttonColumnNameWidth">
        <dxi-button icon="trash" [onClick]="removeButtonClick" hint="{{'REMOVE MAGAZINE' | translate}}"></dxi-button>
        <dxi-button icon="edit" [onClick]="editButtonClick" hint="{{'MODIFY MAGAZINE' | translate}}"></dxi-button>
        <dxi-button icon="doc" [onClick]="languagesButtonClick" hint="{{'MAGAZINE VERSIONS' | translate}}"></dxi-button>
    </dxi-column>
    <div *dxTemplate="let cell of 'thumbnailCellTemplate'">
        <img *ngIf="!!cell.value && cell.value !== ''" [src]="cell.value"
            (click)="downloadFile(cell.data.current_version.url, cell.data.current_version.name)" />
    </div>
    <dxi-column dataField="name" caption="{{'NAME' | translate}}"></dxi-column>
    <dxi-column dataField="issue_number" caption="{{ 'ISSUE NUMBER' | translate }}" 
        alignment="left"></dxi-column>
    <dxi-column dataField="publishing_year" caption="{{ 'PUBLISHING YEAR' | translate }}"
        alignment="left"></dxi-column>
</dx-data-grid>