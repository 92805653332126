"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = {
    production: false,
    baseUrl: 'https://api-dev.fassi.mindtek.it',
    defaultLanguage: 'en',
    hereMapsAppCode: '4v33rjrr5aRNzL2T-C4NvA',
    hereMapsAppId: 'tBeP0vdg2m0kKk2IWTvw',
    startLatitude: 41.89193,
    startLongitude: 12.51133
};
