"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var page_title_service_1 = require("app/core/page-title/page-title.service");
var rxjs_1 = require("rxjs");
var form_password_component_1 = require("app/components/user/form-password/form-password.component");
var material_1 = require("@angular/material");
var environment_1 = require("../../environments/environment");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var i2 = require("../core/page-title/page-title.service");
var i3 = require("@angular/material/dialog");
var UtilsService = /** @class */ (function () {
    function UtilsService(translateService, pageTitleService, dialog) {
        this.translateService = translateService;
        this.pageTitleService = pageTitleService;
        this.dialog = dialog;
    }
    UtilsService.prototype.translate = function (key) {
        return this.translateService.get(key);
    };
    UtilsService.prototype.translateArray = function (array) {
        var _this = this;
        return rxjs_1.zip.apply(void 0, array.map(function (type) { return _this.translate(type); }));
    };
    UtilsService.prototype.translateArrayDict = function (keys) {
        return this.translateArray(keys).map(function (array) {
            return array.map(function (item, i) {
                return { id: keys[i], text: item };
            });
        });
    };
    UtilsService.prototype.translateDict = function (keys) {
        return this.translateArrayDict(keys).map(function (res) {
            var dict = {};
            for (var _i = 0, res_1 = res; _i < res_1.length; _i++) {
                var el = res_1[_i];
                dict[el.id] = el.text;
            }
            return dict;
        });
    };
    UtilsService.prototype.arrayToDict = function (array, primaryKey, valueKey) {
        if (valueKey === void 0) { valueKey = null; }
        var dict = {};
        for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
            var el = array_1[_i];
            dict[el[primaryKey]] = !!valueKey && valueKey !== '' ? el[valueKey] : el;
        }
        return dict;
    };
    UtilsService.prototype.objectArrayToDict = function (array, primaryKey) {
        var dict = {};
        for (var _i = 0, array_2 = array; _i < array_2.length; _i++) {
            var el = array_2[_i];
            dict[el[primaryKey]] = el;
        }
        return dict;
    };
    UtilsService.prototype.setPageTitleByTranslateKey = function (key) {
        var _this = this;
        this.translateService.get(key).subscribe(function (translatedText) {
            _this.pageTitleService.setTitle(translatedText);
        });
    };
    UtilsService.prototype.dataURLToBlob = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, fetch(url).then(function (res) { return res.blob(); })];
            });
        });
    };
    UtilsService.prototype.blobToFile = function (theBlob, fileName) {
        var b = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return theBlob;
    };
    UtilsService.prototype.dataURLToFile = function (url, fileName) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, this.dataURLToBlob(url).then(function (blob) { return _this.blobToFile(blob, fileName); })];
            });
        });
    };
    UtilsService.prototype.secondsToHours = function (seconds, decimal) {
        var hours = seconds / 3600;
        return Math.ceil(hours * Math.pow(10, decimal)) / Math.pow(10, decimal);
    };
    UtilsService.prototype.secondsToString = function (eta) {
        var hours = eta / 3600;
        var hoursInt = Math.floor(hours);
        var minutes = (hours - hoursInt) * 60;
        var minutesInt = Math.floor(minutes);
        var seconds = (minutes - minutesInt) * 60;
        var secondsInt = Math.floor(seconds);
        return this.numberToString(hoursInt) + ':' + this.numberToString(minutesInt) + ':' + this.numberToString(secondsInt);
    };
    UtilsService.prototype.numberToString = function (int) {
        if (int < 10) {
            return '0' + int;
        }
        return int;
    };
    UtilsService.prototype.openPasswordDialog = function (id) {
        var _this = this;
        this.profileDialogRef = this.dialog.open(form_password_component_1.FormPasswordComponent, {
            panelClass: 'form-popup',
            disableClose: false,
            data: id,
            width: '50%',
            height: '50%'
        });
        return this.profileDialogRef.afterClosed().map(function (result) {
            _this.profileDialogRef = null;
            return result;
        });
    };
    UtilsService.prototype.setViewOfMultiLanguageField = function (data) {
        var obj = JSON.parse(data);
        var browserLang = this.translateService.getBrowserLang();
        return !!obj[browserLang] ? obj[browserLang] : obj[environment_1.environment.defaultLanguage];
    };
    UtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.PageTitleService), i0.ɵɵinject(i3.MatDialog)); }, token: UtilsService, providedIn: "root" });
    return UtilsService;
}());
exports.UtilsService = UtilsService;
