import { Component, OnInit } from '@angular/core';
import { UtilsService } from 'app/common/utils.service';
import { LoadingService } from 'app/common/loading.service';

@Component({
  selector: 'ms-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {

  constructor(private utils: UtilsService, public loadingService: LoadingService) { }

  ngOnInit() {
    this.utils.setPageTitleByTranslateKey('LOCATIONS');
  }

}
