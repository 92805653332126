"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".gene-registration-form .login-logo img {\n  margin-bottom: 1.5rem;\n}\n.gene-registration-form button {\n  margin-bottom: 1rem;\n}\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbGliL2plbmtpbnMvd29ya3NwYWNlL2Zhc3NpLWNtcy1kZXYvc3JjL2FwcC9zZXNzaW9uL3JlZ2lzdGVyL3JlZ2lzdGVyLWNvbXBvbmVudC5zY3NzIiwic3JjL2FwcC9zZXNzaW9uL3JlZ2lzdGVyL3JlZ2lzdGVyLWNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVRO0VBQ0kscUJBQUE7QUNEWjtBRElJO0VBQ0ksbUJBQUE7QUNGUiIsImZpbGUiOiJzcmMvYXBwL3Nlc3Npb24vcmVnaXN0ZXIvcmVnaXN0ZXItY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZ2VuZS1yZWdpc3RyYXRpb24tZm9ybXtcbiAgICAubG9naW4tbG9nb3tcbiAgICAgICAgaW1ne1xuICAgICAgICAgICAgbWFyZ2luLWJvdHRvbTogMS41cmVtO1xuICAgICAgICB9XG4gICAgfVxuICAgIGJ1dHRvbntcbiAgICAgICAgbWFyZ2luLWJvdHRvbTogMXJlbTtcbiAgICB9XG59IiwiLmdlbmUtcmVnaXN0cmF0aW9uLWZvcm0gLmxvZ2luLWxvZ28gaW1nIHtcbiAgbWFyZ2luLWJvdHRvbTogMS41cmVtO1xufVxuLmdlbmUtcmVnaXN0cmF0aW9uLWZvcm0gYnV0dG9uIHtcbiAgbWFyZ2luLWJvdHRvbTogMXJlbTtcbn0iXX0= */"];
exports.styles = styles;
