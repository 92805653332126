import { LogService } from './../../../common/log.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DisposeBag } from '../../../classes/dispose-bag.class';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../common/utils.service';
import { Version, VersionService } from '../version.service';
import { LoadingService } from '../../../common/loading.service';
import { DxFormComponent, DxFileUploaderComponent } from 'devextreme-angular';

const maxFileSize = 150000000; // 150 MB

@Component({
  selector: 'ms-form-version',
  templateUrl: './form-version.component.html',
  styleUrls: ['./form-version.component.scss']
})
export class FormVersionComponent implements OnInit, OnDestroy {
  @ViewChild(DxFormComponent, { static: true }) dxForm: DxFormComponent;
  @ViewChild(DxFileUploaderComponent, { static: false }) uploader: DxFileUploaderComponent;
  version: Version;
  catalogueId: number;
  versionFile: any;
  canUpdateFile: boolean;
  cardTitle: string;
  isFileUploaded: boolean;
  disposeBag: DisposeBag;
  isFormInvalid: boolean;
  isMaxSizeExceeded: boolean;

  constructor(
    public loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private versionService: VersionService,
    private logger: LogService
  ) {
    this.disposeBag = new DisposeBag();
    this.canUpdateFile = false;
    this.isFileUploaded = false;
    this.isFormInvalid = true;
    this.isMaxSizeExceeded = false;
  }

  ngOnInit() {
    this.utilsService.setPageTitleByTranslateKey('CATALOGUE VERSIONS');
    this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.getVersion()).subscribe((version: Version) => {
      this.version = version;
      this.canUpdateFile = !version.url;
      if (!!version.id) { this.dxForm.instance.updateData(version); }
    }));
    this.disposeBag.add(this.route.params.map(params => params['id']).subscribe((id: number) => this.catalogueId = id));
  }

  ngOnDestroy(): void {
    this.disposeBag.dispose();
  }

  getVersion(): Observable<Version> {
    return this.route.params.map(params => params['version_id']).switchMap((idFound, _) => {
      if (idFound) {
        this.cardTitle = 'MODIFY VERSION';
        return this.versionService.getOne(idFound);
      }
      this.cardTitle = 'ADD VERSION';
      return of(new Version());
    });
  }

  onFormSubmit() {
    if (this.version.id) {
      this.disposeBag.add(this.loadingService.apiRequest$WithLoading(this.updateVersion()).subscribe(
        () => this.goToListPage(),
        () => this.logger.serverError()
      ));
    } else {
      if (!this.catalogueId) { return; }
      this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(this.saveVersion()).subscribe(
        () => this.goToListPage(),
        () => this.logger.serverError()
      ));
    }
  }

  onFieldDataChanged() {
    this.isFormInvalid = !this.isFormValid();
  }

  fileChange(event: File[]) {
    this.versionFile = null;
    this.isMaxSizeExceeded = false;
    if (event.length > 0) {
      if (event[0].size > maxFileSize) {
        this.uploader.instance.reset();
        this.isMaxSizeExceeded = true;
      } else { this.versionFile = event[0]; }
    }
    this.isFileUploaded = !!this.versionFile;
    this.isFormInvalid = !this.isFormValid();
  }

  validate() {
    if (!this.dxForm || !this.dxForm.instance) { return { isValid: false }; }
    return this.dxForm.instance.validate();
  }

  isFormValid() {
    const result = this.validate();
    const isFormValid = result.isValid;
    return this.version.id ? isFormValid : isFormValid && this.isFileUploaded && !this.isMaxSizeExceeded;
  }

  public goToListPage() {
    if (!this.catalogueId) { return; }
    this.router.navigate(['catalogue', this.catalogueId, 'version']);
  }

  private saveVersion(): Observable<any> {
    this.version.catalogue_id = this.catalogueId;
    return this.versionService.save(this.version, this.versionFile);
  }

  private updateVersion(): Observable<any> {
    return this.versionService.updateName(this.version.id, this.version.name);
  }
}
