"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./user.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../common/mt-loading/mt-loading.component.ngfactory");
var i3 = require("../../common/mt-loading/mt-loading.component");
var i4 = require("@ngx-loading-bar/core");
var i5 = require("@angular/common");
var i6 = require("./grid-user/grid-user.component.ngfactory");
var i7 = require("./grid-user/grid-user.component");
var i8 = require("@angular/router");
var i9 = require("../../common/config.service");
var i10 = require("../../common/utils.service");
var i11 = require("../../common/loading.service");
var i12 = require("./user.service");
var i13 = require("./user.component");
var styles_UserComponent = [i0.styles];
var RenderType_UserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserComponent, data: {} });
exports.RenderType_UserComponent = RenderType_UserComponent;
function View_UserComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ms-mt-loading", [], null, null, null, i2.View_MtLoadingComponent_0, i2.RenderType_MtLoadingComponent)), i1.ɵdid(1, 770048, null, 0, i3.MtLoadingComponent, [i4.LoadingBarService], { show: [0, "show"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ms-grid-user", [], null, null, null, i6.View_GridUserComponent_0, i6.RenderType_GridUserComponent)), i1.ɵdid(4, 114688, null, 0, i7.GridUserComponent, [i8.Router, i9.ConfigService, i10.UtilsService, i11.LoadingService, i12.UserService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.loadingService.loading$)); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, null); }
exports.View_UserComponent_0 = View_UserComponent_0;
function View_UserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ms-user", [], null, null, null, View_UserComponent_0, RenderType_UserComponent)), i1.ɵdid(1, 114688, null, 0, i13.UserComponent, [i10.UtilsService, i11.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_UserComponent_Host_0 = View_UserComponent_Host_0;
var UserComponentNgFactory = i1.ɵccf("ms-user", i13.UserComponent, View_UserComponent_Host_0, {}, {}, []);
exports.UserComponentNgFactory = UserComponentNgFactory;
