<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
    <mat-card fxFlex="100%">
        <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column">
                    <h4>{{ 'MODIFY THE INFO' | translate }}</h4>
                </div> <span fxFlex></span>
            </div>
            <mat-divider></mat-divider>
        </div>
        <div class="gene-card-content">
            <form (submit)="onFormSubmit($event)">
                <dx-form id="dxForm" [formData]="info" [colCount]="2" (onFieldDataChanged)="onFieldDataChanged($event)"
                    (onContentReady)="onContentReady($event)">
                    <dxi-item [colSpan]="2">
                        <ms-text-box-multilanguage labelText="{{ 'TITLE' | translate }}" [(model)]="info.name"
                            [isRequired]="true" [(isValid)]="isTitleFormValid" [languages]="languages">
                        </ms-text-box-multilanguage>
                    </dxi-item>
                    <dxi-item [colSpan]="2">
                        <ms-text-area-multilanguage labelText="{{ 'DESCRIPTION' | translate }}" height="60"
                            [(model)]="info.description" [isRequired]="false" [(isValid)]="isDescriptionFormValid"
                            [languages]="languages"></ms-text-area-multilanguage>
                    </dxi-item>
                    <dxi-item dataField="address" [colSpan]="2" [label]="{text: 'ADDRESS' | translate}">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="photo" [colSpan]="2">
                        <dx-file-uploader #uploader selectButtonText="{{'SELECT IMAGE' | translate}}" labelText=""
                            accept="image/png, image/jpg, image/jpeg" uploadMode="useForm" (valueChange)="fileChange($event)">
                        </dx-file-uploader>
                        <div class="field-required-error" *ngIf="isMaxSizeExceeded">
                            {{ 'MSG MAX FILE SIZE' | translate}} 1 MB
                        </div>
                    </dxi-item>
                    <dxi-item dataField="mail" [colSpan]="1" [label]="{text: 'MAIL' | translate}">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="phone" [colSpan]="1" [label]="{text: 'PHONE' | translate}">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="website" [colSpan]="2" [label]="{text: 'WEBSITE' | translate}">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="facebook" [colSpan]="1" [label]="{text: 'FACEBOOK' | translate}">
                    </dxi-item>
                    <dxi-item dataField="twitter" [colSpan]="1" [label]="{text: 'TWITTER' | translate}">
                    </dxi-item>
                    <dxi-item dataField="linkedin" [colSpan]="1" [label]="{text: 'LINKEDIN' | translate}">
                    </dxi-item>
                    <dxi-item dataField="instagram" [colSpan]="1" [label]="{text: 'INSTAGRAM' | translate}">
                    </dxi-item>
                    <dxi-item dataField="youtube" [colSpan]="1" [label]="{text: 'YOUTUBE' | translate}">
                    </dxi-item>
                </dx-form>
                <div class="pad-wrap" id="form_user_actions">
                    <mat-card-actions>
                        <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
                            [disabled]="isFormInvalid || !isTitleFormValid || !isDescriptionFormValid">
                        </dx-button>
                        <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()" type="normal">
                        </dx-button>
                    </mat-card-actions>
                </div>
            </form>
        </div>
    </mat-card>
</div>