import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AppApiService {

  constructor(protected requestService: RequestService, protected apiService: ApiService) { }

  getLanguages(): Observable<any[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl('settings', 'language')).map(res => res as any);
  }

  getMagazineLanguages(): Observable<any[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl('settings', 'magazine_language')).map(res => res as any);
  }

  getCategories(): Observable<any[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl('settings', 'category')).map(res => res as any);
  }

  getCountries(): Observable<any[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl('settings', 'country')).map(res => res as any);
  }

  getPaperCopiesRequired(): Observable<any[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl('paper_copy_request')).map(res => res as any);
  }

  getPaperCopyRequest(id): Observable<any[]> {
    return this.requestService.get(this.apiService.createUrlFromBaseUrl('paper_copy_request', id)).map(res => res as any);
  }

  processPaperCopyRequest(obj: any, id: string) {
    return this.requestService.put(this.apiService.createUrlFromBaseUrl('paper_copy_request', id, 'process'), JSON.stringify(obj))
  }
}
