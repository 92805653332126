"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@ngx-translate/core");
var material_1 = require("@angular/material");
var i0 = require("@angular/core");
var i1 = require("@angular/material/snack-bar");
var i2 = require("@ngx-translate/core");
var LogService = /** @class */ (function () {
    function LogService(snackBar, translate) {
        this.snackBar = snackBar;
        this.translate = translate;
    }
    LogService.prototype.info = function (message) {
        this.log(message, 'info');
    };
    LogService.prototype.warning = function (message) {
        this.log(message, 'warning');
    };
    LogService.prototype.success = function (message) {
        this.log(message, 'success');
    };
    LogService.prototype.error = function (message) {
        this.log(message, 'error');
    };
    LogService.prototype.log = function (message, type) {
        var _this = this;
        var extraClasses = [];
        switch (type) {
            case 'success': {
                extraClasses = ['toast-dark', 'toast-success'];
                break;
            }
            case 'info': {
                extraClasses = ['toast-light', 'toast-assert'];
                break;
            }
            case 'warning': {
                extraClasses = ['toast-light', 'toast-warning'];
                break;
            }
            case 'error': {
                extraClasses = ['toast-dark', 'toast-error'];
                break;
            }
            default: {
                break;
            }
        }
        this.translate.get(message).subscribe(function (translatedText) {
            _this.openSnackBar(translatedText, extraClasses);
        }, function (error) {
            _this.openSnackBar(message, extraClasses);
        });
    };
    LogService.prototype.serverError = function () {
        var _this = this;
        this.translate.get('SERVER ERROR').subscribe(function (translatedText) {
            _this.error(translatedText);
        });
    };
    LogService.prototype.alreadyBookedError = function () {
        var _this = this;
        this.translate.get('ALREADY BOOKED').subscribe(function (translatedText) {
            _this.error(translatedText.toUpperCase());
        });
    };
    LogService.prototype.notAccetableError = function () {
        var _this = this;
        this.translate.get('NOTACCETABLE ERROR').subscribe(function (translatedText) {
            _this.error(translatedText.toUpperCase());
        });
    };
    LogService.prototype.badRequestError = function () {
        var _this = this;
        this.translate.get('BADREQUEST ERROR').subscribe(function (translatedText) {
            _this.error(translatedText.toUpperCase());
        });
    };
    LogService.prototype.pdfLinkIdDuplicateError = function () {
        var _this = this;
        this.translate.get('PDFLINKIDDUPLICATE ERROR').subscribe(function (translatedText) {
            _this.error(translatedText.toUpperCase());
        });
    };
    LogService.prototype.duplicateLanguageForMagazineError = function () {
        var _this = this;
        this.translate.get('DUPLICATE LANGUAGE FOR MAGAZINE').subscribe(function (translatedText) {
            _this.error(translatedText.toUpperCase());
        });
    };
    LogService.prototype.openSnackBar = function (message, extraClasses) {
        this.snackBar.open(message, this.translate.instant('CLOSE').toUpperCase(), {
            duration: 30000,
            panelClass: extraClasses
        });
    };
    LogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LogService_Factory() { return new LogService(i0.ɵɵinject(i1.MatSnackBar), i0.ɵɵinject(i2.TranslateService)); }, token: LogService, providedIn: "root" });
    return LogService;
}());
exports.LogService = LogService;
