"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var utils_service_1 = require("app/common/utils.service");
var loading_service_1 = require("app/common/loading.service");
var PaperCopyRequestComponent = /** @class */ (function () {
    function PaperCopyRequestComponent(utilsService, loadingService) {
        this.utilsService = utilsService;
        this.loadingService = loadingService;
    }
    PaperCopyRequestComponent.prototype.ngOnInit = function () {
        this.utilsService.setPageTitleByTranslateKey('PAPER COPY REQUEST');
    };
    return PaperCopyRequestComponent;
}());
exports.PaperCopyRequestComponent = PaperCopyRequestComponent;
