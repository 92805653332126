
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ms-test-map',
  templateUrl: './test-map.component.html',
  styleUrls: ['./test-map.component.scss']
})
export class TestMapComponent implements OnInit {
  latitude: any;
  longitude: any;
  constructor() {
   }

  ngOnInit() {
    this.latitude = 5.9714;
    this.longitude = 116.0953;
  }
}
