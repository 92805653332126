"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var core_1 = require("@ngx-translate/core");
var LockScreenV2Component = /** @class */ (function () {
    function LockScreenV2Component(router, translate) {
        this.router = router;
        this.translate = translate;
    }
    // when submit button click, router navigates to a home page.
    LockScreenV2Component.prototype.onSubmit = function () {
        this.router.navigate(['/']);
    };
    return LockScreenV2Component;
}());
exports.LockScreenV2Component = LockScreenV2Component;
