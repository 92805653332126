"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var dispose_bag_class_1 = require("app/classes/dispose-bag.class");
var grid_base_component_1 = require("app/classes/grid-base.component");
var config_service_1 = require("app/common/config.service");
var magazine_service_1 = require("../../magazine.service");
var dx_service_1 = require("app/common/dx.service");
var loading_service_1 = require("app/common/loading.service");
var log_service_1 = require("app/common/log.service");
var magazine_localization_service_1 = require("../magazine-localization.service");
var GridMagazineLocalizationComponent = /** @class */ (function (_super) {
    __extends(GridMagazineLocalizationComponent, _super);
    function GridMagazineLocalizationComponent(configService, router, magazineLanguageService, magazineService, dxService, loadingService, logger) {
        var _this = _super.call(this, configService) || this;
        _this.configService = configService;
        _this.router = router;
        _this.magazineLanguageService = magazineLanguageService;
        _this.magazineService = magazineService;
        _this.dxService = dxService;
        _this.loadingService = loadingService;
        _this.logger = logger;
        _this.disposeBag = new dispose_bag_class_1.DisposeBag();
        _this.editButtonClick = _this.editButtonClick.bind(_this);
        _this.removeButtonClick = _this.removeButtonClick.bind(_this);
        _this._magazineId = null;
        return _this;
    }
    GridMagazineLocalizationComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    GridMagazineLocalizationComponent.prototype.ngOnChanges = function (changes) {
        if (!changes || !changes.magazineId || !changes.magazineId.currentValue) {
            return;
        }
        var magazineId = changes.magazineId.currentValue;
        if (magazineId === 0) {
            return;
        }
        this._magazineId = magazineId;
        this.loadData();
    };
    GridMagazineLocalizationComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    GridMagazineLocalizationComponent.prototype.onToolbarPreparing = function (e) {
        var _this = this;
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'back',
                onClick: function () { return _this.router.navigate(['magazine']); }
            }
        }, {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: function () { return _this.loadData(); }
            }
        }, {
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'add',
                onClick: function () {
                    if (!_this._magazineId) {
                        return;
                    }
                    _this.router.navigate(['magazine', _this._magazineId, 'language', 'add']);
                }
            }
        });
    };
    GridMagazineLocalizationComponent.prototype.editButtonClick = function (e) {
        this.router.navigate(['magazine', this._magazineId, 'language', e.row.data.id]);
    };
    GridMagazineLocalizationComponent.prototype.removeButtonClick = function (e) {
        var _this = this;
        if (!this.isNotCurrent(e)) {
            return;
        }
        this.disposeBag.add(this.askConfirmation(this.magazineLanguageService.delete(e.row.data.id)).subscribe(function () { return _this.loadData(); }, function () { return _this.logger.serverError(); }));
    };
    GridMagazineLocalizationComponent.prototype.isNotCurrent = function (e) {
        return !e.row.data.current;
    };
    GridMagazineLocalizationComponent.prototype.downloadFile = function (url, name) {
        this.magazineLanguageService.downloadFile(url, name + ".pdf");
    };
    GridMagazineLocalizationComponent.prototype.askConfirmation = function (observable$) {
        return this.loadingService.apiRequest$WithLoading(this.dxService.dxConfirmWithSuccessfulObservable('WARNING', 'ARE YOU SURE', observable$));
    };
    GridMagazineLocalizationComponent.prototype.loadData = function () {
        if (!this._magazineId) {
            return;
        }
        this.languages$ = this.loadingService.apiRequest$WithLoading(this.magazineService.getLanguageList(this._magazineId));
    };
    return GridMagazineLocalizationComponent;
}(grid_base_component_1.GridBaseComponent));
exports.GridMagazineLocalizationComponent = GridMagazineLocalizationComponent;
