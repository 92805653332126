<div class="my-container">
    <!--<ngx-loading [show]="loadingService.loading$ | async"></ngx-loading>
    <div *ngIf="loadingService.progress$ | async as progress">
        <span class="text">{{ progress }} %</span>
    </div>-->
</div>
<div fxLayout="row wrap" fxLayoutAlign="start" class="gene-form-wizard" *ngIf="info$ | async as info">
    <div fxFlex="100%">
        <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column">
                    <div class="fi-label fi-first-label">{{ 'TITLE' | translate }}</div>
                  <div class="fi-name">{{ info.name }}</div>
                </div>
            </div>
        </div>
        <div class="gene-card-content">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" *ngIf="!!info.description">
                  <div class="fi-label fi-first-label">{{ 'DESCRIPTION' | translate }}</div>
                  <div class="fi-description">{{ info.description }}</div>
                </div>
                <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                  <div class="fi-label">{{ 'ADDRESS' | translate }}</div>
                  <div class="fi-text">{{ info.address }}</div>
                </div>
                <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="fi-label">{{ 'IMAGE' | translate }}</div>
                    <img class="previewImg" [attr.src]="info.photo | blobImageUrl | async"/>
                  </div>
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                    <div class="fi-label">{{ 'MAIL' | translate }}</div>
                    <div class="fi-text">{{ info.mail }}</div>
                </div>
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100">
                    <div class="fi-label">{{ 'PHONE' | translate }}</div>
                    <div class="fi-text">{{ info.phone }}</div>
                </div>
                <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div class="fi-label">{{ 'WEBSITE' | translate }}</div>
                    <div class="fi-text">{{ info.website }}</div>
                </div>
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" *ngIf="!!info.facebook">
                  <div class="fi-label">{{ 'FACEBOOK' | translate }}</div>
                  <div class="fi-text">{{ info.facebook }}</div>
                </div>
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" *ngIf="!!info.twitter">
                    <div class="fi-label">{{ 'TWITTER' | translate }}</div>
                    <div class="fi-text">{{ info.twitter }}</div>
                </div>
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" *ngIf="!!info.instagram">
                    <div class="fi-label">{{ 'INSTAGRAM' | translate }}</div>
                    <div class="fi-text">{{ info.instagram }}</div>
                </div>
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" *ngIf="!!info.linkedin">
                    <div class="fi-label">{{ 'LINKEDIN' | translate }}</div>
                    <div class="fi-text">{{ info.linkedin }}</div>
                </div>
                <div fxFlex.gt-sm="49" fxFlex.gt-xs="49" fxFlex="100" *ngIf="!!info.youtube">
                    <div class="fi-label">{{ 'YOUTUBE' | translate }}</div>
                    <div class="fi-text">{{ info.youtube }}</div>
                </div>
          </div>
        </div>
        <dx-button [text]="'EDIT' | translate" (onClick)="goToEdit()"></dx-button>
    </div>
</div>
