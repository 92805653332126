"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var loading_service_1 = require("../../common/loading.service");
var utils_service_1 = require("../../common/utils.service");
var core_1 = require("@angular/core");
var CatalogueComponent = /** @class */ (function () {
    function CatalogueComponent(utilsService, loadingService) {
        this.utilsService = utilsService;
        this.loadingService = loadingService;
    }
    CatalogueComponent.prototype.ngOnInit = function () {
        this.utilsService.setPageTitleByTranslateKey('CATALOGUES');
    };
    return CatalogueComponent;
}());
exports.CatalogueComponent = CatalogueComponent;
