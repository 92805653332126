"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["@media (max-width: 599px) {\n  .set-resp-space {\n    padding-top: 0.8rem !important;\n    padding-bottom: 0.8rem !important;\n  }\n}\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbGliL2plbmtpbnMvd29ya3NwYWNlL2Zhc3NpLWNtcy1kZXYvc3JjL2FwcC9zZXNzaW9uL2xvZ2luVjIvbG9naW5WMi1jb21wb25lbnQuc2NzcyIsInNyYy9hcHAvc2Vzc2lvbi9sb2dpblYyL2xvZ2luVjItY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRztJQUNHLDhCQUFBO0lBQ0EsaUNBQUE7RUNDSjtBQUNGIiwiZmlsZSI6InNyYy9hcHAvc2Vzc2lvbi9sb2dpblYyL2xvZ2luVjItY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAbWVkaWEobWF4LXdpZHRoOjU5OXB4KXtcbiAgIC5zZXQtcmVzcC1zcGFjZXtcbiAgICAgIHBhZGRpbmctdG9wOiAwLjhyZW0gIWltcG9ydGFudDtcbiAgICAgIHBhZGRpbmctYm90dG9tOiAwLjhyZW0gIWltcG9ydGFudDtcbiAgIH1cbn0iLCJAbWVkaWEgKG1heC13aWR0aDogNTk5cHgpIHtcbiAgLnNldC1yZXNwLXNwYWNlIHtcbiAgICBwYWRkaW5nLXRvcDogMC44cmVtICFpbXBvcnRhbnQ7XG4gICAgcGFkZGluZy1ib3R0b206IDAuOHJlbSAhaW1wb3J0YW50O1xuICB9XG59Il19 */"];
exports.styles = styles;
