"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".editor-margin[_ngcontent-%COMP%] {\n  margin-top: 10px;\n}\n\n.field-required-error[_ngcontent-%COMP%] {\n  color: red;\n  margin-top: 4px;\n}\n/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92YXIvbGliL2plbmtpbnMvd29ya3NwYWNlL2Zhc3NpLWNtcy1kZXYvc3JjL2FwcC9jb21tb24vaHRtbC1lZGl0b3ItbXVsdGlsYW5ndWFnZS9odG1sLWVkaXRvci1tdWx0aWxhbmd1YWdlLmNvbXBvbmVudC5zY3NzIiwic3JjL2FwcC9jb21tb24vaHRtbC1lZGl0b3ItbXVsdGlsYW5ndWFnZS9odG1sLWVkaXRvci1tdWx0aWxhbmd1YWdlLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsZ0JBQUE7QUNDRjs7QURFQTtFQUNFLFVBQUE7RUFDQSxlQUFBO0FDQ0YiLCJmaWxlIjoic3JjL2FwcC9jb21tb24vaHRtbC1lZGl0b3ItbXVsdGlsYW5ndWFnZS9odG1sLWVkaXRvci1tdWx0aWxhbmd1YWdlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmVkaXRvci1tYXJnaW4ge1xuICBtYXJnaW4tdG9wOiAxMHB4O1xufVxuXG4uZmllbGQtcmVxdWlyZWQtZXJyb3Ige1xuICBjb2xvcjogcmVkO1xuICBtYXJnaW4tdG9wOiA0cHhcbn0iLCIuZWRpdG9yLW1hcmdpbiB7XG4gIG1hcmdpbi10b3A6IDEwcHg7XG59XG5cbi5maWVsZC1yZXF1aXJlZC1lcnJvciB7XG4gIGNvbG9yOiByZWQ7XG4gIG1hcmdpbi10b3A6IDRweDtcbn0iXX0= */"];
exports.styles = styles;
