"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./catalogue.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../common/mt-loading/mt-loading.component.ngfactory");
var i3 = require("../../common/mt-loading/mt-loading.component");
var i4 = require("@ngx-loading-bar/core");
var i5 = require("@angular/common");
var i6 = require("./grid-catalogue/grid-catalogue.component.ngfactory");
var i7 = require("./grid-catalogue/grid-catalogue.component");
var i8 = require("../../common/config.service");
var i9 = require("@angular/router");
var i10 = require("./catalogue.service");
var i11 = require("../../common/dx.service");
var i12 = require("../../common/loading.service");
var i13 = require("../../common/log.service");
var i14 = require("../version/version.service");
var i15 = require("../../common/app-api.service");
var i16 = require("../../common/utils.service");
var i17 = require("./catalogue.component");
var styles_CatalogueComponent = [i0.styles];
var RenderType_CatalogueComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CatalogueComponent, data: {} });
exports.RenderType_CatalogueComponent = RenderType_CatalogueComponent;
function View_CatalogueComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ms-mt-loading", [], null, null, null, i2.View_MtLoadingComponent_0, i2.RenderType_MtLoadingComponent)), i1.ɵdid(1, 770048, null, 0, i3.MtLoadingComponent, [i4.LoadingBarService], { show: [0, "show"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ms-grid-catalogue", [], null, null, null, i6.View_GridCatalogueComponent_0, i6.RenderType_GridCatalogueComponent)), i1.ɵdid(4, 245760, null, 0, i7.GridCatalogueComponent, [i8.ConfigService, i9.Router, i10.CatalogueService, i11.DxService, i12.LoadingService, i13.LogService, i14.VersionService, i15.AppApiService, i16.UtilsService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.loadingService.loading$)); _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, null); }
exports.View_CatalogueComponent_0 = View_CatalogueComponent_0;
function View_CatalogueComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ms-catalogue", [], null, null, null, View_CatalogueComponent_0, RenderType_CatalogueComponent)), i1.ɵdid(1, 114688, null, 0, i17.CatalogueComponent, [i16.UtilsService, i12.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CatalogueComponent_Host_0 = View_CatalogueComponent_Host_0;
var CatalogueComponentNgFactory = i1.ɵccf("ms-catalogue", i17.CatalogueComponent, View_CatalogueComponent_Host_0, {}, {}, []);
exports.CatalogueComponentNgFactory = CatalogueComponentNgFactory;
