"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var crud_api_service_1 = require("app/common/crud-api.service");
var request_service_1 = require("app/common/request.service");
var api_service_1 = require("app/common/api.service");
var i0 = require("@angular/core");
var i1 = require("../../common/request.service");
var i2 = require("../../common/api.service");
var InfoService = /** @class */ (function (_super) {
    __extends(InfoService, _super);
    function InfoService(requestService, apiService) {
        var _this = _super.call(this, requestService, apiService) || this;
        _this.requestService = requestService;
        _this.apiService = apiService;
        _this.target = 'info';
        return _this;
    }
    InfoService.prototype.get = function () {
        return this.requestService.get(this.apiService.createUrlFromBaseUrl(this.target));
    };
    InfoService.prototype.save = function (info, photoToUpload) {
        var formData = new FormData();
        this.setOptionalValues(info);
        formData.append('data', JSON.stringify(info));
        if (photoToUpload) {
            formData.append('files', photoToUpload);
        }
        return this.requestService.multipartRequestWithFormData('PUT', this.apiService.createUrlFromBaseUrl(this.target), formData, true);
    };
    InfoService.prototype.setOptionalValues = function (info) {
        if (!info.facebook) {
            info.facebook = '';
        }
        if (!info.instagram) {
            info.instagram = '';
        }
        if (!info.linkedin) {
            info.linkedin = '';
        }
        if (!info.twitter) {
            info.twitter = '';
        }
        if (!info.youtube) {
            info.youtube = '';
        }
    };
    InfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InfoService_Factory() { return new InfoService(i0.ɵɵinject(i1.RequestService), i0.ɵɵinject(i2.ApiService)); }, token: InfoService, providedIn: "root" });
    return InfoService;
}(crud_api_service_1.CrudApiService));
exports.InfoService = InfoService;
var Info = /** @class */ (function () {
    function Info() {
    }
    return Info;
}());
exports.Info = Info;
