"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var loading_service_1 = require("app/common/loading.service");
var utils_service_1 = require("app/common/utils.service");
var MagazineLocalizationComponent = /** @class */ (function () {
    function MagazineLocalizationComponent(utilsService, loadingService, route) {
        this.utilsService = utilsService;
        this.loadingService = loadingService;
        this.route = route;
    }
    MagazineLocalizationComponent.prototype.ngOnInit = function () {
        this.utilsService.setPageTitleByTranslateKey('MAGAZINE LANGUAGES');
        this.magazineId$ = this.route.params.map(function (params) { return +params['id']; });
    };
    return MagazineLocalizationComponent;
}());
exports.MagazineLocalizationComponent = MagazineLocalizationComponent;
