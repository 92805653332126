<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
  <mat-card fxFlex="100%">
    <div class="gene-card-title">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column">
          <h4>{{ 'PROCESS REQUEST' | translate }}</h4>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="gene-card-content">
      <dx-form id="dxForm" [formData]="data" [colCount]="1" [customizeItem]="customizeItemTemplate" class="address-form form-container">
        <dxi-item dataField="user.fullName">
          <dxo-label text="{{'REQUIRED BY' | translate}}"></dxo-label>
        </dxi-item>
        <dxi-item dataField="detail">
          <dxo-label text="{{'REQUEST DETAIL' | translate}}"></dxo-label>
        </dxi-item>
        <dxi-item dataField="created_date">
          <dxo-label text="{{'REQUEST DATE' | translate}}"></dxo-label>
        </dxi-item>
        <div *dxTemplate="let item of 'formItem'">
          <div *ngIf="!!data">
            <span *ngIf="!!data.user && item.dataField == 'user.fullName'">{{ data.user.fullName }}</span>
            <span *ngIf="item.dataField == 'detail'">{{ data.detail }}</span>
            <span *ngIf="item.dataField == 'created_date'">{{ data.created_date | date:'dd/MM/yyyy, HH:mm' }}</span>
          </div>
        </div>
      </dx-form>
      <br />
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field class="full-wid mrgn-b-lg">
              <textarea matInput="matInput" placeholder="{{ 'NOTE' | translate }}" formControlName="note"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="pad-wrap">
          <mat-card-actions>
            <button mat-raised-button="mat-raised-button" color="primary" type="submit" [disabled]="!isFormValid()">{{ 'SUBMIT' | translate }}</button>
            <button mat-raised-button="mat-raised-button" type="button" (click)="goToListPage()">{{ 'CANCEL' | translate }}</button>
          </mat-card-actions>
        </div>
      </form>
    </div>
  </mat-card>
</div>