<ms-mt-loading [show]="loadingService.loading$ | async"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
    <mat-card fxFlex="100%">
        <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="column">
                    <h4>{{ cardTitle | translate }}</h4>
                </div> <span fxFlex></span>
            </div>
            <mat-divider></mat-divider>
        </div>
        <form (submit)="onFormSubmit()">
            <dx-form id="dxForm" [formData]="catalogue" (onFieldDataChanged)="onFieldDataChanged()"
                [colCount]="2">
                <dxi-item dataField="name" [label]="{text: 'NAME' | translate}" [colSpan]="2">
                    <dxi-validation-rule type="required" [message]="'MSG NAME' | translate"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="search_keys" [label]="{text: 'KEYWORDS' | translate}"></dxi-item>
                <dxi-item dataField="order" editorType="dxNumberBox" [label]="{text: 'FAMILY' | translate}"
                    [editorOptions]="{ format: '#0', showClearButton: true }"></dxi-item>
                <dxi-item dataField="category_id" editorType="dxSelectBox"
                    [editorOptions]="categoryEditorOptions$ | async" [colSpan]="1"
                    [label]="{text: 'CATEGORY' | translate}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="um" editorType="dxSelectBox" [editorOptions]="umEditorOptions$ | async"
                    [colSpan]="1" [label]="{text: 'UM' | translate}">
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="pdf_link_id" [label]="{text: 'PDF LINK' | translate}">
                    <dxi-validation-rule type="pattern" [pattern]="patternRules" [ignoreEmptyValue]="false"
                        [message]="'MSG PDF LINK' | translate"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="public" editorType="dxCheckBox"
                    [editorOptions]="{ text: 'PUBLIC' | translate, value: false }" [label]="{text: ' '}"></dxi-item>
            </dx-form>
            <div class="pad-wrap" id="form_actions">
                <mat-card-actions>
                    <dx-button text="{{ 'SUBMIT' | translate }}" type="default" useSubmitBehavior="true"
                        [disabled]="isFormInvalid">
                    </dx-button>
                    <dx-button text="{{ 'CANCEL' | translate }}" (onClick)="goToListPage()" type="normal">
                    </dx-button>
                </mat-card-actions>
            </div>
        </form>
    </mat-card>
</div>