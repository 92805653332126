"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth_1 = require("@angular/fire/auth");
var router_1 = require("@angular/router");
var ngx_toastr_1 = require("ngx-toastr");
var i0 = require("@angular/core");
var i1 = require("@angular/fire/auth");
var i2 = require("@angular/router");
var i3 = require("ngx-toastr");
var AuthService = /** @class */ (function () {
    function AuthService(firebaseAuth, router, toastr) {
        this.firebaseAuth = firebaseAuth;
        this.router = router;
        this.toastr = toastr;
        this.isLoggedIn = false;
        this.user = firebaseAuth.authState;
    }
    /*
     *  getLocalStorageUser function is used to get local user profile data.
     */
    AuthService.prototype.getLocalStorageUser = function () {
        this.userData = JSON.parse(localStorage.getItem("userProfile"));
        if (this.userData) {
            this.isLoggedIn = true;
            return true;
        }
        else {
            this.isLoggedIn = false;
            return false;
        }
    };
    /*
    * signupUserProfile method save email and password into firabse &
    * signupUserProfile method save the user sign in data into local storage.
    */
    AuthService.prototype.signupUserProfile = function (value) {
        var _this = this;
        this.firebaseAuth
            .auth
            .createUserWithEmailAndPassword(value.email, value.password)
            .then(function (value) {
            _this.toastr.success('Successfully Signed Up!');
            _this.setLocalUserProfile(value);
            _this.router.navigate(['/']);
        })
            .catch(function (err) {
            _this.toastr.error(err.message);
        });
    };
    /*
     * loginUser fuction used to login
     */
    AuthService.prototype.loginUser = function (value) {
        var _this = this;
        this.firebaseAuth
            .auth
            .signInWithEmailAndPassword(value.email, value.password)
            .then(function (value) {
            _this.setLocalUserProfile(value);
            _this.toastr.success('Successfully Logged In!');
            _this.router.navigate(['/']);
        })
            .catch(function (err) {
            _this.toastr.error(err.message);
        });
    };
    /*
     * resetPassword is used to reset your password
     */
    AuthService.prototype.resetPassword = function (value) {
        var _this = this;
        this.firebaseAuth.auth.sendPasswordResetEmail(value.email)
            .then(function (value) {
            _this.toastr.success("A password reset link has been sent to this email.");
            _this.router.navigate(['/session/login']);
        })
            .catch(function (err) {
            _this.toastr.error(err.message);
        });
    };
    /*
     * resetPasswordV2 is used to reset your password
     */
    AuthService.prototype.resetPasswordV2 = function (value) {
        var _this = this;
        this.firebaseAuth.auth.sendPasswordResetEmail(value.email)
            .then(function (value) {
            _this.toastr.success("A password reset link has been sent to this email.");
            _this.router.navigate(['/session/loginV2']);
        })
            .catch(function (err) {
            _this.toastr.error(err.message);
        });
    };
    /*
     * logOut function is used to sign out
     */
    AuthService.prototype.logOut = function () {
        this.firebaseAuth
            .auth
            .signOut();
        localStorage.removeItem("userProfile");
        this.isLoggedIn = false;
        this.toastr.success("Successfully logged out!");
        this.router.navigate(['/session/loginV2']);
    };
    /*
     * setLocalUserProfile function is used to set local user profile data.
     */
    AuthService.prototype.setLocalUserProfile = function (value) {
        localStorage.setItem("userProfile", JSON.stringify(value));
        this.getLocalStorageUser();
        this.isLoggedIn = true;
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.ToastrService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
exports.AuthService = AuthService;
