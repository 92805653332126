"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var app_api_service_1 = require("./../../../common/app-api.service");
var core_1 = require("@angular/core");
var devextreme_angular_1 = require("devextreme-angular");
var dispose_bag_class_1 = require("app/classes/dispose-bag.class");
var forms_1 = require("@angular/forms");
var page_title_service_1 = require("app/core/page-title/page-title.service");
var utils_service_1 = require("app/common/utils.service");
var location_service_1 = require("../location.service");
var router_1 = require("@angular/router");
var loading_service_1 = require("app/common/loading.service");
var rxjs_1 = require("rxjs");
var environment_1 = require("environments/environment");
var FormLocationComponent = /** @class */ (function () {
    function FormLocationComponent(loadingService, fb, pageTitleService, utils, locationService, route, router, appApiService) {
        this.loadingService = loadingService;
        this.fb = fb;
        this.pageTitleService = pageTitleService;
        this.utils = utils;
        this.locationService = locationService;
        this.route = route;
        this.router = router;
        this.appApiService = appApiService;
        this.disposeBag = new dispose_bag_class_1.DisposeBag();
        this.isNameFormValid = false;
        this.isDescriptionFormValid = false;
        this.isShortDescriptionFormValid = false;
    }
    FormLocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.utils.translate('UPDATE LOCATION').subscribe(function (res) { return _this.cardTitle = res; });
        this.utils.translate('LOCATIONS').subscribe(function (res) { return _this.pageTitleService.setTitle(res); });
        this.form = this.fb.group({
            phone: [null, forms_1.Validators.required],
            url: [null],
            email: [null, forms_1.Validators.required],
            address: [null, forms_1.Validators.required],
            zip: [null, forms_1.Validators.required],
            city: [null, forms_1.Validators.required],
            country_id: [null, forms_1.Validators.required],
            main: [null, forms_1.Validators.required]
        });
        this.countries$ = this.loadingService.apiRequest$WithLoading(this.appApiService.getCountries());
        this.route.params.subscribe(function (params) {
            _this.id = String(params['id']);
            if (!_this.isNew()) {
                _this.utils.translate('EDIT LOCATION').subscribe(function (res) { return _this.cardTitle = res; });
                _this.loadLocationData();
            }
            else {
                _this.form.controls.main.setValue(false);
                _this.setEmptyData();
                _this.disposeBag.add(_this.loadingService.apiRequest$WithLoading(_this.appApiService.getLanguages()).subscribe(function (res) {
                    _this.languages = res.map(function (language) {
                        language.key = language.code.toLowerCase();
                        return language;
                    });
                }));
            }
        });
    };
    FormLocationComponent.prototype.ngOnDestroy = function () {
        this.disposeBag.dispose();
    };
    FormLocationComponent.prototype.setEmptyData = function () {
        this.location = {
            name: '{}',
            description: '{}',
            short_description: '{}',
            latitude: environment_1.environment.startLatitude,
            longitude: environment_1.environment.startLongitude
        };
    };
    FormLocationComponent.prototype.loadLocationData = function () {
        var _this = this;
        this.disposeBag.add(rxjs_1.zip(this.loadingService.apiRequest$WithLoading(this.locationService.getOne(this.id)), this.appApiService.getLanguages()).subscribe(function (array) {
            var location = array[0];
            _this.location = location;
            _this.objToForm(_this.location);
            if (!_this.location.description || _this.location.description === 'null') {
                _this.location.description = '{}';
            }
            if (!_this.location.short_description || _this.location.short_description === 'null') {
                _this.location.short_description = '{}';
            }
            _this.languages = array[1];
            _this.languages = _this.languages.map(function (language) {
                language.key = language.code.toLowerCase();
                return language;
            });
        }));
    };
    FormLocationComponent.prototype.objToForm = function (location) {
        this.form.controls.phone.setValue(location.phone);
        this.form.controls.url.setValue(location.url);
        this.form.controls.email.setValue(location.email);
        this.form.controls.address.setValue(location.address);
        this.form.controls.zip.setValue(location.zip);
        this.form.controls.city.setValue(location.city);
        this.form.controls.country_id.setValue(location.country_id);
        this.form.controls.main.setValue(location.main);
    };
    FormLocationComponent.prototype.formToObj = function () {
        var obj = {};
        if (this.location) {
            obj = this.location;
            delete obj['photo'];
        }
        else {
            obj['latitude'] = 0;
            obj['longitude'] = 0;
        }
        obj['name'] = this.location.name;
        obj['description'] = this.location.description;
        obj['short_description'] = this.location.short_description;
        obj['phone'] = this.form.controls.phone.value;
        obj['url'] = this.form.controls.url.value;
        obj['email'] = this.form.controls.email.value;
        obj['address'] = this.form.controls.address.value;
        obj['zip'] = this.form.controls.zip.value;
        obj['city'] = this.form.controls.city.value;
        obj['country_id'] = this.form.controls.country_id.value;
        obj['main'] = this.form.controls.main.value;
        return obj;
    };
    FormLocationComponent.prototype.isFormValid = function () {
        return this.form.valid && this.isNameFormValid && this.isDescriptionFormValid && this.isShortDescriptionFormValid;
    };
    FormLocationComponent.prototype.fileChange = function (event) {
        this.photoToUpload = null;
        if (event.length > 0) {
            this.photoToUpload = event[0];
        }
    };
    FormLocationComponent.prototype.updatePosition = function (event) {
        this.location.latitude = event.lat;
        this.location.longitude = event.lng;
    };
    FormLocationComponent.prototype.onSubmit = function () {
        if (!this.isFormValid()) {
            return;
        }
        this.handleRequest(this.isNew() ? this.saveLocation() : this.updateLocation(), !!this.photoToUpload);
    };
    FormLocationComponent.prototype.isNew = function () {
        return this.id === 'undefined';
    };
    FormLocationComponent.prototype.goToListPage = function () {
        this.router.navigate(['location']);
    };
    FormLocationComponent.prototype.handleRequest = function (observable$, progress) {
        var _this = this;
        if (!progress) {
            this.disposeBag.add(this.loadingService.apiRequest$WithLoading(observable$).subscribe(function () { return _this.goToListPage(); }));
        }
        else {
            this.disposeBag.add(this.loadingService.apiRequest$WithLoadingAndProgress(observable$).subscribe(function () { return _this.goToListPage(); }));
        }
    };
    FormLocationComponent.prototype.updateLocation = function () {
        return this.locationService.update(this.id, this.formToObj(), this.photoToUpload);
    };
    FormLocationComponent.prototype.saveLocation = function () {
        return this.locationService.create(this.formToObj(), this.photoToUpload);
    };
    return FormLocationComponent;
}());
exports.FormLocationComponent = FormLocationComponent;
