<ms-mt-loading [show]="loading"></ms-mt-loading>
<div fxLayout="row wrap" fxLayoutAlign=" start" class="gene-form-wizard">
        <mat-card fxFlex="100%">
          <div class="gene-card-title">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div fxLayout="column">
                <h4>{{ cardTitle | translate }}</h4>
              </div>
              <span fxFlex></span>
            </div>
            <mat-divider></mat-divider>
          </div>
          <div class="gene-card-content">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                  <mat-form-field class="full-wid mrgn-b-lg">
                    <input matInput placeholder="{{ 'PASSWORD' | translate }}" type="password" formControlName="password"/>
                  </mat-form-field>
                  <small class="gene-block" *ngIf="validatePassword()" class="mat-text-warn">
                    {{ "MSG PASSWORD" | translate }}
                  </small>
                </div>
              </div>
              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                  <mat-form-field class="full-wid mrgn-b-lg">
                    <input matInput placeholder="{{ 'CONFIRM PASSWORD' | translate }}" type="password" formControlName="confirmPassword"/>
                  </mat-form-field>
                  <small class="gene-block" *ngIf="matchPassword()" class="mat-text-warn">
                    {{ "MSG CONFIRM PASSWORD" | translate }}
                  </small>
                </div>
              </div>
              <div class="pad-wrap">
                <mat-card-actions>
                  <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid"> {{ "SUBMIT" | translate }}</button>
                  <button mat-raised-button type="button" (click)="onClose()">{{ "CANCEL" | translate }}</button>
                </mat-card-actions>
              </div>
            </form>
          </div>
        </mat-card>
      </div>
      