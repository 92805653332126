import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MtLoadingComponent} from './mt-loading.component';



@NgModule({
  declarations: [
      MtLoadingComponent
  ],
  imports: [
    CommonModule,
    LoadingBarModule
  ],
  exports: [
    MtLoadingComponent
  ]
})
export class MtLoadingModule { }
