"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var utils_service_1 = require("app/common/utils.service");
var app_api_service_1 = require("./../../../common/app-api.service");
var core_1 = require("@angular/core");
var grid_base_component_1 = require("app/classes/grid-base.component");
var rxjs_1 = require("rxjs");
var config_service_1 = require("app/common/config.service");
var loading_service_1 = require("app/common/loading.service");
var user_service_1 = require("app/components/user/user.service");
var GridPaperCopyRequestComponent = /** @class */ (function (_super) {
    __extends(GridPaperCopyRequestComponent, _super);
    function GridPaperCopyRequestComponent(configService, loadingService, appApiService, utils, router) {
        var _this = _super.call(this, configService) || this;
        _this.configService = configService;
        _this.loadingService = loadingService;
        _this.appApiService = appApiService;
        _this.utils = utils;
        _this.router = router;
        return _this;
    }
    GridPaperCopyRequestComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadData();
        this.processButtonClick = this.processButtonClick.bind(this);
    };
    GridPaperCopyRequestComponent.prototype.processButtonClick = function (e) {
        this.router.navigate(['paper_copy_request', e.row.data.id, 'process']);
    };
    GridPaperCopyRequestComponent.prototype.onToolbarPreparing = function (e) {
        var _this = this;
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
                icon: 'refresh',
                onClick: function () { return _this.loadData(); }
            }
        });
    };
    GridPaperCopyRequestComponent.prototype.loadData = function () {
        var _this = this;
        this.paperCopies$ = this.loadingService.apiRequest$WithLoading(rxjs_1.zip(this.appApiService.getPaperCopiesRequired(), this.utils.translate('COPY'))).map(function (items) {
            var paperCopies = items[0];
            var translatedCopy = items[1];
            return paperCopies.map(function (paperCopy) {
                paperCopy.user = new user_service_1.User(paperCopy.user);
                paperCopy.detail = _this.getDetailString(paperCopy, translatedCopy);
                return paperCopy;
            });
        });
    };
    GridPaperCopyRequestComponent.prototype.isNotProcessed = function (e) {
        return !e.row.data.processed;
    };
    GridPaperCopyRequestComponent.prototype.getDetailString = function (body, translatedCopy) {
        return body.catalogue_version.catalogue.name + ' (' + body.catalogue_version.name + ' - ' + body.catalogue_version.catalogue.language.code + ') - ' + translatedCopy + body.quantity;
    };
    GridPaperCopyRequestComponent.prototype.customizeItemTemplate = function (item) {
        item.template = 'formItem';
    };
    GridPaperCopyRequestComponent.prototype.onRowPrepared = function (e) {
        if (!(e.rowType === 'data' && e.data.processed === false)) {
            return;
        }
        e.rowElement.querySelector('.dx-command-expand').firstChild.classList.remove('dx-datagrid-group-closed');
        e.rowElement.querySelector('.dx-command-expand').classList.remove('dx-datagrid-expand');
    };
    return GridPaperCopyRequestComponent;
}(grid_base_component_1.GridBaseComponent));
exports.GridPaperCopyRequestComponent = GridPaperCopyRequestComponent;
