<dx-data-grid
        id="gridContainer"
        [dataSource]="paperCopies$ | async"
        [allowColumnReordering]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        [showBorders]="true"
        (onToolbarPreparing)="onToolbarPreparing($event)"
        [masterDetail]="{ enabled: true, template: 'detail' }"
        (onRowPrepared)="onRowPrepared($event)">
    <dxo-export [enabled]="true" fileName="PaperCopiesRequired"></dxo-export>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-sorting mode="multiple"></dxo-sorting>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]">
    </dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxi-column type="buttons" [width]="buttonColumnNameWidth">
      <dxi-button [visible]="isNotProcessed" icon="box" [onClick]="processButtonClick" hint="{{'PROCESS' | translate}}"></dxi-button>
    </dxi-column>
    <dxi-column dataField="user.fullName" caption="{{'REQUIRED BY' | translate}}" [width]="userFullNameWidth"></dxi-column>
    <dxi-column dataField="detail" caption="{{'REQUEST DETAIL' | translate}}"></dxi-column>
    <dxi-column dataField="created_date" caption="{{'REQUEST DATE' | translate}}" dataType="datetime"
      format="dd/MM/yyyy, HH:mm" [width]="timestampWidth"></dxi-column>
    <dxi-column dataField="processed" caption="{{'PROCESSED' | translate}}"></dxi-column>
    <dxi-column dataField="process_date" caption="{{'PROCESSED REQUEST DATE' | translate}}" dataType="datetime"
      format="dd/MM/yyyy, HH:mm" [width]="timestampWidth"></dxi-column>
    <div *dxTemplate="let request of 'detail'">
      <dx-form 
          [formData]="request.data"
          [colCount]="1"
          [customizeItem]="customizeItemTemplate"
          class="address-form form-container"
      >
          <dxi-item dataField="process_note">
            <dxo-label text="{{'NOTE' | translate}}"></dxo-label>
          </dxi-item>
          <div *dxTemplate="let item of 'formItem'">
              <span>{{item.editorOptions.value}}</span>
          </div>
      </dx-form>
  </div>
</dx-data-grid>
