import { Injectable } from '@angular/core';

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'users',
    name: 'USERS',
    type: 'link',
    icon: 'group'
  },
  {
    state: 'catalogue',
    name: 'CATALOGUES',
    type: 'link',
    icon: 'note'
  },
  {
    state: 'magazine',
    name: 'MAGAZINES',
    type: 'link',
    icon: 'newspaper'
  },
  {
    state: 'paper_copy_request',
    name: 'COPY REQUEST',
    type: 'link',
    icon: 'receipt'
  },
  {
    state: 'location',
    name: 'LOCATIONS',
    type: 'link',
    icon: 'place'
  },
  {
    state: 'news',
    name: 'NEWS',
    type: 'link',
    icon: 'featured_play_list'
  },
  {
    state: 'info',
    name: 'INFO',
    type: 'link',
    icon: 'info'
  }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
  add(menu:any) {
    MENUITEMS.push(menu);
  }
}
